import React, { useRef, useState } from 'react'
import teamMember1 from '../../assets/images/aboutUsTeam/equipo_Andrea-Bertone.png'
import teamMember2 from '../../assets/images/aboutUsTeam/equipo_Maximiliano-Sabada-Cuadrada.png'
import teamMember3 from '../../assets/images/aboutUsTeam/equipo_Celina-Durrieu.png'
import teamMember4 from '../../assets/images/aboutUsTeam/equipo_Mariana-Hortal.png'
import teamMember5 from '../../assets/images/aboutUsTeam/equipo_Gabriela-Sorrentino.png'
import teamMember6 from '../../assets/images/aboutUsTeam/equipo_Candea-Vitale.png'
import teamMember7 from '../../assets/images/aboutUsTeam/equipo_Miguel-Domenech.png'
import TeamMember from './aboutUs.teamMember'
import i18n from '../../i18n'

export default function Team() {
    const grid = useRef()
    const members = [
        {
            name: 'Andrea Bertone',
            role: i18n.t('about-us.members.role1'),
            image: teamMember1,
            description: i18n.t('about-us.members.description1'),
        },
        {
            name: 'Celina Durrieu',
            role: i18n.t('about-us.members.role3'),
            image: teamMember3,
            description: i18n.t('about-us.members.description3'),
        },
        {
            name: 'Maximiliano Sádaba',
            role: i18n.t('about-us.members.role2'),
            image: teamMember2,
            description: i18n.t('about-us.members.description2'),
        },
        {
            name: 'Candela Vitale',
            role: i18n.t('about-us.members.role6'),
            image: teamMember6,
            description: i18n.t('about-us.members.description6'),
        },
        {
            name: 'Mariana Hortal Sueldo',
            role: i18n.t('about-us.members.role4'),
            image: teamMember4,
            description: i18n.t('about-us.members.description4'),
        },
        {
            name: 'Gabriela Sorrentino',
            role: i18n.t('about-us.members.role5'),
            image: teamMember5,
            description: i18n.t('about-us.members.description5'),
        },
        {
            name: 'Miguel Domenech',
            role: i18n.t('about-us.members.role7'),
            image: teamMember7,
            description: i18n.t('about-us.members.description7'),
        },
    ]

    const [display, setDisplay] = useState(false)
    const [action, setAction] = useState('VER BIOGRAFÍAS')

    const toggleDisplay = () => {
        setDisplay(!display)
        if (!display) {
            setAction('OCULTAR BIOGRAFÍAS')
        } else {
            setAction('VER BIOGRAFÍAS')
        }
        grid.current.scrollIntoView({ behavior: 'smooth' })
        window.scrollBy(0, -70)
    }

    const forMembers = members.map((member, key) => (
        <div
            className={display ? 'col-12' : 'col-12 col-sm-6 col-md-4'}
            key={key}
        >
            <TeamMember
                member={member}
                display={display}
                setDisplay={setDisplay}
            />
        </div>
    ))

    return (
        <section className="team container">
            <div className="section-title text-center">
                <h6>{i18n.t('about-us.team.header-text')}</h6>
                <h2>{i18n.t('about-us.team.title')}</h2>
                <p>{i18n.t('about-us.team.text')}</p>
            </div>

            <div ref={grid} className="button text-center mb-3">
                <button
                    type="button"
                    className="btn btn-outline-primary g-action-button rounded-pill fix-button-link"
                    onClick={toggleDisplay}
                >
                    {action}
                </button>
            </div>

            <div className="team-grid row">{forMembers}</div>

            {display && (
                <div className="button text-center mb-3">
                    <button
                        type="button"
                        className="btn btn-outline-primary g-action-button rounded-pill fix-button-link"
                        onClick={toggleDisplay}
                    >
                        {action}
                    </button>
                </div>
            )}
        </section>
    )
}
