import axios from 'axios'
import { csrf } from './csrfValidation'
import { ENV_URL } from '../../app/constants'

/**
 * Endpoint for quotation user register
 * @param formDataRes
 * @returns {AxiosPromise<any>}
 */
const quotationUser = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/createQuotationUser`,
        data: formDataRes,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    })
}

/**
 * Endpoint for updating business
 * @param formDataRes
 * @returns {AxiosPromise<any>}
 */
const businessUpdate = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/business/update`,
        data: formDataRes,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    })
}

/**
 * Endpoint for contacting business
 * @param formDataRes
 * @returns {AxiosPromise<any>}
 */
 const businessContact = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/business/contact`,
        data: formDataRes,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    })
}

const addVisitCount = async id => {
    return axios({
        method: 'get',
        url: `${ENV_URL}/api/V1/business/visit/count/` + id,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

export { quotationUser, businessUpdate , businessContact, addVisitCount }
