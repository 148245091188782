import i18n from '../../i18n.js'

export default function ContactUsBanner(){
    return(
        <div className="banner">
            <div className="section-title container">
                <h6>{i18n.t('contact-us.banner.header-text')}</h6>
                <h1 className='contactus-title'>
                    <span>{i18n.t('contact-us.banner.title')}</span>
                </h1>
            </div>
        </div>
    )
}
