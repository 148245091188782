import Pagination from "@mui/material/Pagination/Pagination";
import React, {useState} from "react";
import BusinessDataCard from "./businessList.businessCard";
import i18n from '../../i18n.js'

export default function Results(props){

    const {businessList, currentPage, setCurrentPage, data, language, setIsSubmitted} = (props) = props;
    const {last_page} = businessList?businessList:0;
    /**
     * Iterates businesses array data.
     * If array is empty returns an alert
     * else it returns a businessCard component with each business in prop
     */
    let forBusinesses = !data.length>0?
        <h3 className={"alert alert-warning"}>No existen registros que coincidan con su búsqueda</h3>
        :
        data.map((business, index) =>
            (
                <div key={index}>
                    <BusinessDataCard business={business} language={language}/>
                </div>
            )
        );

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value)
        setIsSubmitted(true);
      };

    return(
        
        <>
            <div className="alert alert-info text-center">{i18n.t('business.list.info')}</div>

            {forBusinesses}
            { data.length > 0 &&
                <div className={"custom-pagination"}>
                <Pagination
                    count={last_page}
                    onChange={handleChange}
                    page={currentPage}
                />
                </div>
            }
        </>
    )
}