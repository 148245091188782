import React from "react";
import i18n from '../../../i18n'

export default function ButtonDivider(){


      return(
        <div className={"button"}>
            <div className={"line"}>
            </div>

<a href="#contactRefId">
            <button
                className="btn btn-outline-primary g-action-button rounded-pill"
                id={"me-interesa"}
            >
                {i18n.t("business-detail.detail.interest")}
            </button>
            </a>


        </div>
    )
}