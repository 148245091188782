import React, {useEffect, useState} from "react";
import QuotationHeader from "./quotationHeader";
import QuotationSuccess from "./quotationSuccess";
import BusinessUpdateForm from "./quotationForm/quotationForm.updateBusiness";
import loadingImage from '../../assets/icons/loading.gif'
import BusinessCategoriesFetch from "../api/businessCategories.fetch";
import BusinessDataFetch from "../api/businessData.fetch";
import SocialNetworksFetch from "../api/socialNetworks.fetch";

import i18n from '../../i18n.js'

export default function QuotationBusinessUpdate(props){
    const {businessId, businessToken, language} = props;
    const [businessData, setBusinessData] = useState(null); //all business data from DDBB
    const [quotationSuccess, setQuotationSuccess]=useState(false); //changes from data form to confirmation message
    const [loading, setLoading]=useState(true);
    const [danger, setDanger]=useState(false);
    const [businessCategories, setBusinessCategories] = useState(null);
    const [infoFetch, setInfoFetch] = useState(false); //api fetch data state
    const [socialNetworks, setSocialNetworks] = useState(null)

    const quotationTitle = i18n.t('quotation.update.title');
    const quotationMessage = i18n.t('quotation.update.quotationMessage');

    const successMessage =   i18n.t('quotation.update.successMessage');
    const successTitle =  i18n.t('quotation.update.successTitle');

    const errorMessage = i18n.t('quotation.errorMessage');
    const errorTitle = i18n.t('quotation.errorTitle');

    const successSubtitle = i18n.t('quotation.update.successSubtitle');
    const successText =  i18n.t('quotation.successText');

    const  errorSubtitle = i18n.t('quotation.errorSubtitle');
    const  errorText = i18n.t('quotation.errorText');

    const displayHeader = () => {
        switch(quotationSuccess){
            case true:
                return <QuotationHeader title={successTitle} message={successMessage}/>;
            case false:
                return <QuotationHeader title={quotationTitle} message={quotationMessage}/>;
           default:
                return <QuotationHeader title={errorTitle} message={errorMessage}/>;
        }
    }

    const displayContent = () => {
        switch(quotationSuccess){
            case true:
                return <QuotationSuccess subtitle={successSubtitle} text={successText} />;
            case false:
                return  <BusinessUpdateForm setQuotationSuccess={setQuotationSuccess} businessData={businessData} businessId={businessId} businessCategories={businessCategories} socialNetworks={socialNetworks} language={language}/>;
           default:
                return <QuotationSuccess subtitle={errorSubtitle} text={errorText}/>;
        }
    }

    /**
     * Validates that all data is available
     */
     useEffect(() => {
       if(businessData  && businessCategories && socialNetworks){
           setInfoFetch(true)
           setLoading(false);
       }
    }, [businessData, businessCategories, socialNetworks])

    return(
        <section className={"quotation"}>
            <div className={"quotation-section-content container"}>

                {displayHeader()}

                <div className={"quotation-form contact shadow mt-4"}>

                    <BusinessDataFetch
                        setBusinessData={setBusinessData}
                        setDanger={setDanger}
                        setLoading={setLoading}
                        businessId={businessId}
                        businessToken={businessToken}
                    />

                    <BusinessCategoriesFetch setBusinessCategories={setBusinessCategories}/>
                    <SocialNetworksFetch setSocialNetworks={setSocialNetworks}/>

                    {loading &&
                    <div className={"text-center"}>
                        <img src={loadingImage} alt={"loading"}/>
                        <p>{i18n.t('quotation.success.loading')}</p>
                    </div>
                    }

                  {infoFetch &&   displayContent()}

                  {danger &&
                      <div>
                          <p className={"alert alert-danger text-center"}>{i18n.t('quotation.success.danger1')}
                          <br/>
                          {i18n.t('quotation.success.danger2')}
                          </p>
                          <QuotationSuccess subtitle={errorSubtitle} text={errorText}/>
                      </div>
                    }


                </div>
                <div className={"gradient-bottom shadow mb-4 col-12"}>
                </div>

            </div>
        </section>
    )

}