import './faqs.css'
import FaqsBanner from "./faqs.banner";
import ButtonSelector from "./faqs.buttonSelector";
import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import Questions from "./faqs.questionsAccordion";

export default function Faqs(props){

    const {type, language} = (props) = props;
    const navigate = useNavigate();

    //si en e param llega cualquier cosa redirect a not found
    useEffect(() => {
        if( type != "comprador" && type != "vendedor"){
            navigate("/*");
        }
    },[])

    const [buyer, setBuyer] = useState(false);
    const [seller, setSeller] = useState(false);

    return (
        <section className="faqs">
            <div className="faqs-section-content">

                <FaqsBanner />

                <div className="container">

                <ButtonSelector buyer={buyer} setBuyer={setBuyer} seller={seller} setSeller={setSeller} type={type} language={language}/>

                    {buyer?(
                        <Questions type={"comprador"} />
                    ):(
                        <Questions  type={"vendedor"} />
                    )
                    }

                </div>

                <div className="back-effect">
                </div>
            </div>
        </section>
    )
}