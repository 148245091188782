import TermsTextEs from "./terms.text.es";
import TermsTextPt from "./terms.text.pt";
import "./terms.css"


export default function Terms(props) {
    const {language} = props;

    return (
        <>


            <div className="modal fade" id="termsModal" tabIndex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="termsModalLabel">Términos y condiciones del sitio</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {language==="es"&&<TermsTextEs />}
                            {language==="pt"&&<TermsTextPt />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary rounded-pill g-action-button" data-bs-dismiss="modal">Cerrar</button>
                       </div>
                    </div>
                </div>
            </div>
        </>
    );
}

