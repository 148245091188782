import React, {useEffect, useState} from "react";
import i18n from '../../../i18n'

export default function BottomInfo(props) {
    const {business, loading} = (props) = props;

    const [years, setYears] = useState(0)
    const [months, setMonths] = useState(0)
    const roi = business.roi

    const calculateRoi = () =>{
        let totalMonths = Math. trunc(roi/0.083);
        setYears(Math. trunc(totalMonths/12));

        let tempMonths = roi - Math.floor(roi)
        setMonths(Math. trunc(tempMonths/0.083))
     }

    useEffect(() => {
        calculateRoi()
    },[loading])

    return(
        <div className={"bulletpoints"}>
            <ul>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <li><span className={"custom-bullet"}></span>
                            {i18n.t("business-detail.detail.followers")}
                        </li>
                        <p>{business.total_social_network_followers_stat.toLocaleString('de-DE')}</p>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <li><span className={"custom-bullet"}></span>
                        {i18n.t("business-detail.detail.subscribers")}
                        </li>
                        <p>{business.total_email_subscribers_stat.toLocaleString('de-DE')}</p>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <li><span className={"custom-bullet"}></span>
                            {i18n.t("business-detail.detail.organic")}
                        </li>
                        <p>{business.total_monthly_organic_visits_stat.toLocaleString('de-DE')}%</p>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        
                    </div>

                   <div className={"col-12 col-md-6"}>
                        <li><span className={"custom-bullet"}></span>
                             {i18n.t("business-detail.detail.revenue")}
                        </li>
                        <p>$ { business.average_gross_revenue_stat.toLocaleString('de-DE') }</p>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <li><span className={"custom-bullet"}></span>
                        {i18n.t("business-detail.detail.roi")}
                        </li>
                        {months>0 && years>0?
                            <p>
                                { years } {i18n.t("business-detail.detail.years")} {months} {months<2?"mes":i18n.t("business-detail.detail.months")}
                            </p>
                            :
                            null
                        }

                        {months>=1 && years<=0?
                            <p>{months} {i18n.t("business-detail.detail.months")} </p>
                            :
                            null
                        }

                        {months<=0 && years>0?
                            <p>{years} {i18n.t("business-detail.detail.years")}</p>
                            :
                            null
                        }
                    </div>

                </div>
            </ul>

        </div>
    )
}