import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import cookie from 'react-cookies';
import {getUserProfile, getUserBusinesses} from '../../api/userEndpoints'
import loadingImage from "../../../assets/icons/loading.gif";
import './dashboard.css'
import '../user.css'
import TopButtons from "./dashboard.topButtons";
import BusinessesInfo from "./dashboard.businessesInfo";
import BottomButtons from "./dashboard.bottomButtons";
import ContactUsImage from "../../contactUs/contactUs.image";
import UserProfile from "./dashboard.userProfile";
import i18n from '../../../i18n'
import CountriesFetch from '../../api/countries.fetch'; 

export default function Dashboard(props){

    const {language} = props
    const navigate = useNavigate();
    const token = cookie.load('laravel_token');
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [businesses, setBusinesses] = useState(null);
    const [view, setView] = useState('business');
    const [countries, setCountries] = useState(null);
    const expires = new Date()
    expires.setDate(Date.now() + 2 * 60 * 60 * 1000)


    useEffect(() => {
      if(!token){
          navigate("/usuario/login");
      }else{
        getUser()
       }

    },[])

    useEffect(() => {
        if(!token){
            navigate("/usuario/login");
        }
    })

    useEffect(() => {
        if (userProfile) {
         getBusinesses(userProfile.id)
            }
    },[userProfile])

    useEffect(() => {
        if (userProfile && businesses && countries) {
            setLoading(false)
        }
      },[userProfile, businesses, countries])




    const getUser = () =>{
        getUserProfile(token).then((response) =>{
            setUserProfile(response.data)
            cookie.save('user_id',response.data.id,
                { path: '/', expires,
                   maxAge: 10800,
                  //secure: true,
                  //httpOnly: true
                } );
            cookie.save('user_name',response.data.name+" "+response.data.last_name,
                { path: '/', expires,
                    maxAge: 10800,
                    //secure: true,
                    //httpOnly: true
            } )
          })
    };

    const getBusinesses = () =>{
        getUserBusinesses(cookie.load('user_id')).then((response) =>{
            setBusinesses(response.data)
        })
    }

    return(
        <section className={"dashboard"}>
            <div className={"dashboard-content-section"}>
                <div className="container">
                <CountriesFetch setCountries={setCountries} /> 



            {token && !loading ?
                <div className={"pt-5"}>

                <div className={"row"}>
                   <div className={"col-12 col-md-9 pb-5"}>
                       <TopButtons
                           username={userProfile.name+" "+userProfile.last_name}
                           view={view}
                           setView={setView}
                       />

                       {view==='business'&&
                    <BusinessesInfo
                        businesses={businesses}
                        setBusinesses={setBusinesses}
                    />
                    }

                       {view==='profile'&&
                           <UserProfile
                               userProfile={userProfile}
                               getUser={getUser}
                               language= {language}
                               countries={countries}
                           />
                       }

                   <BottomButtons />
                   </div>
                    <div className={"col-12 col-md-3 image"}>
                        <ContactUsImage/>
                    </div>
                </div>


                </div>
            :null
            }



            {loading &&
            <div className={"text-center"}>
                <img src={loadingImage} alt={"loading"}/>
                <p>{i18n.t("contact-us.form.placeholders.loading")}</p>
            </div>
            }


        </div>
            </div>
        </section>
    )
}