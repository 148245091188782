import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import TagManager from 'react-gtm-module';
import {Helmet} from "react-helmet";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_KEY } from './app/constants'

const tagManagerArgs = {
  gtmId: 'GTM-P3F5NFW'
}

TagManager.initialize(tagManagerArgs)

const currentDomain = window.location.hostname;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    { !currentDomain.includes(".br") ?
      <Helmet>
        <link rel="canonical" href="https://sitiodetiendas.com/" />
        <link rel="alternate" hreflang="es" href="https://sitiodetiendas.com/" />
      </Helmet>
      :
      <Helmet>
        <link rel="canonical" href="https://seuproximonegocio.com.br/" />
        <link rel="alternate" hreflang="pt" href="https://seuproximonegocio.com.br/" />
      </Helmet>
    }
    <GoogleReCaptchaProvider
      reCaptchaKey={ RECAPTCHA_KEY }
      language={ !currentDomain.includes(".br") ? "es" : "pt-BR" }
      container={{ // optional to render inside custom element
        element: "",
        parameters: {
          badge: 'bottomright', // optional, default undefined ([inline|bottomright|bottomleft])
          theme: 'dark', // optional, default undefined
        }
      }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
