import Accordion from 'react-bootstrap/Accordion'
import React from 'react'
import i18n from '../../i18n'
import { Link } from 'react-router-dom'

export default function QuestionsBuyer(props) {
    const { type } = props

    function setQuestions() {
        let limit
        if (type === 'comprador') {
            limit = 12
        } else {
            limit = 9
        }

        const questions = []
        for (let i = 1; i < limit; i++) {
            questions.push(
                <Accordion.Item eventKey={i} key={i}>
                    <Accordion.Header>
                        {i18n.t('faqs.' + type + '.question' + i)}
                    </Accordion.Header>
                    <Accordion.Body className="whitespace">
                    {((type === 'comprador' && i === 11) || (type !== 'comprador' && i === 8)) ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('faqs.' + type + '.answer' + i, {
                                        link: `${i18n.t('url_canonical')}`+ 'precios'
                                    })
                                }}
                            />
                        ) : (
                            i18n.t('faqs.' + type + '.answer' + i)
                        )}
                    </Accordion.Body>
                </Accordion.Item>,
            )
        }
        return questions
    }

    return (
        <div className="accordion-questions">
            <Accordion>{setQuestions()}</Accordion>
        </div>
    )
}
