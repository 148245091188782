import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import ForgetPasswordMessage from "./forgetPasswordMessage";
import {passwordUpdateRequest} from "../api/userEndpoints";
import cookie from "react-cookies";
import i18n from '../../i18n'
import {Helmet} from "react-helmet";

export default function ForgetPassword(props){
    //document.title = props.title
    const { register, formState: { errors }, handleSubmit } = useForm();

    const [success, setSucess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [danger, setDanger] = useState(false);
    const token = cookie.load('laravel_token');

    const mailRequired = i18n.t("form-error-messages.mail");
    const mailPattern = i18n.t("form-error-messages.mail-pattern");

    const navigate = useNavigate();

    useEffect(() => {
        if(token){
            navigate("/usuario/panel");
        }
    })

    const onSubmit = (data) => {
        setLoading(true)

        const formDataRes = new FormData();
        formDataRes.append('email', data.mail);

        try{
        passwordUpdateRequest(formDataRes).then(response => {
            if (response.status === 200) {
                setLoading(false)
                setDanger(false)
                setSucess(true)
            }
        }).catch((response) => {
            setLoading(false)
            setDanger(true)
        })
        }catch (e) {
            setLoading(false)
            setDanger(true)
        }
    }


    const handleNavigate = (route) =>{
        navigate(route)
    }


    return(
        <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            {!danger && !success?
                <div>
                    <h1>{i18n.t("login.reset")}</h1>

                    <h2 className={"col-12 col-lg-4 forgotpass_page_subtitle"}>
                    {i18n.t("login.message1")}
                        <br/>
                        {i18n.t("login.message2")}
                    </h2>

                    <div className={"login-form col-12 col-lg-4"}>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <fieldset className="col-12">
                                <label className="label-element">Mail</label>
                                <input className={errors.mail ? "input-info error" : "input-info success"}
                                       type="mail"
                                       placeholder={i18n.t('contact-us.form.placeholders.mail')}
                                       {...register("mail", {
                                           required: true,
                                           pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                       })}
                                />
                                <div className={"error-message text-danger"}>
                                    {errors.mail?.type === 'required' && mailRequired}
                                    {errors.mail?.type === 'pattern' && mailPattern}
                                </div>

                            </fieldset>
                            <div className="col-12 submit-botton">
                                <input className="btn btn-outline-primary g-action-button rounded-pill"
                                       type="submit"
                                       disabled={loading}
                                       value={!loading?"ENVIAR":"ENVIANDO..."}/>
                            </div>


                        </form>
                    </div>

                </div>
                :
                null
            }

            {success && <ForgetPasswordMessage/>}

            {danger && !loading && !success?
                <div className={"col-12 col-lg-4"}>
                    <h2>{i18n.t("login.reset")}</h2>
                    <div className={"alert alert-danger text-center"}>
                        <h3>{i18n.t("login.atention")}</h3>
                        <p>
                        {i18n.t("login.error1")}
                            <br/>
                            {i18n.t("login.error2")}
                        </p>
                    </div>
                    <div className="submit-botton text-center">
                        <input className="btn btn-outline-primary g-action-button rounded-pill"
                               type="button"
                               onClick={() => handleNavigate("/usuario/registro")}
                               value="REGISTRARSE"/>
                    </div>
                </div>
                :
                null
            }
            </>
    )
}