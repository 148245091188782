import React from "react";

export default function SocialNetworks(props) {
    const {business} = (props) = props;
    const networks = business.network_values.constructor === Array ? business.network_values : JSON.parse(business.network_values)
    const forNetworks = !networks? [] : networks.map((nw, index) =>
        (
            <img key={index} src={require("../../../assets/icons/"+ nw.name.toLowerCase() +".png")} alt={"Instagram"}/>
        )
    );

    return(
        <div className={"social-networks-icons"}>
            {forNetworks}
        </div>
    )

}