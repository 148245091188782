import React, {useState} from "react";

export default function TeamMember(props){

    const {member, display} = props;

    return(

    <div className= {display?"team-member height-auto":"team-member"}>
        <img className="img-fluid" src={member.image} alt="team member" />
        <h5>{member.name}</h5>
        <p className={"role"}>{member.role}</p>

            <div className={display?"member-info d-block":"d-none"}>
            <p>
                {member.description}
            </p>
        </div>

    </div>


    )
}