import i18n from "../../i18n";

export default function BlogBanner(props) {
    const {language, type} = props;
    return(
        <div className="banner">
            {type === 'blog' &&
            <div className="section-title container">

                <h6>{i18n.t('blog.banner.header-text')}</h6>
                <h1>
                    {i18n.t('blog.banner.title1')}
                </h1>
            </div>
            }
            {type === 'prensa' &&
            <div className="section-title container">

                <h6>{i18n.t('news.banner.header-text')}</h6>
                <h1>
                    {i18n.t('news.banner.title1')}
                </h1>
            </div>
            }
        </div>
    )
}