import i18n from "../../i18n";
import React from "react";

export default function TestimonialsBanner() {
    return(
        <div className="banner">
            <div className="section-title container">
                <h6 className={"col-6 col-md-4"}> {i18n.t('testimonials.banner.header-text')}</h6>
                <h1>
                    <span>{i18n.t('testimonials.banner.title')}</span>
                </h1>

                <h2 className="col-6 col-md-4 d-none d-md-block testimonials_page_subtitle">
                    {i18n.t('testimonials.banner.text1')}
                    <br/>
                    {i18n.t('testimonials.banner.text2')}
                </h2>
                <h2 className="col-6 col-md-4 d-block d-md-none testimonials_page_subtitle">
                    {i18n.t('testimonials.banner.text1m')}
                    <br/>
                    {i18n.t('testimonials.banner.text2m')}
                </h2>
            </div>
        </div>
    )

}