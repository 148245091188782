import React, {useState} from "react";
import {isArrayEmpty} from "../../Utils";
import ActionButtons from "./dashboard.businessActionButtons";
import BusinessState from "./dashboard.businessUserState";
import i18n from '../../../i18n'


export default function BusinessTableDesktop(props){

    const {businesses, handleEdit, handleDelete, handlePayment, deleting, handleDetails} = (props) = props


    let forBusinesses = isArrayEmpty(businesses) ?
        []
        :
        businesses.map((business, index) =>
            (
                <tr key={index}>
                    <th scope="row">{index+1}</th>
                    <td>{business.domain_url}</td>
                    <td className={"d-none d-sm-table-cell"}>${ business.algorithm_price_usd.toLocaleString('de-DE')}</td>
                    <td>
                        <BusinessState
                            business={business}
                        />
                    </td>
                    <td className={"d-flex"}>
                        <ActionButtons
                            business={business}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handlePayment={handlePayment}
                            handleDetails={handleDetails}
                            deleting={deleting}
                        />

                    </td>
                </tr>

            )
        );

    return(
        <div className="table-responsive shadow p-3">
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                        {i18n.t("dashboard.domain")}
                    </th>
                    <th scope="col" className={"d-none d-sm-table-cell"}>
                        {i18n.t("dashboard.value")}
                    </th>
                    <th scope="col">
                        {i18n.t("dashboard.state")}
                    </th>
                    <th scope="col">
                        {i18n.t("dashboard.options")} 
                    </th>
                </tr>
                </thead>
                <tbody>
                {forBusinesses}

                </tbody>
            </table>

        </div>
    )

}