import React from "react";

export default function BulletpointsTextarea(props){

    const{value, label, placeholder, inputName, err, businessBulletsRequired,businessBulletsMax, register} = (props) = props;

    return(

    <fieldset className="col-12">
        <label className="label-element">{label}</label>
        <textarea
            name={inputName}
            defaultValue={value}
            className={err?"error":"success"}
            maxLength={500}
            placeholder={placeholder}
            {...register(inputName, { required: true, maxLength: 500 })} />
        <div className={"error-message text-danger"}>
            {err?.type === 'required' && businessBulletsRequired}
            {err?.type === 'maxLength' && businessBulletsMax}
        </div>
    </fieldset>
    )
}