import { useForm } from "react-hook-form";
import i18n from '../../i18n.js'
import React, {useState, useEffect, useCallback} from "react";
import CountriesFetch from "../api/countries.fetch";
import { userContact } from "../api/userEndpoints.js";
import MessageStatus from "../message.status";
import Input from 'react-phone-number-input/input';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function ContactUsForm(props){
    const {language} = props;
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [countries, setCountries] = useState(null);
    const [sendState, setSendState] = useState(null);
    const [country, setCountry] = useState("") //selected country for phone code

    const nameRequired = i18n.t('form-error-messages.name');
    const nameMax = i18n.t('form-error-messages.name-max');
    const nameMin = i18n.t('form-error-messages.name-min');
    const mailRequired = i18n.t('form-error-messages.mail');
    const mailPattern = i18n.t('form-error-messages.mail-pattern');
    const phoneRequired = i18n.t('form-error-messages.phone');
    const phoneMax = i18n.t('form-error-messages.phone-max');
    const phoneMin = i18n.t('form-error-messages.phone-min');
    const countryRequired = i18n.t('form-error-messages.country');
    const commentRequired = i18n.t('form-error-messages.message');
    const commentMax = i18n.t('form-error-messages.message-max');
    const commentMin = i18n.t('form-error-messages.message-min');

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        const script = document.createElement('script');
        script.type = "text/javascript";
        script.src = "https://leads.godixital.com/js/leads_form_trigger.js";
        //script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const setPhoneCode = (id) =>{
        countries.forEach(function(item){
                if(id==item.id) {
                    setCountry(item.iso_code)
                }
            }
        )}

    /**
     * Recives data from form after validation
     * @param data object
     */
    const onSubmit = useCallback(async(data) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
    
        let recaptcha_token = await executeRecaptcha('contact');
        console.log("google recaptcha recived")
        setSendState(1);
        let body = {
            comments: data.comments,
            mail: data.mail,
            country: data.country,
            name: data.name,
            phone: data.phone,
            recibeNews: data.recibeNews,
            interest: 'contact',
            recaptcha_token: recaptcha_token
        }
        userContact(body).then(response => {
            if(response.status===200){
                setSendState(2)
                reset()
            }
        }).catch(function (response) {
            setSendState(0)
        })
    }, [executeRecaptcha])

    const forCountries = !countries? [] : countries.map((c, index) =>
        (
            <option value={c.id} key={index}>{language==="es"?c.name_es:c.name_pt}</option>
        )
    );

    return(
        <div className="contact-form">
            <CountriesFetch setCountries={setCountries}/>
            <h2 className="contactus_page_subtitle">
            {i18n.t('contact-us.form.text')}
            </h2>

            <form className="pt-5" onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="col-12">
                    <label className="label-element">{i18n.t('contact-us.form.label1')}</label>
                    <input className={errors.name?"input-info error":"input-info success"}
                        type="text"
                        placeholder={i18n.t('contact-us.form.placeholders.name')}
                        {...register("name", { required: true, maxLength: 20, minLength: 2 })} />
                        <div className={"error-message text-danger"}>
                            {errors.name?.type === 'required' && nameRequired}
                            {errors.name?.type === 'maxLength' && nameMax}
                            {errors.name?.type === 'minLength' && nameMin}
                        </div>

                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">{i18n.t('contact-us.form.label4')}</label>
                    <select className={errors.country?"input-info error":"input-info success"}
                            {...register("country", { required: true })}
                            disabled={!countries}
                            onChange={(e) => setPhoneCode(e.target.value)}
                    >

                        {countries?
                            <option value="">{i18n.t('contact-us.form.placeholders.country')}</option>
                            :
                            <option value="">{i18n.t('contact-us.form.placeholders.loading')}</option>
                        }

                        {forCountries}
                    </select>
                    <div className={"error-message text-danger"}>
                        {errors.country?.type === 'required' && countryRequired}

                    </div>
                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">{i18n.t('contact-us.form.label2')}</label>
                    <input className={errors.mail?"input-info error":"input-info success"}
                        type="mail"
                        placeholder={i18n.t('contact-us.form.placeholders.mail')}
                        {...register("mail", {required: true, pattern:  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                    />
                    <div className={"error-message text-danger"}>
                    {errors.mail?.type === 'required' && mailRequired}
                    {errors.mail?.type === 'pattern' && mailPattern}
                    </div>

                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">{i18n.t('contact-us.form.label3')}</label>
                    <Input className={errors.phone?"input-info error":"input-info success"}
                        placeholder={i18n.t('contact-us.form.placeholders.phone')}
                        {...register("phone", { required: true, maxLength: 20, minLength: 6 })}
                        disabled = {country === "" }
                        withCountryCallingCode
                        international
                        country={country}
                    />

                    <div className={"error-message text-danger"}>
                        {errors.phone?.type === 'required' && phoneRequired}
                        {errors.phone?.type === 'maxLength' && phoneMax}
                        {errors.phone?.type === 'minLength' && phoneMin}
                    </div>
                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">{i18n.t('contact-us.form.label5')}</label>
                    <textarea
                        className={errors.comments?"error":"success"}
                        placeholder={i18n.t('contact-us.form.placeholders.message')}
                        {...register("comments", { required: true, maxLength: 500, minLength: 10  })} />
                    <div className={"error-message text-danger"}>
                        {errors.comments?.type === 'required' && commentRequired}
                        {errors.comments?.type === 'maxLength' && commentMax}
                        {errors.comments?.type === 'minLength' && commentMin}
                    </div>
                </fieldset>

                <fieldset className="col-12">
                    <input type="checkbox"
                        {...register("recibeNews" )} />
                    <label className="label-check">{i18n.t('contact-us.form.check-box')}</label>
                </fieldset>

                <MessageStatus sendState={sendState}/>

                <div className="col-12 text-right">
                    <input className="btn btn-outline-primary g-action-button rounded-pill"
                        type="submit"
                        value="ENVIAR"/>
                </div>

            </form>

        </div>
    )
}