import React from "react";
import {Link} from "react-router-dom";

export default function NotFound() {
    return (
        <>
            <div className="text-center">
                <h2>La página que estas buscando no existe</h2>
            </div>

            <div className="button text-center d-flex justify-content-center pb-4">
                <Link to="/"
                    type="button"
                    className="btn btn-outline-primary g-action-button rounded-pill fix-button-link"
                >
                    IR A PÁGINA DE INICIO
                </Link>
            </div>

        </>
    )
}