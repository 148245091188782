import React, {useEffect, useState} from "react";
import i18n from "../../i18n";
import Slider from "@mui/material/Slider";
import {useForm} from "react-hook-form";
import CountriesFetch from "../api/countries.fetch";
import {useLocation} from "react-router";

export default function BlogFilters(props){
    const {loading, type, language, setFilters, setCurrentPage, setFilterApply} = props
    const [countries, setCountries] = useState(null);
    const {register ,setValue ,handleSubmit} = useForm();
    const location = useLocation();

    const onSubmit = (data) => {
        let country_id;
        let category;
        setCurrentPage(1);
        if(type==='blog'){
            country_id = false;
            category = data.blogCategory;
        }else{
            country_id = data.country;
            category = false;
        }

       setFilters(
           prevState => ({
               filter: {
                   ...prevState.filter,
                   category,
                   country_id,
                   date: data.blogDate,
                   language: data.blogLanguage
               }
           })
       )
       setFilterApply(true)
      }

      useEffect(() => {
          setValue('blogCategory', 'all')
          setValue('country', 'all')
          setValue('blogDate', 'desc')
          setValue('blogLanguage', language)
      },[]);

    const resetFilters = () => {
            setValue('blogCategory', 'all')
            setValue('country', 'all')
            setValue('blogDate', 'desc')
            setValue('blogLanguage', language)

            setFilters({filter:{type:type,language: language, date:'desc'}})

            }

            useEffect(() => {
               resetFilters()
            }, [location])

    const forCountries = !countries? [] : countries.map((c, index) =>
        (
            <option value={c.id} key={index}>{language==="es"?c.name_es:c.name_pt}</option>
        )
    );



    return(
        <div className="filters">
            <CountriesFetch setCountries={setCountries}/>
            <div className="filters-form shadow p-4">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={"row"}>

                        {type==='blog'&&
                        <fieldset className={"col-12 col-md-4 col-xl-3 d-block d-md-flex justify-content-between align-items-center pt-2 pxl-0"}>
                                <p className="label-element mb-0 px-2">{i18n.t('blog.filters.category')}</p>
                                <select className="input-info"
                                        name="blogCategory"
                                        disabled = {loading}
                                        {...register("blogCategory")}
                                >
                                    <option value="all">{i18n.t('blog.filters.all')}</option>
                                    <option value={"seller"}>{i18n.t('blog.filters.seller')}</option>
                                    <option value={"buyer"}>{i18n.t('blog.filters.buyer')}</option>
                                </select>
                            </fieldset>
                        }

                        {type==='prensa'&&
                        <fieldset className={"col-12 col-md-4 col-xl-3 d-block d-md-flex justify-content-between align-items-center pt-2 pxl-0"}>
                            <p className="label-element mb-0 px-2">País</p>
                            <select className="input-info"
                                    name={"country"}
                                    disabled={!countries || loading}
                                    {...register("country")}
                            >
                                <option value="all">Todos</option>
                                {forCountries}
                            </select>

                        </fieldset>
                        }

                            <fieldset className={"col-12 col-md-4 col-xl-2 d-block d-md-flex justify-content-between align-items-center pt-2 pxl-0"}>
                                <p className="label-element mb-0 px-2">{i18n.t('blog.filters.date')}</p>
                                <select className="input-info"
                                        name="blogDate"
                                        disabled = {loading}
                                        {...register("blogDate")}
                                >
                                    <option value={"asc"}>{i18n.t('blog.filters.old')}</option>
                                    <option value={"desc"}>{i18n.t('blog.filters.recent')}</option>

                                </select>
                            </fieldset>

                            <fieldset className={"col-12 col-md-4 col-xl-3 d-block d-md-flex justify-content-between align-items-center pt-2 pxl-0"}>
                                <p className="label-element mb-0 px-2">{i18n.t('blog.filters.language')}</p>
                                <select className="input-info"
                                        name="blogLanguage"
                                        disabled = {loading}
                                        {...register("blogLanguage")}
                                >
                                    <option value="all">{i18n.t('blog.filters.all')}</option>
                                    <option value={"es"}>{i18n.t('lang-selector.button-es')}</option>
                                    <option value={"pt"}>{i18n.t('lang-selector.button-pt')}</option>

                                </select>
                            </fieldset>


                    <div className={"col-12 col-md-12 col-xl-4 d-block d-md-flex justify-content-center align-items-center pt-2 pxl-0"}>
                            <div className={"submit-filters px-2"}>
                                <input className="btn btn-outline-primary g-action-button rounded-pill w-100"
                                       type="submit"
                                       value={i18n.t('blog.filters.apply')}
                                       disabled = {loading}
                                />
                            </div>

                                <div onClick={resetFilters} className={"reset-filters text-center"}>
                                    <button
                                        className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline pt-2 pxl-0"
                                        disabled = {loading}
                                        type={"button"}
                                    >{i18n.t('blog.filters.reset')}</button>
                                </div>
                    </div>

                    </div>
                </form>


            </div>
        </div>
    )
}
