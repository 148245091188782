import React, { useEffect } from 'react'
import { ENV_URL } from '../../app/constants'

/**
 * @return {null}
 */

export default function BusinessCategoriesFetch(props) {
    const { setBusinessCategories } = (props = props)

    const fetchData = async () => {
        let url = `${ENV_URL}/api/V1/business/categories/index`
        const api = await fetch(url)
        if (api.status === 200) {
            const dataApi = await api.json()
            setBusinessCategories(dataApi)
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return null
}
