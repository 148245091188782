import './howTo.scss'
import Account from '../assets/images/account-card.png'
import Display from '../assets/images/display-card.png'
import Deal from '../assets/images/deal-card.png'
import HowToCard from './howToCard'
import {Link} from "react-router-dom";
import i18n from '../i18n.js'


export default function HowToSell({isHome=false}) {
    return (
        <div className="htb" id="howToSell">
            <div className="container pt-2 pt-xl-5 pt-lg-5 pb-5">
                <div className="row pt-5 pb-5 section-title">
                    <div className="d-flex col-12 justify-content-start justify-content-md-center text-left text-md-center">
                        <h6>{i18n.t('homepage.how-to-sell.header-text')}</h6>
                    </div>
                    <div className="d-flex col-12 justify-content-start justify-content-md-center text-left text-md-center">
                        { isHome ?
                            <h2>
                                {i18n.t('homepage.how-to-sell.title')}
                            </h2>
                            :
                            <h1>
                                {i18n.t('homepage.how-to-sell.title')}
                            </h1>
                        }
                    </div>
                    <div className="d-flex col-12 justify-content-start justify-content-md-center mt-4">
                        <div className="d-flex col-8 text-left text-md-center">
                            { isHome ?
                                <p>
                                    {i18n.t('homepage.how-to-sell.text')}
                                </p>
                                :
                                <h2 className='htb_page_subtitle'>
                                    {i18n.t('homepage.how-to-sell.text')}
                                </h2>
                            }
                        </div>
                    </div>
                </div>
                <div className="row pt-0 pt-md-5 pb-5 px-xl-5 mx-xl-5 px-lg-5 mx-lg-5">
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-5">
                        <HowToCard
                            image={Account}
                            number={'01'}
                            text={i18n.t('homepage.how-to-sell.cards.1')}
                        />
                    </div>
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-5">
                        <HowToCard
                            image={Display}
                            number={'02'}
                            text={i18n.t('homepage.how-to-sell.cards.2')}
                        />
                    </div>
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-5">
                        <HowToCard image={Deal} number={'03'} text={i18n.t('homepage.how-to-sell.cards.3')} />
                    </div>
                </div>
                <div className="row justify-content-center align-items-center px-md-5 mx-md-5">
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 justify-content-center align-items-center mx-0">
                        <Link to="/vende-tu-negocio"
                            type="button"
                            className="fix-button-link btn w-100 btn-primary rounded-pill g-action-button"
                        >
                            {i18n.t('homepage.how-to-sell.button1')}
                        </Link>
                    </div>
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 justify-content-center mx-0">
                        <Link to="/preguntas-frecuentes/vendedor"
                            type="button"
                            className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                        >
                            {i18n.t('homepage.how-to-sell.button2')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
