import cellphoneIcon from "../../assets/icons/cellphone.svg";
import mailIcon from "../../assets/icons/mailIcon.svg";
import gpsMarker from "../../assets/icons/gpsMarker.svg";
import React, {useState} from "react";
import i18n from '../../i18n.js'

export default function ContactUsInfo(props){
    const {language} = props;

    return(
        <div className="contact-info">
            <p className="pt-4 pb-4">
            {i18n.t('contact-us.info.text1')}
            <br></br>
            {i18n.t('contact-us.info.text2')}
            </p>
            <div className="info">
                <p className="pb-3">
                    <img
                        src={cellphoneIcon}
                        alt="Ícono teléfono"
                        className="px-2"
                    />
                    <span className="pl-5"><a href="tel:59897228603">+598 9722 8603</a></span>
                </p>
                <p className="pb-3">
                    <img
                        src={mailIcon}
                        alt="Ícono correo electrónico"
                        className="px-2"
                    />
                    <span className="pl-5"> {language==="es"?"info@sitiodetiendas.com":"contato@seuproximonegocio.com.br"}</span>
                </p>
                <p className="pb-3 d-flex">
                    <img
                        src={gpsMarker}
                        alt="Ícono localización"
                        className="px-2"
                    />
                    <span className="pl-5"><br/>
                        LATAM, Argentina, Brasil, Chile,<br/>
                        Colombia, México, Perú, Uruguay
                    </span>
                </p>

            </div>

        </div>
    )
}