import React from "react";
import BlogCard from "./blog.blogCard";
import Pagination from "@mui/material/Pagination/Pagination";
import i18n from "../../i18n";

export default function BlogList(props) {

    const {blogs, currentPage, setCurrentPage, data, language} = props;
    const {last_page} = blogs?blogs:0;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value)
    };

    let forBlogs = !data.length>0?
        <h3 className={"alert alert-warning"}>  {i18n.t('blog.list.message')}</h3>
        :
        data.map((blog, index) =>
            (
                <div key={index} className={"col-12 col-md-4 pt-4"}>
                <BlogCard blog={blog} />
                </div>
            )
        );

    return(
        <div>

        <div className={"row result-list"}>
                {forBlogs}
        </div>

            <div className={"custom-pagination mt-5"}>

                <Pagination
                    count={last_page}
                    onChange={handleChange}
                    page={currentPage}
                />

            </div>
        </div>
    )

}