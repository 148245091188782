import React from "react";
import i18n from '../i18n'

export default function SusbscribeStatus(props){
    const {sendState} = props;

    return(
        <div className={"status-alert"}>
            {sendState === 1 ?
                <p className={"text-center alert alert-warning mt-1"}>
                    {i18n.t("message-status.sending")}
                </p>
                :
                <p className={"text-center"}/>
            }

            {sendState === 0 ?
                <p className={"text-center alert alert-danger"}>
                     {i18n.t("message-status.error")}
                </p>
                :
                <p className={"text-center"}/>
            }

            {sendState === 2 ?
                <p className={"text-center alert alert-success"}>
                     {i18n.t("message-status.mail-success")}
                </p>
                :
                <p className={"text-center"}/>
            }
        </div>
    )
}