import {Link} from "react-router-dom";
import React from "react";import i18n from '../../../i18n'

export default function TopButtons(props) {
    const {username,view, setView} = (props) = props;

    const handleView = (view) =>{
        if(view==='profile'){
            setView('business')
        }else{
            setView('profile')
        }
    }

    return (
        <div className="row top-buttons justify-content-center">

            <div className={"row"}>
                <div className={"col-md-6 col-12"}>
            <h3 className={"text-center pb-3"}> {i18n.t("dashboard.welcome")} {username} </h3>
                </div>
                <div className={"col-md-6 col-12 mb-1 mb-md-0"}>

                    <button
                    className="btn btn-outline-primary rounded-pill w-100"
                    onClick={()=>handleView(view)}
                    >
                        {view==='business'?
                            i18n.t("dashboard.my-profile")
                        :
                            i18n.t("dashboard.my-businesses")
                    }
                </button>

                </div>
            </div>

            <div className="d-flex col-12 col-md-6 justify-content-center align-items-center mb-1 mb-md-0">
                <Link to="/vende-tu-negocio"
                      className="w-100 sell btn btn-outline-primary rounded-pill"
                >
                    {i18n.t("dashboard.new-business")}

                </Link>
            </div>
            <div className="d-flex col-12 col-md-6 justify-content-center mx-0">

            <Link
                    className="w-100 btn btn-outline-primary rounded-pill"
                    aria-current="page"
                    to="/negocios-disponibles"
                >
                    {i18n.t("dashboard.available")}
                    
                </Link>

            </div>
        </div>
    )
}