import React from "react";
import i18n from '../i18n.js'

export default function MessageStatus(props){
    const {sendState} = props;

    return(
        <div className={"status-alert pt-2"}>
            {sendState === 1 ?
                <p className={"text-center alert alert-warning"}>{i18n.t('message-status.sending')}</p>
                :
                <p className={"text-center"}/>
            }

            {sendState === 0 ?
                <p className={"text-center alert alert-danger"}>{i18n.t('message-status.error')}</p>
                :
                <p className={"text-center"}/>
            }

            {sendState === 2 ?
                <p className={"text-center alert alert-success"}>{i18n.t('message-status.success')}</p>
                :
                <p className={"text-center"}/>
            }
        </div>
    )
}