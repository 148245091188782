import React from "react";
import i18n from '../../../i18n.js';

export default function StatusTag(props){
    const {business} = (props) = props;


    return(
        <div className="d-flex">
    {business.state==0 &&
       <div className={"state state-available"}>
        <span>{i18n.t('business-detail.detail.status-0')}</span>
       </div>
    }

    {business.state==1 &&
    <div className={"state state-reserved"}>
        <span>{i18n.t('business-detail.detail.status-1')}</span>
    </div>
    }
    {business.state==2 &&
    <div className={"state state-sold"}>
        <span>{i18n.t('business-detail.detail.status-2')}</span>
    </div>
    }

    <div className={"state visit"}>
        <span>{i18n.t('business-detail.detail.visit-counter')}: {business.visit_count}</span>        
    </div>

        </div>
    )
}