import React from 'react'

export default function TestimonialCard(props) {
    const { image, user, testimonial, description } = props

    return (
        <div className="card shadow">
            <div
                className={`card-body text-start pt-4 px-1`}
            >
                <h5 className="card-title col-12 mb-3 mt-1 px-1">
                    <span style={{ color: '#357fd8' }}>"</span>
                    <span style={{ fontStyle: 'italic' }}>{testimonial}</span>
                    <span style={{ color: '#357fd8' }}>"</span>
                    <span>
                        &nbsp;&nbsp;<b style={{ textDecorationLine: 'underline' }}>{user}</b>
                    </span>
                </h5>
            </div>
        </div>
    )
}
