import React, { useState } from 'react'
import i18n from '../../i18n'
import TestimonialsBanner from './testimonials.page.banner'
import CarouselDesktop from './testimonials.carousel-d'
import CarouselMobile from './testimonials.carousel-m'
import Suscribe from '../suscribe'
import GetInTouch from '../getIntouch'
import Selector from './testimonials.page.selector'

export default function TestimonialsPage(props) {
    const { language } = props
    const [testimonial, setTestimonial] = useState('seller')

    return (
        <section className="testimonials-section">
            <div className={'testimonials-section-content white py-3'}>
                <TestimonialsBanner />

                <div className={'container'}>
                    <Selector
                        testimonial={testimonial}
                        setTestimonial={setTestimonial}
                    />

                    <div className="carousel mb-5 mt-5">
                        <div className="d-none d-md-block">
                            <CarouselDesktop
                                language={language}
                                testimonial={testimonial}
                            />
                        </div>
                        <div className="d-md-none">
                            <CarouselMobile
                                language={language}
                                testimonial={testimonial}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Suscribe language={language} />

            <GetInTouch language={language} />
        </section>
    )
}
