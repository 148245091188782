import React from "react";

export default function PercentageInput(props){

    const{label, placeholder, inputName, err, quantityRequired,quantityMin,percentageMax, register, value} = (props) = props;

    return(
        <fieldset className="col-12 col-md-6">
            <label className="label-element">{label}</label>
            <input className={err?"input-info error":"input-info success"}
                   defaultValue = {value}
                   name={inputName}
                   type="number"
                   min={0}
                   max={100}
                   placeholder={placeholder}
                   {...register(inputName, { required: true, min: 0, max: 100 })} />
            <div className={"error-message text-danger"}>
                {err?.type === 'required' && quantityRequired}
                {err?.type === 'min' && quantityMin}
                {err?.type === 'max' && percentageMax}
            </div>

        </fieldset>
    )
}