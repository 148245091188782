import React from "react";
import i18n from "../../../i18n";

export default function BulletpointsInfo(props) {
    const {business} = (props) = props;
    return(
        <div className={"bulletpoints"}>
            <ul>
                <li><span className={"custom-bullet"}></span>
                    {i18n.t('business-detail.detail.model_description')}
                </li>
                <p>{business.model_description}</p>

               <li><span className={"custom-bullet"}></span>
                    {i18n.t('business-detail.detail.product_margin_description')}
               </li>
                <p>{business.product_margin_description}</p>

                <li><span className={"custom-bullet"}></span>
                    {i18n.t('business-detail.detail.cost_structure_description')}
                </li>
                <p>{business.cost_structure_description}</p>

                <li><span className={"custom-bullet"}></span>
                    {i18n.t('business-detail.detail.delivered_with_description')}
                </li>
                <p>{business.delivered_with_description}</p>

            </ul>

        </div>
    )

}