import {Link} from "react-router-dom";
import React from "react";

export default function BottomButtons() {
    return(
        <div className="row justify-content-center align-items-center px-md-5 mx-md-5">
            <div className="d-flex col-12 col-md-6 justify-content-center align-items-center">
                <Link to="/preguntas-frecuentes"
                      type="button"
                      className="fix-button-link btn w-100 btn-primary rounded-pill g-action-button"
                >
                    FAQ
                </Link>
            </div>
            <div className="d-flex col-12 col-md-6 justify-content-center">
                <Link to="/"
                      type="button"
                      className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                >
                    INICIO
                </Link>
            </div>
        </div>
    )

}