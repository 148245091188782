import './whoWeAre.scss'
import whoWeAreEs from '../assets/images/whoWeAre-es.webp'
import whoWeArePt from '../assets/images/whoWeAre-pt.png'
import {Link} from "react-router-dom";
import i18n from '../i18n.js'

export default function WhoWeAre(props) {
    return (
        <section className="container wwa-c">
            <div className="row">
                <div className="d-flex col-xs-12 col-sm-12 col-md-6 col-lg-6 wwa-c1 justify-content-center">
                    <img
                        src={
                            props.language === "es" ? whoWeAreEs : whoWeArePt
                        }
                        alt="logo conózcanos"
                        className="wwa-c1-image"
                    />
                </div>
                <div className="d-flex col-xs-12 col-sm-12 col-md-6 col-lg-6 align-items-center">
                    <div className="align-items-center justify-content-center wwa section-title">
                        <h6 className="start-content-title">
                        {i18n.t('homepage.who-we-are.header-text')}
                        </h6>
                        <h2>{i18n.t('homepage.who-we-are.title')}</h2>
                        <p className="start-content-text">
                        {i18n.t('homepage.who-we-are.text')}
                        </p>
                        <div className="d-flex" style={{ marginTop: '40px' }}>
                            <Link to="/vende-tu-negocio"
                                type="button"
                                className="fix-button-link btn btn-primary rounded-pill start-button g-action-button"
                            >
                                {i18n.t('homepage.who-we-are.button')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
