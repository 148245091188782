import React, { useState } from 'react'
import './quotation.css'
import QuotationForm from './quotationForm/quotationForm'
import QuotationSuccess from './quotationSuccess'
import QuotationHeader from './quotationHeader'
import i18n from '../../i18n.js'

export default function Quotation(props) {
    const { language } = props

    const [quotationSuccess, setQuotationSuccess] = useState(false) //changes from data form to confirmation message
    const [savedPersonalData, setSavedPersonalData] = useState(false) //user personal data complete

    const quotationTitle = i18n.t('quotation.title')
    const successMessage = i18n.t('quotation.successMessage')
    const successTitle = i18n.t('quotation.successTitle')
    const quotationParagraph01 = i18n.t('quotation.paragraphs.pg01')
    const quotationParagraph02 = i18n.t('quotation.paragraphs.pg02')
    const quotationParagraph03 = i18n.t('quotation.paragraphs.pg03')
    const pgBusinessForm01 = i18n.t('quotation.paragraphs.pgBusinessForm01')
    const pgBusinessForm02 = i18n.t('quotation.paragraphs.pgBusinessForm02')
    const pgBusinessForm03 = i18n.t('quotation.paragraphs.pgBusinessForm03')
    const quotationTestimonies = i18n.t('quotation.testimonies')

    const errorMessage = i18n.t('quotation.errorMessage')
    const errorTitle = i18n.t('quotation.errorTitle')

    const successSubtitle = i18n.t('quotation.successSubtitle')
    const successText = i18n.t('quotation.successText')

    const errorSubtitle = i18n.t('quotation.errorSubtitle')
    const errorText = i18n.t('quotation.errorText')

    const displayHeader = () => {
        switch (quotationSuccess) {
            case true:
                return (
                    <QuotationHeader
                        title={successTitle}
                        message={successMessage}
                    />
                )
            case false:
                return (
                    <QuotationHeader
                        title={quotationTitle}
                        language={language}
                        message={{
                            quotationParagraph01,
                            quotationParagraph02,
                            quotationParagraph03,
                            pgBusinessForm01,
                            pgBusinessForm02,
                            pgBusinessForm03,
                            quotationTestimonies,
                        }}
                        savedPersonalData={savedPersonalData}
                    />
                )
            default:
                return (
                    <QuotationHeader
                        title={errorTitle}
                        message={errorMessage}
                    />
                )
        }
    }

    const displayContent = () => {
        switch (quotationSuccess) {
            case true:
                return (
                    <QuotationSuccess
                        subtitle={successSubtitle}
                        text={successText}
                    />
                )
            case false:
                return (
                    <QuotationForm
                        setQuotationSuccess={setQuotationSuccess}
                        language={language}
                        savedPersonalData={savedPersonalData}
                        setSavedPersonalData={setSavedPersonalData}
                    />
                )
            default:
                return (
                    <QuotationSuccess
                        subtitle={errorSubtitle}
                        text={errorText}
                    />
                )
        }
    }

    return (
        <section className={'quotation'}>
            <div className={'quotation-section-content container'}>
                {displayHeader()}

                <div className={'quotation-form contact shadow'}>
                    {displayContent()}
                </div>
                <div className={'gradient-bottom shadow mb-4 col-12'}></div>
            </div>
        </section>
    )
}
