import './testimonials.scss'
import CarouselMobile from './testimonials.carousel-m'
import CarouselDesktop from './testimonials.carousel-d'
import { Link } from 'react-router-dom'
import i18n from '../../i18n'
import React from 'react'

export default function Testimonials(props) {
    const { language } = props
    return (
        <section className="testimonials-section">
            <div className="testimonials-section-content py-5">
                <div className="container mt-5">
                    <div className="section-title text-center testimonial-custom-title">
                        <h6>{i18n.t('homepage.testimonials.header-text')}</h6>
                        <h2>{i18n.t('homepage.testimonials.title')}</h2>
                    </div>

                    <div className="carousel mb-5 mt-5">
                        <div className="d-none d-md-block">
                            <CarouselDesktop
                                language={language}
                                testimonial={'all'}
                            />
                        </div>

                        <div className="d-md-none">
                            <CarouselMobile
                                language={language}
                                testimonial={'all'}
                            />
                        </div>
                    </div>

                    <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: '40px' }}
                    >
                        <Link
                            to="/testimonios"
                            type="button"
                            className="fix-button-link btn btn-primary rounded-pill start-button g-action-button"
                        >
                            {i18n.t('homepage.testimonials.more')}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
