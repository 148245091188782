import React from "react";

export default function TermsTextEs() {
    return(
        <div className="terms-text-content">
            <div>
            <h4>Termos e Condições de Uso </h4>
                <p>
                Seu Próximo Negócio é uma marca registrada sob as leis Brasileiras e as leis dos diferentes países onde opera de acordo com os regulamentos aplicáveis. 
                </p>
                <p>
                Sitiodetiendas.com é propriedade de pessoa física representada por Sítio de Tiendas Sociedad por Ações com endereço real na Calle Colonia 981 Apto 305, na cidade de Montevidéu, República Oriental do Uruguai.
                </p>
                <p>
                Seu Próximo Negócio é uma página web que oferece serviços de cotação e compra e venda de sites (Negócios Digitais) através da forma contratual de Cessão de Direitos
                </p>
                <p>
                Nesse sentido, as pessoas que nele ingressarem para usufruir dos benefícios que oferece, serão denominados USUÁRIOS, que encontrarão todos os serviços e conteúdos relacionados aos diversos sites de Negócios Digitais.
                </p>
                <p>
                Qualquer pessoa que utilize o Site, “USUÁRIO” aceita todos e quaisquer destes Termos e Condições de Uso, suas modificações e documentos complementares e compromete-se a cumpri-los sem objeções.
                </p>
                <p>
                Todos os conteúdos do site pertencem ao domínio de www.seuproximonegocio.com.br e o USUÁRIO pode ter acesso aos mesmos na forma que o site o permite. O usuário assume a responsabilidade pelo uso do portal.
                </p>
                <p>
                O site reserva-se o direito, a seu exclusivo critério, de modificar, alterar ou atualizar estes Termos de Uso a qualquer momento. As modificações entrarão em vigor imediatamente, a menos que seja especificamente indicado em outro momento. Uma vez feitas essas modificações, ao utilizar o site, você concorda em estar sujeito às mesmas, alterações ou atualizações dos Termos e Condições de Uso, sem o direito de fazer qualquer reclamação em relação aos mesmos. Se você não concorda com qualquer um dos Termos de Uso, não use este Site.
                </p>
            </div>
            <div>
                <h4>Condições de acesso e uso do Site:</h4>
                <ol>
                    <li>
                    A utilização de qualquer Serviço do site atribui a condição de utilizador do mesmo.
                    </li>
                    <li>
                    O Site é de livre circulação, exceto para aqueles que desejam cotar, vender ou comprar seu Negócio Digital, que neste caso deverá informar seus dados de contato por meio dos formulários fornecidos. Da mesma forma, o Usuário será responsável por fornecer informações verdadeiras e lícitas.                    </li>
                    <li>
                    Seu Próximo Negócio cumpre todos os regulamentos relativos à proteção de dados.
                    </li>
                </ol>
            </div>

            <div>
                <h4>Uso do site e seus serviços</h4>
                <p>
                O Usuário reconhece e aceita que o uso do conteúdo e / ou Serviços oferecidos pelo site será por sua própria conta e risco e / ou responsabilidade. O Usuário concorda em usar o site e todo o seu conteúdo e Serviços de acordo com a lei, moralidade, ordem pública, estes Termos deUso, quando aplicável, são aplicáveis. Da mesma forma, compromete-se a fazer uso adequado dos Serviços e / ou conteúdos do site e a não utilizá-los para a realização de atividades ilegais ou criminosas, que violem os direitos de terceiros e / ou que violem o regulamento sobre propriedade intelectual e industrial , ou quaisquer outras regras do sistema jurídico aplicável.                </p>
                <p>
                É expressamente proibido realizar qualquer ato que possa causar danos aos sistemas físicos e lógicos do site, seus fornecedores ou terceiros, introduzir ou disseminar vírus de computador ou quaisquer outros sistemas físicos ou lógicos que possam causar os danos mencionados.                </p>
            </div>
            <div>
                <h4>Serviços em particular</h4>
                <p>
                Este site oferece o serviço de cotação, publicação e gestão e intervenção na venda por cessão dos direitos de Digital Business de quem o pretenda, mediante consentimento expresso e de acordo com os termos e condições por eles estabelecidos e aceitos.                 </p>
                <p>
                Ao se cadastrar e / ou navegar no Site, o USUÁRIO e / ou o visitante fornece informações pessoais, dando seu consentimento livre, expresso e informado para que sejam coletadas e armazenadas diretamente em nosso banco de dados, sendo protegidas eletronicamente, utilizando os mecanismos de proteção de segurança informática das informações mais completas e eficazes para mantê-las em total sigilo. Toda a informação fornecida será usada exclusivamente para a implementação do presente contrato. Além disso, todas as informações serão protegidas de acordo com os padrões de privacidade, em conformidade com o Regulamento Geral de Proteção de Dados (GDPR) da União Europeia. Argentina: Lei de Proteção de Dados Pessoais Nº 25.326, Brasil: Lei Geral de Proteção de Dados (LGPD), Chile: Lei Nº 19.628 e Lei Nº 19.628, México: Lei Federal de Proteção de Dados Pessoais em Posse de Particulares (LFPDPPP), Colômbia: Lei 1581, Peru: Lei de Proteção de Dados Pessoais Nº 29733, Uruguai: Lei Nº 18.331 sobre Proteção de Dados Pessoais e Ação de "Habeas Data".</p>
                <p>
                O USUÁRIO é responsável pelo uso adequado do Site, comprometendo-se expressamente a evitar qualquer tipo de ação que possa danificar sistemas, equipamentos ou serviços acessíveis direta ou indiretamente através da Internet, incluindo o congestionamento intencional de links ou sistemas e de acordo com o presente condições.</p>
                <p>
                Fica expressamente esclarecido que o Site pode ser utilizado única e exclusivamente por maiores de 18 anos, devendo as pessoas que não tenham mais de 18 anos se absterem de utilizá-lo. 
                </p>
            </div>
            <div>
                <h4>Interesse em um Negócio Digital</h4>
                <p>
                O Usuário deve selecionar da lista disponível as opções que lhe interessam, preenchendo todas as informações que a página solicita, para ser contatado para dar continuidade ao processo.                 </p>
                <p>
                O usuário poderá solicitar um orçamento para seu Negócio Digital, que será estabelecido levando-se em consideração fatores de faturamento e características da marca, levando em consideração o número de seguidores, participação em redes, notoriedade, logística, registros de marca / domínio, propriedade de aplicativos, horas trabalhadas e tempo de mercado.                 </p>
                <p>
                Seu Próximo Negócio disponibilizará ao interessado toda a informação de forma a gerar ferramentas que o levem a definir a obtenção do Negócio Digital de interesse.                 </p>
                <p>
                É proibido o contacto direto entre os interessados, sendo sempre e indesculpável o seuproximonegocio.com.br o intermediário para a concretização da transação. Ou seja, osinteressados tanto na aquisição quanto na venda não poderão contornar o Seu Próximo Negócio para realizar a operação.                </p>
                <p>
                Caso o Vendedor encontre um comprador por vias próprias, deverá apresentá-lo ao seuproximonegocio.com.br para efetuar e formalizar a transação, nas mesmas condições acima mencionadas. Neste caso, a comissão será de 50% da comissão normal.                 </p>
                <p>
                Toda a atividade comercial será realizada com a intervenção de Seu Próximo Negócio.                 </p>
                <p>
                Quem põe à venda um negócio digital deve ser o verdadeiro dono do mesmo, que deve fornecer ao Seu Próximo Negócio as informações que corroborem a referida situação. Além disso, www.seuproximonegocio.com.br, compromete-se a não utilizar os dados fornecidos pelo vendedor para qualquer outra finalidade que não seja a realização deste negócio.                </p>
                
                <p>
                Seu Próximo Negócio, irá auditar através de ferramentas digitais a veracidade dos dados fornecidos pelo vendedor relativamente ao negócio digital à venda. Isto apenas no que se refere às informações existentes nas plataformas digitais, tráfego, público e interação com terceiros e pagamentos, não podendo corroborar quaisquer outros dados alheios aos mesmos.                 </p>
                <p>
                Seu Próximo Negócio irá solicitar ao vendedor, uma vez aceito a oferta de compra, todas as permissões, autorizações, palavras-passe e / ou quaisquer outras informações que faça para a transferência de todos os direitos do Negócio Digital comercializado.                 </p>
                <p>
                Seu Próximo Negócio concorda com o vendedor a forma de transferência do mesmo.Quem vende (cede direitos) renuncia a todos os direitos que integram o Negócio Digital cedido, não podendo reclamar qualquer tipo de direito do adquirente desde o momento da realização da cessão e recebimento do valor objeto da operação.                 </p>
                <p>
                Caso a operação não seja realizada por motivos alheios ao controle de Seu Próximo Negócio, o inadimplente será punido com multa correspondente a 14% do valor da operação por sua culpa frustrada.                 </p>
            </div>
            <div>
                <h4>Termos gerais</h4>
                <p>
                Todas as informações sobre os Negócios Digitais publicadas e / ou oferecidas pelo site são fornecidas pelos Usuários na qualidade de titulares do Domínio sobre ele, sendo essas informações de responsabilidade exclusiva dos mesmos. O USUÁRIO é responsável pelo uso adequado do serviço, comprometendo-se expressamente a evitar qualquer tipo de ação que possa danificar sistemas, equipamentos ou serviços acessíveis direta ou indiretamente através da Internet, incluindo o congestionamento intencional de links ou sistemas e de acordo com estas condições.                 </p>
                <p>
                O USUÁRIO assume total responsabilidade perante Seu Próximo Negócio e terceiros pelos danos que possam ocorrer em decorrência de suas próprias ações, seus dependentes ou terceiros ligados por meio do USUÁRIO e aqueles decorrentes do não cumprimento das leis ou regulamentos de terceiros ilícitos atos ou uso indevido do serviço, o USUÁRIO deverá indenizar e isentar o Seu Próximo Negócio de qualquer reclamação de pagamento que possa corresponder.                 </p>
             
                <p>
                Seuproximonegocio.com.br reserva-se o direito de expandir ou melhorar os serviços prestados sem necessidade de comunicação prévia, bem como de alterar os meios ou vias de prestação do serviço.                 </p>
                <p>
                Seuproximonegocio.com.br não se responsabiliza por danos, alteração de informações ou interferências no sistema ou nas redes, resultantes da ação de terceiros. A empresa Seu próximo negócio não se responsabiliza pelos danos causados, incluindo casos fortuitos e de força maior, que possam ocorrer aos equipamentos, instalações ou pessoal do usuário.                </p>
              
            </div>
            <div>
                <h4>Declarações</h4>
                <p>
                Os usuários expressamente entendem, aceitam e concordam que: 
                </p>
                <ol>
                    <li>
                    O site pode conter erros.
                    </li>
                    <li>
                    O Site não será responsável por qualquer perda ou dano, direto ou indireto, incluindo, sem qualquer limitação, danos causados pela perda e / ou deterioração das informações e / ou pelo uso do Site.                    </li>
                    <li>
                    A utilização do Site e da Internet em geral implica assumir riscos de potenciais danos ao software e hardware do USUÁRIO. Por este motivo, o equipamento terminal do qual o USUÁRIO acessa o Site estaria em posição de ser atacado e danificado pela ação de hackers que poderiam inclusive acessar a informação contida no equipamento terminal do USUÁRIO, extraí-la, roubá-la e / ou danificá-lo. O USUÁRIO renuncia a fazer reclamações por esses motivos.                    </li>
                    <li>
                    A troca de informações pela Internet acarreta o risco de que tais informações sejam capturadas por terceiros. O Site não se responsabiliza pelas consequências que tal hipótese possa levar ao USUÁRIO.                     </li>
                    <li>
                    Seu Próximo Negócio não se responsabiliza, em qualquer caso, por danos de qualquer espécie que possam causar, a título de exemplo: erros ou omissões no conteúdo, indisponibilidade do portal ou transmissão de vírus ou programas maliciosos ou nocivos no conteúdo, apesar de ter adotado todas as medidas tecnológicas necessárias para evitá-lo.                     </li>
                    <li>
                    Seu Próximo Negócio reserva-se o direito de negar ou retirar o acesso ao portal e / ou aos serviços oferecidos sem aviso prévio, a seu pedido, a quem não cumpra as presentes Condições Gerais de Utilização. O Site não guarda nenhuma obrigação de guardar as informações que colocou à disposição do USUÁRIO, nem que tenham sido enviadas por este.
                    </li>
                </ol>
               
                <p>
                As pessoas que utilizam o Site sitiodetiendas.com, por seu uso exclusivo, aceitarão integralmente todas e cada uma das disposições estabelecidas nestes Termos de Uso, sem ressalvas.
                </p>
                <p>
                Em caso de controvérsia e, por se tratar de transações com participantes de diferentes países, estarão sujeitas às Regras de Arbitragem Internacional.
                </p>
            </div>
          </div>
    )

}