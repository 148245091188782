import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {passwordCreate, passwordUpdate} from "../api/userEndpoints";
import {useNavigate, useParams} from "react-router";
import loadingImage from "../../assets/icons/loading.gif";
import i18n from '../../i18n'
import {Helmet} from "react-helmet"

export default function CreatePassword(props){

    //document.title = props.title
    const passwordRequired = i18n.t("form-error-messages.password");
    const passwordMax = i18n.t("form-error-messages.password-max");
    const passwordMin = i18n.t("form-error-messages.password-min");

    const passwordConfirmRequired  = i18n.t("form-error-messages.confirm-required");
    const passwordConfirmMatch = i18n.t("form-error-messages.confirm-match");

    const getParams = useParams();

    const [noUser, setNoUser] = useState(false);
    const [conectionError, setConectionError] = useState(false);
    const [danger, setDanger] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const {userId, token} = getParams;
    const [changed, setChanged] = useState(false)

    const handleNavigate = (route) =>{
        navigate(route)
    }

    const formValidation = Yup.object().shape({
        password: Yup.string()
            .required(passwordRequired)
            .min(3, passwordMin)
            .max(15, passwordMax),
        passwordConfirm: Yup.string()
            .required(passwordConfirmRequired)
            .oneOf([Yup.ref('password')], passwordConfirmMatch),
    })

    const formOptions = { resolver: yupResolver(formValidation) }
    const { register, handleSubmit, reset, formState } = useForm(formOptions)
    const { errors } = formState


    useEffect(() => {
        if(!changed){
            checkToken()
        }
    }, [])


    const checkToken = () =>{
       try {
            passwordCreate(userId, token).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    setDanger(false)
                    setNoUser(false)
                    setConectionError(false)
                }

                if (response.status === 204) {
                    setLoading(false)
                    setDanger(false)
                    setConectionError(false)
                    setNoUser(true)
                    console.log("error 204")
                }

                if (response.status === 203) {
                    setLoading(false)
                    setDanger(true)
                    setNoUser(false)
                    setConectionError(false)
                    console.log("catch axios")
                }
            }).catch((response) => {
                setLoading(false)
                setDanger(false)
                setConectionError(true)
                setNoUser(false)
            
            })
        }catch (e) {
            setLoading(false)
            setDanger(false)
            setConectionError(true)
            setNoUser(true)
            console.log("try catch")
        }
    }


    function onSubmit(data) {
        try{
        setLoading(true);
        const formDataRes = new FormData();

        formDataRes.append('id', userId);
        formDataRes.append('newPassword', data.password);

        passwordUpdate(userId, token, formDataRes).then((response) =>{

            if (response.status === 200) {
                setLoading(false)
                setDanger(false)
                setChanged(true)
            }

        }).catch((response) => {
            setLoading(false)
            setDanger(true)
        })
    }catch (e) {
        setLoading(false)
        setDanger(true)
    }
    }
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h2>
                {i18n.t("login.create-title")}
            </h2>
            <div className={"login-form col-12 col-lg-4"}>

            {loading &&
            <div className={"text-center"}>
                <img src={loadingImage} alt={"loading"}/>
                <p>{i18n.t("contact-us.form.placeholders.loading")}</p>
            </div>
            }

            {danger && !loading && !changed?
            <div>
                <div className={"alert alert-danger text-center"}>
                    <h3>
                        {i18n.t("login.atention")}
                    </h3>
                    <p>
                        {i18n.t("login.create-error-1")}
                    <br/>
                        {i18n.t("login.create-error-2")}
                    
                </p>
                </div>
                <div className="submit-botton text-center">
                    <input className="btn btn-outline-primary g-action-button rounded-pill"
                           type="button"
                           onClick={() => handleNavigate("/usuario/olvido-clave")}
                           value={i18n.t("login.create-button")}/>
                </div>
            </div>
                :
                null
                }

                {noUser && !loading && !changed?
                            <div>
                                <div className={"alert alert-danger text-center"}>
                                    <h3>
                                        {i18n.t("login.atention")}
                                    </h3>
                                    <p>
                                        {i18n.t("login.create-error-3")}
                                    <br/>
                                        {i18n.t("login.create-error-4")}
                                </p>
                                </div>
                                <div className="submit-botton text-center">
                                    <input className="btn btn-outline-primary g-action-button rounded-pill"
                                        type="button"
                                        onClick={() => handleNavigate("/usuario/olvido-clave")}
                                        value={i18n.t("login.create-button")}/>
                                </div>
                            </div>
                                :
                                null
                                }

                {conectionError && !loading && !changed?
                 <div>
                 <div className={"alert alert-danger text-center"}>
                     <h3>
                        {i18n.t("login.create-title-2")}
                     </h3>
                     <p>
                     {i18n.t("login.create-error-5")}
                     <br/>
                     {i18n.t("login.create-error-6")}<br/>
                     {i18n.t("login.create-error-7")}<br/>
                     {i18n.t("login.create-error-8")}<br/>
                   </p>
                 </div>
                 <div className="submit-botton text-center">
                     <input className="btn btn-outline-primary g-action-button rounded-pill"
                            type="button"
                            onClick={() => handleNavigate("/usuario/olvido-clave")}
                            value={i18n.t("login.create-button")}/>
                 </div>
             </div>
                :
                null
                }


                {!loading && !danger && !conectionError && !changed && !noUser?
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset className="col-12">
                            <label className="label-element">
                                {i18n.t("login.label2")}
                            </label>
                            <input
                                name="password"
                                type="password"
                                placeholder= {i18n.t("login.pass-placeholder")}
                                {...register('password')}
                                className={errors.password?"input-info error":"input-info success"}
                            />
                            <div className={"error-message text-danger"}>
                                {errors.password?.message}
                            </div>
                        </fieldset>


                        <fieldset className="col-12">
                            <label className="label-element">
                                {i18n.t("login.confirm")}
                            </label>
                            <input
                                name="passwordConfirm"
                                type="password"
                                placeholder={i18n.t("login.confirm-placeholder")}
                                {...register('passwordConfirm')}
                                className={errors.passwordConfirm?"input-info error":"input-info success"}
                            />
                            <div className={"error-message text-danger"}>
                                {errors.passwordConfirm?.message}
                            </div>
                        </fieldset>

                        <div className="col-12 submit-botton">
                            <input className="btn btn-outline-primary g-action-button rounded-pill"
                                   type="submit"
                                   value="ENVIAR"/>
                        </div>
                    </form>
                :
                null
                }

                {changed &&
                <div>

                    <div className={"alert alert-success text-center"}>
                        <h3>
                            {i18n.t("login.succes-title")}
                        </h3>
                        <p> {i18n.t("login.succes-message-1")}
                        <br/>
                        {i18n.t("login.succes-message-2")}
                    

                    </p>
                    </div>
                    <div className="submit-botton text-center">
                        <input className="btn btn-outline-primary g-action-button rounded-pill"
                               type="button"
                               onClick={() => handleNavigate("/usuario/login")}
                               value="INICIAR SESIÓN"/>
                    </div>
                </div>
                }


            </div>
        </>

    )

}