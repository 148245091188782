import React from 'react'
import sergio from '../../assets/images/testimonials/1.webp'
import erik from '../../assets/images/testimonials/2.webp'
import leo from '../../assets/images/testimonials/3.webp'
import mica from '../../assets/images/testimonials/4.webp'
import i18n from '../../i18n'

export default function CarouselDesktop(props) {
    const { testimonial } = props

    return (
        <div
            id="testimonialsCarouselDesktop"
            className="carousel slide d-none d-md-block"
            data-bs-ride="carousel"
        >
            {/* Carrousel visible en mobile. Oculta en md */}
            {/* Carrousel visible a partir de md*/}
            <div className="carousel-inner pb-3 pt-2">
                <div
                    className={
                        testimonial === 'all'
                            ? 'carousel-item active'
                            : testimonial === 'seller'
                            ? 'carousel-item active'
                            : 'd-none'
                    }
                >
                    <div className="container">
                        <div className="row">
                            {/* Carrousel item cards*/}
                            <div className="col-sm">
                                <div className="card shadow">
                                    <div
                                        className={`card-body text-start pt-4 px-1`}
                                    >
                                        <h5 className="card-title col-12 mb-3 mt-1 px-1">
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span
                                                style={{ fontStyle: 'italic' }}
                                            >
                                                {i18n.t(
                                                    'homepage.testimonials.testimonial1',
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;<b style={{ textDecorationLine: 'underline' }}>Sergio Silva</b>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="card shadow">
                                    <div
                                        className={`card-body text-start pt-4 px-1`}
                                    >
                                        <h5 className="card-title col-12 mb-3 mt-1 px-1">
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span
                                                style={{ fontStyle: 'italic' }}
                                            >
                                                {i18n.t(
                                                    'homepage.testimonials.testimonial3',
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;
                                                <b style={{ textDecorationLine: 'underline' }}>Leopoldo Vallenilla</b>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="card shadow">
                                    <div
                                        className={`card-body text-start pt-4 px-1`}
                                    >
                                        <h5 className="card-title col-12 mb-3 mt-1 px-1">
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span
                                                style={{ fontStyle: 'italic' }}
                                            >
                                                {i18n.t(
                                                    'homepage.testimonials.testimonial5',
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;
                                                <b style={{ textDecorationLine: 'underline' }}>Maria José Perandones</b>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        testimonial === 'all'
                            ? 'carousel-item'
                            : testimonial === 'buyer'
                            ? 'carousel-item active'
                            : 'd-none'
                    }
                >
                    <div className="container">
                        <div className="row">
                            {/* Carrousel item cards*/}
                            <div className="col-sm">
                                <div className="card shadow">
                                    <div
                                        className={`card-body text-start pt-4 px-1`}
                                    >
                                        <h5 className="card-title col-12 mb-3 mt-1 px-1">
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span
                                                style={{ fontStyle: 'italic' }}
                                            >
                                                {i18n.t(
                                                    'homepage.testimonials.testimonial2',
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;
                                                <b style={{ textDecorationLine: 'underline' }}>Erik Untiveros</b>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="card shadow">
                                    <div
                                        className={`card-body text-start pt-4 px-1`}
                                    >
                                        <h5 className="card-title col-12 mb-3 mt-1 px-1">
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span
                                                style={{ fontStyle: 'italic' }}
                                            >
                                                {i18n.t(
                                                    'homepage.testimonials.testimonial4',
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;<b style={{ textDecorationLine: 'underline' }}>Micaela Doy</b>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="card shadow">
                                    <div
                                        className={`card-body text-start pt-4 px-1`}
                                    >
                                        <h5 className="card-title col-12 mb-3 mt-1 px-1">
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span
                                                style={{ fontStyle: 'italic' }}
                                            >
                                                {i18n.t(
                                                    'homepage.testimonials.testimonial6',
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#357fd8',
                                                }}
                                            >
                                                "
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;<b style={{ textDecorationLine: 'underline' }}>Daniela Vaccarezza</b>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Carrousel controls*/}
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#testimonialsCarouselDesktop"
                data-bs-slide="prev"
            >
                <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#testimonialsCarouselDesktop"
                data-bs-slide="next"
            >
                <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
