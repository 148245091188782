import back from "../../../assets/icons/back.svg";
import React from "react";
import { useNavigate  } from "react-router-dom";
import i18n from '../../../i18n'

export default function Breadcrums(props){

    const {business, language} = (props) = props;

    let navigate = useNavigate();

    return(
        <div className={"breadcrumbs p-3"}>

            <div className={"desktop d-none d-md-block"}>
                <p>
                    <span className={"link"} onClick={() => navigate("/")}>{i18n.t("business-detail.detail.home")} </span>
                    <span className={"arrow"}> > </span>
                    <span  className={"link"} onClick={() => navigate("/negocios-disponibles")}>{i18n.t("business-detail.detail.available")}</span>
                    <span className={"arrow"}> > </span> {language==="es"?business.business_type.name_es:business.business_type.name_pt} de {language==="es"?business.business_category.name_es:business.business_category.name_pt}
                </p>
            </div>

            <div className={"back d-flex d-md-none"}>
                <button onClick={() => navigate(-1)}>
                         <span>
                            <object data={back} alt="back image svg"/>
                          </span>
                          {i18n.t("business-detail.detail.back")}
                </button>
            </div>


        </div>
    )
}