import React from "react";
import {Link} from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import i18n from "../../i18n";
import {ENV_URL} from '../../app/constants'





export default function BlogCard(props) {
    const {blog} = props;

    const backgoundImage = ENV_URL + blog.storage_path + blog.preview_image;
    const backgroundStyle1 = {
        backgroundImage: `url(${backgoundImage})`,
    }

    return(
        <div className="card shadow mt-4 mt-md-0">
            <Link to={"/"+blog.type+"/"+blog.id}>
                <div
                    className="card-img-top img-fluid"
                    style={backgroundStyle1}
                ></div>
                <div className="card-body">
                    <div>
                    {blog.logo_image &&
                    <div className={"pb-2"}>
                        <img className={"logo-image"} src={ENV_URL + blog.storage_path + blog.logo_image}/>
                    </div>
                    }
                    <h5 className="card-title">
                        {blog.title}
                    </h5>
                    <p className="card-date">{blog.date}</p>
                    <p className="card-text">
                        {blog.subtitle}

                    </p>
                    </div>
                </div>
            </Link>
        </div>
    )

}