import React, {useEffect, useRef, useState} from "react";
import './blog.css';
import Banner from "./blog.banner";
import BlogSearch from "./blog.searchbar";
import FeaturedCarrouselDesktop from "./blog.featuredCarrouselDesktop";
import CarouselMobileRB from "../latestNews.carouselMobileRB";
import BlogList from "./blog.blogList";
import BlogFilters from "./blog.filters";
import i18n from "../../i18n";
import loadingImage from '../../assets/icons/loading.gif'
import {ENV_URL} from "../../app/constants";
import BlogsFeaturedFetch from "../api/blogs.featured.fetch";
import axios from "axios";
import { csrf } from '../api/csrfValidation'
import {useLocation} from "react-router";

export default function Blog(props){

    const resultRef = useRef();
    const {language, type} = props;
    const [blogs, setBlogs] = useState(null)
    const [featuredBlogs, setFeaturedBlogs] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({filter:{type:type, language:language, date:'desc'}})
    const location = useLocation();
    const [filterAplly, setFilterApply] =useState(false)

   const scroll = () =>{
        if(blogs && featuredBlogs){
        resultRef.current.scrollIntoView();
        window.scrollBy(0, -180)
        }
    }
    const setFilteredBlogs = async () => {
        scroll()
         setLoading(true)
        await csrf()
        axios({
            method: 'post',
            url: `${ENV_URL}/api/V1/blog/indexBy/filters?page=`+currentPage,
            data: filters.filter,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            if(response.status==200){
                setBlogs(response.data)
                scroll()
            }

        }).catch(function (response) {
            setLoading(false)
        })
    }

    useEffect(() => {
        if(blogs && featuredBlogs){
            setLoading(false)
        }
    }, [blogs, featuredBlogs])

    useEffect(() => {
        if(filterAplly){
            console.log(filters)
            setFilteredBlogs()
            setFilterApply(false)
        }
    }, [filterAplly])

    useEffect(() => {
       setFilteredBlogs()
             }, [currentPage, language, location])

    return(
        <section className="blog">
            <BlogsFeaturedFetch
                setFeaturedBlogs={setFeaturedBlogs}
                language={language}
            />
            <div className="blog-section-content">

                <Banner language={language} type={type}/>

                <BlogSearch setFilters={setFilters}
                            loading={loading}
                />

                {loading? (
                    <div className={'text-center pt-4 mt-4'}>
                        <img src={loadingImage} alt={'loading'} />
                        <p>{i18n.t('blog.search-bar.button-loading')}</p>
                    </div>
                ):
                null
                }


                {blogs && featuredBlogs? (
                <div className={"container mt-5 pt-5"}>

                    {type==='blog'&&<h3 className={"mt-3 mt-md-0"}>{i18n.t('blog.featured.title')}</h3>}
                    {type==='prensa'&&<h3 className={"mt-3 mt-md-0"}>{i18n.t('news.featured.title')}</h3>}


                    <div className={"carousel mb-5 mt-5"}>
                        <div className="desktop-carrousel d-none d-md-block">
                            <FeaturedCarrouselDesktop featuredBlogs={featuredBlogs} type={type}/>
                        </div>

                        <div className="mobile-carrousel d-md-none">
                            <CarouselMobileRB featuredBlogs={featuredBlogs} type={type}/>
                        </div>
                    </div>

                    <h3>{i18n.t('blog.list.title')}</h3>

                    <div className={"pt-3 pb-5 mobile-carrousel"}
                         ref={resultRef}
                    >
                        <BlogFilters type={type}
                                     language={language}
                                     loading={loading}
                                     setFilters={setFilters}
                                     setCurrentPage={setCurrentPage}
                                     setFilterApply={setFilterApply}
                        />

                        {!loading?
                        <BlogList blogs={blogs}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  data={blogs.data}
                                  language={language}
                        />
                        :
                        <div className={'text-center pt-4 mt-4'}>
                            <img src={loadingImage} alt={'loading'} />
                            <p>{i18n.t('blog.search-bar.button-loading')}</p>
                        </div>
                        }

                    </div>
                </div>
                ):
                null
                }

            </div>
        </section>
    )
}