import category from "../../../assets/icons/category.png";
import dolar from "../../../assets/icons/dolar.png";
import age from "../../../assets/icons/age.png";
import stats from "../../../assets/icons/stats.png";
import React from "react";
import i18n from '../../../i18n'

export default function TopInfo(props) {

    const {business, language} = (props) = props;
    return(
        <div>
           <div className={"icons row"}>
                <div className={"col-6"}>
                    <img src={category} alt={"category icon"}/>
                    <h6>
                        {i18n.t("business-detail.detail.category")}
                    </h6>
                    <p>{language==="es"?business.business_category.name_es:business.business_category.name_pt}</p>
                </div>

                <div className={"col-6"}>
                    <img src={dolar} alt={"dolar icon"}/>
                    <h6>
                        {i18n.t("business-detail.detail.price")}
                    </h6>
                        <p>${business.algorithm_local_price.toLocaleString('de-DE')}</p>
                </div>

                <div className={"col-6"}>
                    <img src={age} alt={"age icon"}/>
                    <h6>
                        {i18n.t("business-detail.detail.age")}
                    </h6>
                    <p>{business.business_age_stat} años</p>
                </div>

                <div className={"col-6"}>
                    <img src={stats} alt={"stats icon"}/>
                    <h6>
                         {i18n.t("business-detail.detail.profit")}
                    </h6>
                    <p>$ {business.average_gross_profit_stat.toLocaleString('de-DE')}</p>
                </div>

            </div>
        </div>
    )

}