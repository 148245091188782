import i18n from '../../i18n.js'
import React from 'react'
import { Link } from 'react-router-dom'
import PriceCard from './payment.priceCard.js'

export default function PaymentPrices() {
    const plan = i18n.t('payment.plan')
    const month = i18n.t('payment.month')
    const months = i18n.t('payment.months')
    const title = i18n.t('payment.card-title')
    const price = i18n.t('payment.price')

    return (
        <div className={'container'}>
            <div className="prices shadow mb-2 p-2">
                <div className="table-responsive">
                    <table className="table table-bordered border-dark text-center align-middle">
                        <thead className="">
                            <tr className="border border-bottom border-white rowTitleRadius">
                                <th
                                    rowSpan={2}
                                    className="border border-0"
                                ></th>
                                <th
                                    scope="col"
                                    colspan="3"
                                    className="border border-0 headerTitle"
                                >
                                    {i18n.t('payment.tableSeller.row1')}
                                </th>
                            </tr>
                            <tr className="border border-0 text-white">
                                <th
                                    scope="col"
                                    className="border border-start-0 headerSubtitle"
                                >
                                    {i18n.t('payment.tableSeller.row2.col2')}
                                </th>
                                <th
                                    scope="col"
                                    className="border border-start-0 align-middle headerSubtitle"
                                >
                                    {i18n.t('payment.tableSeller.row2.col3')}
                                </th>
                                <th
                                    scope="col"
                                    className="border border-start-0 headerSubtitle"
                                >
                                    {i18n.t('payment.tableSeller.row2.col4')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <b>
                                        {i18n.t(
                                            'payment.tableSeller.row3.col1',
                                        )}
                                    </b>
                                </td>
                                <td>
                                    <table className="table">
                                        <tr>
                                            <th>
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col2.subrow1.subcol1',
                                                )}
                                            </th>
                                            <th className="border border-top-0 border-dark">
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col2.subrow1.subcol2',
                                                )}
                                            </th>
                                            <th>
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col2.subrow1.subcol3',
                                                )}
                                            </th>
                                        </tr>
                                        <tr className="border-dark border-top align-middle">
                                            <td colspan="3">
                                                <b>
                                                    {i18n.t(
                                                        'payment.tableSeller.row3.col2.subrow2',
                                                    )}
                                                </b>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table className="table">
                                        <tr>
                                            <th>
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col3.subrow1.subcol1',
                                                )}
                                            </th>
                                            <th className="border border-top-0 border-dark">
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col3.subrow1.subcol2',
                                                )}
                                            </th>
                                            <th>
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col3.subrow1.subcol3',
                                                )}
                                            </th>
                                        </tr>
                                        <tr className="border-dark border-top align-middle">
                                            <td colspan="3">
                                                <b>
                                                    {i18n.t(
                                                        'payment.tableSeller.row3.col3.subrow2',
                                                    )}
                                                </b>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table className="table">
                                        <tr>
                                            <th>
                                                {i18n.t(
                                                    'payment.tableSeller.row3.col4.subrow1',
                                                )}
                                                <br></br>&nbsp;&nbsp;
                                            </th>
                                        </tr>
                                        <tr className="border-dark border-top align-middle">
                                            <td>
                                                <b>
                                                    {i18n.t(
                                                        'payment.tableSeller.row3.col4.subrow2',
                                                    )}
                                                </b>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <b>
                                        {i18n.t(
                                            'payment.tableSeller.row4.col1',
                                        )}
                                    </b>
                                </th>
                                <td>
                                    <b>
                                        {i18n.t(
                                            'payment.tableSeller.row4.col2',
                                        )}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {i18n.t(
                                            'payment.tableSeller.row4.col3',
                                        )}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {i18n.t(
                                            'payment.tableSeller.row4.col4',
                                        )}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" className="services">
                                    <b>{i18n.t('payment.tableSeller.row5')}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row6.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row7.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row8.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row9.col1')}
                                </td>
                                <td>
                                    {i18n.t('payment.tableSeller.row9.col2')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row10.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row11.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row12.col1')}
                                </td>
                                <td>
                                    {i18n.t('payment.tableSeller.row12.col2')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableSeller.row13.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="text-start tablesAclaration">
                        <b>{i18n.t('payment.tablesAclaration')}</b>
                    </p>
                </div>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered border-dark text-center align-middle">
                        <thead className="">
                            <tr className="border border-bottom border-white rowTitleRadius">
                                <th
                                    rowSpan={2}
                                    className="border border-0"
                                ></th>
                                <th
                                    scope="col"
                                    colspan="3"
                                    className="border border-0 headerTitle"
                                >
                                    {i18n.t('payment.tableBuyer.row1')}
                                </th>
                            </tr>
                            <tr className="border border-0 text-white">
                                <th
                                    scope="col"
                                    className="border border-start-0 headerSubtitle"
                                >
                                    {i18n.t('payment.tableBuyer.row2.col2')}
                                </th>
                                <th
                                    scope="col"
                                    className="border border-start-0 col-3 align-middle headerSubtitle"
                                >
                                    {i18n.t('payment.tableBuyer.row2.col3')}
                                </th>
                                <th
                                    scope="col"
                                    className="border border-start-0 headerSubtitle"
                                >
                                    {i18n.t('payment.tableBuyer.row2.col4')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    <b>
                                        {i18n.t('payment.tableBuyer.row3.col1')}
                                    </b>
                                </th>
                                <td>
                                    <b>
                                        {i18n.t('payment.tableBuyer.row3.col2')}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {i18n.t('payment.tableBuyer.row3.col3')}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {i18n.t('payment.tableBuyer.row3.col4')}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" className="services">
                                    <b>{i18n.t('payment.tableBuyer.row4')}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row5.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row6.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    {i18n.t('payment.tableBuyer.row6.col4')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row7.col1')}
                                </td>
                                <td>
                                    {i18n.t('payment.tableBuyer.row7.col2')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row8.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row9.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    {i18n.t('payment.tableBuyer.row9.col4')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row10.col1')}
                                </td>
                                <td>
                                    {i18n.t('payment.tableBuyer.row10.col2')}
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {i18n.t('payment.tableBuyer.row11.col1')}
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                                <td>
                                    <i className="bi bi-x-lg icon-cross"></i>
                                </td>
                                <td>
                                    <i className="bi bi-check-lg icon-check"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="text-start tablesAclaration">
                        <b>{i18n.t('payment.tablesAclaration')}</b>
                    </p>
                </div>
                <div>
                    <a
                        href={
                            'https://paypal.me/SitioDeTiendas?country.x=AR&locale.x=en_US'
                        }
                        target={'_blank'}
                        className="fix-button-link btn btn-outline-primary g-action-button rounded-pill"
                    >
                        PAGAR
                    </a>
                </div>
            </div>
        </div>
    )
}
