import './howTo.scss'
import Find from '../assets/images/find-card.png'
import Contact from '../assets/images/contact-card.png'
import Deal from '../assets/images/deal-card.png'
import HowToCard from './howToCard'
import {Link} from "react-router-dom";
import i18n from '../i18n.js'

export default function HowToBuy({isHome=false}) {
    return (
        <div className="htb" id="howToBuy">
            <div className="container pt-2 pt-xl-3 pt-lg-3 pb-3">
                <div className="row pt-3 pb-3 section-title">
                    <div className="d-flex col-12 justify-content-start justify-content-md-center text-left text-md-center">
                        <h6>{i18n.t('homepage.how-to-buy.header-text')}</h6>
                    </div>
                    <div className="d-flex col-12 justify-content-start justify-content-md-center text-left text-md-center">
                        { isHome ?
                            <h2>
                                {i18n.t('homepage.how-to-buy.title')}
                            </h2>
                            :
                            <h1>
                                {i18n.t('homepage.how-to-buy.title')}
                            </h1>
                        }
                    </div>
                    <div className="d-flex col-12 justify-content-start justify-content-md-center mt-4">
                        <div className="d-flex col-8 text-left text-md-center">
                            { isHome ?
                                <p>
                                    {i18n.t('homepage.how-to-buy.text')}
                                </p>
                                :
                                <h2 className='htb_page_subtitle'>
                                    {i18n.t('homepage.how-to-buy.text')}
                                </h2>
                            }
                        </div>
                    </div>
                </div>
                <div className="row pt-0 pt-md-5 pb-0 px-xl-5 mx-xl-5 px-lg-5 mx-lg-5">
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-5">
                        <HowToCard
                            image={Find}
                            number={'01'}
                            text={i18n.t('homepage.how-to-buy.cards.1')} />
                    </div>
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-5">
                        <HowToCard
                            image={Contact}
                            number={'02'}
                            text={i18n.t('homepage.how-to-buy.cards.2')}
                        />
                    </div>
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-5">
                        <HowToCard
                            image={Deal} number={'03'}
                            text={i18n.t('homepage.how-to-buy.cards.3')} />
                    </div>
                </div>
                <div className="row justify-content-center align-items-center px-md-5 mx-md-5">
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 justify-content-center align-items-center mx-0">


                        <Link to="/negocios-disponibles"
                            type="button"
                            className="fix-button-link btn w-100 btn-primary rounded-pill g-action-button"
                        >
                            <span> {i18n.t('homepage.how-to-buy.button1')}</span>
                        </Link>

                    </div>
                    <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 justify-content-center mx-0">


                        <Link to="/preguntas-frecuentes/comprador"
                            type="button"
                            className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                        >
                            <span> {i18n.t('homepage.how-to-buy.button2')}</span>
                               </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}
