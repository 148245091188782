import React from "react";

export default function CheckboxInput(props) {

    const{label, inputName, err, isRequired,message , register, value} = (props) = props;

    return(
        <>
            <input type="checkbox"
                   defaultChecked={value}
                   name={inputName}
                   {...register(inputName, {required: isRequired})}
                  />
            <label className="label-check">{label}</label>
            <div className={"error-message text-danger"}>
                {err?.type === 'required' && message}
            </div>
            </>
    )

}

