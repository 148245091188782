import Carousel from "react-bootstrap/Carousel";
import React from "react";
import Logo1 from '../../assets/images/sponsors/ambito.png'
import Logo2 from '../../assets/images/sponsors/emol.png'
import Logo3 from '../../assets/images/sponsors/financiero.png'
import Logo4 from '../../assets/images/sponsors/forbes.png'
import Logo5 from '../../assets/images/sponsors/lanacion.png'
import LogoHome1 from '../../assets/images/sponsors/cevec.webp'
import LogoHome2 from '../../assets/images/sponsors/ecommerce_inst.webp'
import LogoHome3 from '../../assets/images/sponsors/emblue.png'
import LogoHome4 from '../../assets/images/sponsors/goodbox.webp'
import LogoHome5 from '../../assets/images/sponsors/startup.png'

export default function LogosCarouselDesktop({ fromHome=false }){
    const redirectToUrl = (event) => {
        event.preventDefault();
        if (event.target.dataset.url !== "") {
            let anchor = document.createElement('a');
            anchor.href = event.target.dataset.url;
            anchor.target="_blank";
            anchor.click();
        }
    }

    return(
        <div className="logo-carousel container">
        <Carousel>
            <Carousel.Item interval={3000}>

               <div className="row">
                   <div className="col-3">
                       { fromHome ? 
                            <img src={LogoHome5} alt="logoHome5" data-url="https://startupchile.org/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                            :
                            <img src={Logo1} alt="logo1" data-url="" onClick={(e) => redirectToUrl(e)}/>
                       }
                   </div>
                   <div className="col-3">
                   { fromHome ? 
                       <img src={LogoHome2} alt="logoHome2" data-url="https://ecommerce.institute/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                       :
                       <img src={Logo2} alt="logo2" data-url="" onClick={(e) => redirectToUrl(e)}/>
                   }
                   </div>
                   <div className="col-3">
                   { fromHome ? 
                       <img src={LogoHome3} alt="logoHome3" data-url="https://www.embluemail.com/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                       :
                       <img src={Logo3} alt="logo3" data-url="" onClick={(e) => redirectToUrl(e)}/>
                   }
                   </div>
                   <div className="col-3">
                   { fromHome ? 
                       <img src={LogoHome1} alt="logoHome1" data-url="https://cevec.com.ar/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                       :
                       <img src={Logo4} alt="logo4" data-url="" onClick={(e) => redirectToUrl(e)}/>
                   }
                   </div>
               </div>


            </Carousel.Item>
            <Carousel.Item interval={3000}>

                <div className="row">
                    <div className="col-3">
                    { fromHome ? 
                       <img src={LogoHome4} alt="logoHome4" data-url="https://goodbox.cl/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                       :
                       <img src={Logo5} alt="logo5" data-url="" onClick={(e) => redirectToUrl(e)}/>
                    }
                    </div>
                    <div className="col-3">
                    { fromHome ? 
                        <img src={LogoHome5} alt="logoHome5" data-url="https://startupchile.org/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                        :
                        <img src={Logo1} alt="logo1" data-url="" onClick={(e) => redirectToUrl(e)}/>
                    }
                   </div>
                   <div className="col-3">
                   { fromHome ? 
                       <img src={LogoHome2} alt="logoHome2" data-url="https://ecommerce.institute/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                       :
                       <img src={Logo2} alt="logo2" data-url="" onClick={(e) => redirectToUrl(e)}/>
                   }
                   </div>
                   <div className="col-3">
                   { fromHome ? 
                       <img src={LogoHome3} alt="logoHome3" data-url="https://www.embluemail.com/?utm_source=Sitio&utm_id=deTiendas" onClick={(e) => redirectToUrl(e)}/>
                       :
                       <img src={Logo3} alt="logo3" data-url="" onClick={(e) => redirectToUrl(e)}/>
                   }
                   </div>
                </div>


            </Carousel.Item>

        </Carousel>
        </div>
    )
}