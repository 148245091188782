import React from "react";
import HombreLaptop from '../../assets/images/hombre_laptop.png'
import Saludo from '../../assets/images/saludo.png'
import HombreLaptopMBB from '../../assets/images/hombre_laptop_mobile.png'
import SaludoMB from '../../assets/images/saludo_mobile.png'
import i18n from '../../i18n';

export default function AboutUsMision() {
    return(
        <div className="mision">
            <div className="row">
                <div className="image col-12 col-md-5 justify-content-center">
                    <img className="img-fluid d-none d-md-block" src={HombreLaptop} alt="About us hombre laptop" />
                    <img className="img-fluid d-block d-md-none" src={HombreLaptopMBB} alt="About us hombre laptop" />
                </div>

                <div className="col-12 col-md-7 text-content">
                    <h6>{i18n.t('about-us.mision.header-text1')}</h6>
                    <h2>
                        <span>{i18n.t('about-us.mision.title1')}</span>
                    </h2>

                    <p>
                    {i18n.t('about-us.mision.text1')}
                    </p>
                </div>

                <div className="col-12 col-md-7 text-content order-2 order-md-1">
                    <h6>{i18n.t('about-us.mision.header-text2')}</h6>
                    <h2>
                        <span>{i18n.t('about-us.mision.title2')}</span>
                    </h2>

                    <p>
                    {i18n.t('about-us.mision.text2')}
                    </p>
                </div>

                <div className="image col-12 col-md-5 justify-content-center order-1 order-md-2">
                       <img className="img-fluid d-none d-md-block" src={Saludo} alt="About us saludo" />
                    <img className="img-fluid d-block d-md-none" src={SaludoMB} alt="About us hombre laptop" />

                </div>

                </div>
        </div>
    )

}