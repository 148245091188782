import React from "react";
import i18n from '../../../i18n.js'

export default function FormStatus(props){
    const {sendState, type} = props;

    return(
        <div className={"status-alert"}>
            {sendState === 1 &&
                <p className={"text-center alert alert-warning"}>{i18n.t('quotation.status.alert1')}</p>

            }

            {sendState === 0 &&
                <p className={"text-center alert alert-danger"}>{i18n.t('quotation.status.alert2')}</p>

            }

            {sendState === 2 &&
                <p className={"text-center alert alert-success"}>
                    {type==='user' || type==='domain' ?
                        i18n.t('quotation.status.alert3')
                        :
                        i18n.t('quotation.status.alert5')
                    }
                </p>
                            }

            {sendState === 422 &&
                <p className={"text-center alert alert-danger"}>
                    {type === 'user' &&
                        <span>{i18n.t('quotation.status.message-user')}
                        <br/>{i18n.t('quotation.status.alert4')}
                        </span>
                    }
                    {type === 'domain' &&
                    <span>{i18n.t('quotation.status.message-business')}
                         <br/>{i18n.t('quotation.status.alert4')}
                        </span>
                    }
                    {type === 'dashboard' &&
                    <span>{i18n.t('quotation.status.message-business')}
                       </span>
                    }

                </p>

            }
        </div>
    )
}