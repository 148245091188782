import React from "react";
import i18n from "../../i18n";
import instructions1 from "../../assets/images/instructions1.webp"
import instructions2 from "../../assets/images/instructions2.png"
import instructions3 from "../../assets/images/instructions3.webp"
import instructions4 from "../../assets/images/instructions4.png"


export default function PaymentInstructions() {
    return(
        <div className={"container"}>
        <div className="row pt-5 pb-5 section-title">
            <div className="d-flex col-12 justify-content-start justify-content-md-center text-left text-md-center">
                <h1>
                    {i18n.t("payment.instructions-text")}
                </h1>
            </div>

            <div className={"instructions-cards"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-3  pt-3 pt-md-1"}>
                        <div className="card shadow">
                            <div>
                                <h2 className="htb-card-number">01</h2>
                            </div>
                            <div className="card-body">

                                <p className={"card-title"}>
                                {i18n.t("payment.instructions-title-1")}
                                    </p>
                                <p>
                                {i18n.t("payment.instructions-text-1")}
                                </p>

                                <img className={"w-100 img-fluid"} src={instructions1} alt={instructions1} />


                            </div>
                        </div>
                        <div className={"gradient-bottom shadow mb-4 col-12"}>
                        </div>

                    </div>

                    <div className={"col-12 col-md-3  pt-3 pt-md-1"}>
                        <div className="card shadow">

                            <div>
                                <h2 className="htb-card-number">02</h2>
                            </div>
                            <div className="card-body">
                                <p className="card-title">
                                {i18n.t("payment.instructions-title-2")}
                                </p>
                                <p>
                                {i18n.t("payment.instructions-text-2")}
                                </p>

                                <img className={"w-100 img-fluid"} src={instructions2} alt={instructions2} />

                            </div>
                        </div>
                        <div className={"gradient-bottom shadow mb-4 col-12"}>
                        </div>

                    </div>

                    <div className={"col-12 col-md-3  pt-3 pt-md-1"}>
                        <div className="card shadow">
                            <div>
                                <h2 className="htb-card-number">03</h2>
                            </div>
                            <div className="card-body">
                                <p className="card-title">
                                {i18n.t("payment.instructions-title-3")}
                                </p>
                                <p>
                                {i18n.t("payment.instructions-text-3")}
                                </p>
                                <img className={"w-100 img-fluid"} src={instructions3} alt={instructions3} />



                            </div>

                        </div>
                        <div className={"gradient-bottom shadow mb-4 col-12"}>
                        </div>
                    </div>

                    <div className={"col-12 col-md-3  pt-3 pt-md-1"}>
                        <div className="card shadow">
                            <div>
                                <h2 className="htb-card-number">04</h2>
                            </div>
                            <div className="card-body">
                                <p className="card-title">
                                {i18n.t("payment.instructions-title-4")}
                                </p>
                                <p>
                                {i18n.t("payment.instructions-text-4")}
                                </p>
                                <img className={"w-100 img-fluid"} src={instructions4} alt={instructions4} />

                            </div>

                        </div>
                        <div className={"gradient-bottom shadow mb-4 col-12"}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )

}