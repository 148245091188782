import './contactUs.css'
import ContactUsImage from "./contactUs.image";
import ContactUsInfo from "./contactUs.contactInfo";
import ContactUsForm from "./contactUs.form";
import ContactUsBanner from "./contactUs.banner";


export default function ContactUs(props){
    const {language} = props;

    return(
        <section className="contactus">
            <div className="contactus-section-content">

                <ContactUsBanner />

                <div className="contact-bottom container pt-2 pt-md-5">
                    <div className="row">
                        <div className="col-12 col-md-5">

                            <ContactUsInfo language={language}/>

                            <div className="d-none d-md-block">
                                <ContactUsImage />
                            </div>

                        </div>

                        <div className="col-12 col-md-7 pt-4">

                            <ContactUsForm language={language}/>

                            <div className="d-block d-md-none">
                                <ContactUsImage />
                            </div>

                        </div>
                    </div>

                </div>
           <div className="back-effect">

           </div>
            </div>
        </section>
    )
}