import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import i18n from "../i18n";
import backgroundImage1 from '../assets/images/news/news-1.jpg'
import backgroundImage2 from '../assets/images/news/news-2.jpg'
import backgroundImage3 from '../assets/images/news/news-3.jpg'
import BlogCard from "./blog/blog.blogCard";

const backgroundStyle1 = {
    backgroundImage: `url(${backgroundImage1})`,
}

const backgroundStyle2 = {
    backgroundImage: `url(${backgroundImage2})`,
}

const backgroundStyle3 = {
    backgroundImage: `url(${backgroundImage3})`,
}

export default function CarouselMobileRB(props) {
    const {featuredBlogs, type} = props;

    let featured = featuredBlogs;
    if(type){
        featured =
            featuredBlogs.filter(blog => {
                return blog.type === type;
            });
    }

    let forBlogs = !featured.length>0?
        <h3 className={"alert alert-warning"}>{i18n.t('blog.list.message')}</h3>
        :
        featured.map((blog, index) =>(
                <Carousel.Item interval={3000} key={index}>
                    <BlogCard blog={blog} />

                </Carousel.Item>
        )
        );
    return (

            <Carousel>
                {forBlogs}
            </Carousel>

    );
}
