import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import erik from '../../assets/images/testimonials/2.webp'
import leo from '../../assets/images/testimonials/3.webp'
import mica from '../../assets/images/testimonials/4.webp'
import sergio from '../../assets/images/testimonials/1.webp'
import TestimonialCard from './testimonials.card'
import i18n from '../../i18n'

export default function CarouselMobile(props) {
    const { testimonial } = props

    return (
        <Carousel>
            {testimonial === 'all' || testimonial === 'seller' ? (
                <Carousel.Item interval={2000}>
                    <TestimonialCard
                        /*image={sergio}*/
                        user={'Sergio Silva'}
                        testimonial={i18n.t(
                            'homepage.testimonials.testimonial1',
                        )}
                        description={i18n.t('homepage.testimonials.user1')}
                    />
                </Carousel.Item>
            ) : null}

            {testimonial === 'all' || testimonial === 'seller' ? (
                <Carousel.Item interval={2000}>
                    <TestimonialCard
                        /*image={leo}*/
                        user={'Leopoldo Vallenilla'}
                        testimonial={i18n.t(
                            'homepage.testimonials.testimonial3',
                        )}
                        description={i18n.t('homepage.testimonials.user3')}
                    />
                </Carousel.Item>
            ) : null}

            {testimonial === 'all' || testimonial === 'seller' ? (
                <Carousel.Item interval={2000}>
                    <TestimonialCard
                        /*image={leo}*/
                        user={'Maria José Perandones'}
                        testimonial={i18n.t(
                            'homepage.testimonials.testimonial5',
                        )}
                        description={i18n.t('homepage.testimonials.user5')}
                    />
                </Carousel.Item>
            ) : null}

            {testimonial === 'all' || testimonial === 'buyer' ? (
                <Carousel.Item interval={2000}>
                    <TestimonialCard
                        /*image={erik}*/
                        user={'Erik Untiveros'}
                        testimonial={i18n.t(
                            'homepage.testimonials.testimonial2',
                        )}
                        description={i18n.t('homepage.testimonials.user2')}
                    />
                </Carousel.Item>
            ) : null}

            {testimonial === 'all' || testimonial === 'buyer' ? (
                <Carousel.Item interval={2000}>
                    <TestimonialCard
                        /*image={mica}*/
                        user={'Micaela Doy'}
                        testimonial={i18n.t(
                            'homepage.testimonials.testimonial4',
                        )}
                        description={i18n.t('homepage.testimonials.user4')}
                    />
                </Carousel.Item>
            ) : null}

            {testimonial === 'all' || testimonial === 'buyer' ? (
                <Carousel.Item interval={2000}>
                    <TestimonialCard
                        /*image={mica}*/
                        user={'Daniela Vaccarezza'}
                        testimonial={i18n.t(
                            'homepage.testimonials.testimonial6',
                        )}
                        description={i18n.t('homepage.testimonials.user6')}
                    />
                </Carousel.Item>
            ) : null}
        </Carousel>
    )
}
