import React, {useState} from "react";
import {isArrayEmpty} from "../../Utils";
import Accordion from "react-bootstrap/Accordion";
import ActionButtons from "./dashboard.businessActionButtons";
import BusinessTableDesktop from "./businessTableDesktop";
import BusinessState from "./dashboard.businessUserState";
import i18n from "../../../i18n";

export default function BusinessAccordionMobile(props) {

    const {businesses, handleEdit, handleDelete, handlePayment, deleting} = (props) = props

    let forBusinesses = isArrayEmpty(businesses) ?
        []
        :
        businesses.map((business, index) =>
            (
                <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                        {!business.domain_url==""?business.domain_url:<span className={"text-danger"}> {i18n.t("dashboard.incomplete")}</span>}
                    </Accordion.Header>
                    <Accordion.Body>
                       <div className={"info"}>
                           <span>
                               <span className={"fw-bold"}> {i18n.t("dashboard.value")}: </span> ${ business.algorithm_price_usd.toLocaleString('de-DE')}
                           </span>
                           <br/>
                           <span>
                               <span className={"fw-bold"}> {i18n.t("dashboard.state")}: </span>
                                <BusinessState
                                    business={business}
                                />
                          </span>
                       </div>

                        <div className={"actions pt-2 d-flex justify-content-around"}>
                            <ActionButtons
                                business={business}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handlePayment={handlePayment}
                                deleting={deleting}
                            />

                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            )
        );

        return(
            <div className="accordion-businesses">
            <Accordion>
                {forBusinesses}
            </Accordion>
            </div>
            )

}