import React from "react";
import {Link} from "react-router-dom";
import ScrollToTop from "../scrollToTopWrapper";
import i18n from '../../i18n.js'

export default function QuotationSuccess(props) {

    const{subtitle, text} = (props) = props;

    return(
        <ScrollToTop>
            <div className={"text-center"}>

                <h4>
                    {subtitle}
                </h4>

                <p>
                    {text}
                </p>

            </div>

            <div className="row justify-content-center align-items-center px-md-5 mx-md-5">
                <div className="d-flex col-12 col-md-6 justify-content-center align-items-center mx-0">
                    <Link to="/preguntas-frecuentes"
                          type="button"
                          className="fix-button-link btn w-100 btn-primary rounded-pill g-action-button"
                    >
                        {i18n.t('quotation.update.button1')}
                    </Link>
                </div>
                <div className="d-flex col-12 col-md-6 justify-content-center mx-0">
                    <Link to="/"
                          type="button"
                          className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                    >
                        {i18n.t('quotation.update.button2')}
                    </Link>
                </div>
            </div>

        </ScrollToTop>
    )

}