import axios from 'axios'
import { ENV_URL } from '../../app/constants'

/**
 * Endpoint to create Laravel cdrf cookie
 * @returns {Promise<AxiosResponse<T>>}
 */
const csrf = async () => {
    axios.defaults.withCredentials = true
    return axios.get(`${ENV_URL}/sanctum/csrf-cookie`)
}

export { csrf }
