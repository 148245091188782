import React from "react";
import './user.css'
import logoEs from '../../assets/logo-es.webp'
import logoPt from '../../assets/logo-pt.png'
import Login from "./login";
import {Route} from "react-router-dom";
import Register from "./register";
import CreatePassword from "./createPassword";
import ForgetPassword from "./forgetPassword";
import ForgetPasswordMessage from "./forgetPasswordMessage"
import NotFound from "../notFoundLink";
import {Routes} from "react-router";
import RegisterSucces from "./registerSuccess";
import Dashboard from "./dashboard/dashboard";
import i18n from "../../i18n";
import {Helmet} from "react-helmet"

export default function User(props){
    const {language} = props;
    const NotFoundSection = () =>{
        return(<>
            <Helmet>
                <title>{props.title}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className={"col-12 col-lg-4"}>
                <NotFound/>
            </div>
            </>
        )
    }

    return(
        <section className={"user"}>
            <div className={"user-content-section"}>
                <div className="container">

                    <div className={"logo"}>
                    <a className="navbar-brand" href="/">
                        <img src={language === "es" ? logoEs : logoPt} alt="logo" height={80} />
                    </a>
                </div>

                    <div className={"user-elements"}>
                        <Routes>
                            <Route path="login" element={<Login title={i18n.t("section-titles.login")} description={i18n.t("section-titles.login_desc")}/>} />
                            <Route path="registro" element={<Register title={i18n.t("section-titles.register")} description={i18n.t("section-titles.register_desc")}/>} />
                            <Route path="olvido-clave" element={<ForgetPassword title={i18n.t("section-titles.forgetPassword")} description={i18n.t("section-titles.forgetPassword_desc")} />} />
                            <Route path="crear-clave/:userId/:token" element={<CreatePassword  title={i18n.t("section-titles.createPassword")}/>} />
                            <Route path="registro-exitoso" element={<RegisterSucces  title={i18n.t("section-titles.registerSucces")}/>} />

                            <Route path="*" element={<NotFoundSection title={i18n.t("section-titles.notFound")}/>} />
                        </Routes>
                    </div>


                </div>
                <div className="back-effect d-block d-lg-none">

                </div>
            </div>
        </section>
    )
}