import React, {useEffect, useState} from "react";
import './blogDetail.css'
import  '../../latestNews.css'
import Breadcrums from "./blog.breadcrums";
import BlogDetailBanner from "./blogDetail.banner";
import BlogBody from "./blog.blogBody";
import CarouselMobileRB from "../../latestNews.carouselMobileRB";
import i18n from "../../../i18n";
import {useParams, useLocation} from "react-router";
import BlogsTypeFetch from "../../api/blogs.fetch";
import FeaturedCarrouselDesktop from "../blog.featuredCarrouselDesktop";
import loadingImage from "../../../assets/icons/loading.gif";
import {BlogByIdFetch} from "../../api/blogs.id.fetch";
import BlogsFeaturedFetch from "../../api/blogs.featured.fetch";
import {Helmet} from "react-helmet";

export default function BlogDetail(props){

    const {language} = props;
    const getParams = useParams();
    let {type, blogId} = getParams;
    const [featuredBlogs, setFeaturedBlogs] = useState(null)
    const [loading, setLoading] =  useState(false)
    const [blogDetail, setBlogDetail] = useState(null)
    const location = useLocation();
    const [danger, setDanger] = useState(false)

    let typeTitle
    if(type==='prensa'){
        typeTitle = i18n.t("section-titles.newsDetailSection");
    }else{
        typeTitle = i18n.t("section-titles.blogDetailSection");
    }
 
    if(blogDetail){
        //document.title=blogDetail.title+" - "+typeTitle
    }else{
        document.title = typeTitle
    }
    

    useEffect(() => {

        if (featuredBlogs && blogDetail) {
            setLoading(false)
        }
       }, [featuredBlogs, blogDetail])

       useEffect(() => {
        blogId = getParams.blogId;
        getBlogInfo()
        }, [location])

       const getBlogInfo = () => {
        setLoading(true)
        BlogByIdFetch(type, blogId).then(response => {
            if(response.status===200){
               setBlogDetail(response.data)
               setLoading(false)
            }
            if(response.status===204){
              setLoading(false)
            }
        }).catch(function (response) {
            setLoading(false)
            setDanger(true)
        })
       }


    return(

        <div>
            <BlogsFeaturedFetch
                setFeaturedBlogs={setFeaturedBlogs}
                language={language}
            />
        {loading && (
        <div className={'text-center pt-4 mt-4'}>
            <img src={loadingImage} alt={'loading'} />
            <p> {i18n.t('blog.search-bar.button-loading')}</p>
        </div>
    )}

    {!loading && (
        <div className={"blog-detail container"}>

            {danger &&
              <div className={"alert alert-warning mt-4 text-center"}>
                 {i18n.t('blog-detail.error')}
              </div>
            }

            {blogDetail&&
                    <>
                        <Helmet>
                            <title>{typeTitle + blogDetail.title}</title>
                            <meta name="description" content={blogDetail.subtitle + i18n.t("section-titles.detailSection_desc")} />
                        </Helmet>
                        <Breadcrums blogDetail={blogDetail}/>
                        <BlogDetailBanner blogDetail={blogDetail}/>
                        <BlogBody blogDetail={blogDetail}/>
                    </>
                }

                {!blogDetail && !danger?
                (
                    <div className={"alert alert-info mt-4 text-center"}>
                        {i18n.t('blog-detail.not-found')}
                    </div>
                ):
                null
            }
            

            {featuredBlogs && (
                <>
         
            <hr/>

                <h3 className={"text-center mt-3 mt-md-0"}>{i18n.t('blog-detail.featured.title')}</h3>
            <div className="carousel mb-5 mt-5">

                <div className="blog d-none d-md-block">
                    <div className={"blog-section-content"}>
                        <div className={"desktop-carrousel"}>
                            <FeaturedCarrouselDesktop featuredBlogs={featuredBlogs}/>
                          </div>
                    </div>
                </div>

                <div className="latestNews-section">
                <div className="mobile-carousel d-md-none">
                    <CarouselMobileRB featuredBlogs={featuredBlogs}/>
                </div>

            </div>
            </div>
                </>
                )}

        </div>
    )}
        </div>

    )
}