import React, {useState} from "react";
import i18n from '../../../i18n.js'

export default function BusinessSocialNetworks(props){

    const {socialNetworks,networkValues, setNetworkValues, errors} = (props) = props;
    const maxNetworks = socialNetworks.length;
    const requiredNetworks = "Debe completar todos los campos";
    const maxNetworksLimit = "No puede agregar más redes sociales";

    const forSocialNetworks = !socialNetworks? [] : socialNetworks.map((sn, index) =>
        (
            <option value={sn.id} key={index}>{sn.name}</option>
        )
    );

    const handleChange = (i, e) => {
        let newFormValues = [...networkValues];
        newFormValues[i][e.target.name] = e.target.value;
        emptyFields()
        setNetworkValues(newFormValues)
    }

    const handleSelectChange = (i, e) => {
        let newFormValues = [...networkValues];
        newFormValues[i][e.target.name] = e.target.value;
        newFormValues[i]['name'] = e.target.options[e.target.selectedIndex].text;
        emptyFields()
        setNetworkValues(newFormValues)
    }


    const emptyFields = () => {
        let anyEmpty = !networkValues? "":networkValues.some(value => value.id === "" || value.name === "" || value.followers === "")
        if(anyEmpty){
            errors['networkValues'] = {type: "required"}
            return true
        }else{
            delete errors['networkValues']
            return false
        }
    }




    const addFormFields = () => {
        let length = 0;
        if(networkValues.length){
            length = networkValues.length
        }
        if (length < maxNetworks && !emptyFields()) {
            setNetworkValues([...networkValues, {id: "", url: "", followers: ""}])
        }
        if(emptyFields()) {
            errors['networkValues'] = {type: "required"}
        }

        if (length == maxNetworks){
            errors['networkValues'] = {type: "maxNetworks"}
        }
       errors['networkValues'] = {type: "required"}
    }

    const removeFormFields = (i) => {
        delete errors['networkValues']
        let newFormValues = [...networkValues];
        newFormValues.splice(i, 1);
        setNetworkValues(newFormValues)
    }

        return(
        <div>
                <div className="button-section px-3 pb-3">
                <div className="add btn btn-outline-primary me-3 rounded-pill px-4" type="button" onClick={() => addFormFields()}>
                    Nueva red social
                    <i className="icon bi bi-plus-circle-fill"/>
                </div>
                    <span className="text-danger">{errors.networkValues?.type === 'required' && requiredNetworks}</span>
                    <span className="text-danger">{errors.networkValues?.type === 'maxNetworks' && maxNetworksLimit}</span>

            </div>

            {!networkValues? [] : networkValues.map((element, index) => (
                <div className="form-inline" key={index}>


                        <div className={"input-col mb-3"}>

                            <div className={"row"}>
                                <fieldset className={"col-12 col-md-3"}>
                                    <label className="label-element">{i18n.t('quotation.form-business.label-sn-1')}</label>
                                    <select className={errors.networkValues?"input-info error":"input-info success"}
                                            name="id" value={element.id || ""}
                                            onChange={e => handleSelectChange(index, e)}
                                    >
                                        <option value={""}>Seleccionar</option>
                                        {forSocialNetworks}
                                    </select>
                                </fieldset>
                                <fieldset className={"col-12 col-md-6"}>
                                    <label className="label-element">{i18n.t('quotation.form-business.label-sn-2')}</label>
                                    <input className={errors.networkValues?"input-info error":"input-info success"}
                                           placeholder={i18n.t('quotation.form.placeholders.network-url')}
                                           type="text" name="url" value={element.url || ""} onChange={e => handleChange(index, e)} />
                                </fieldset>

                                <fieldset className={"col-12 col-md-2"}>
                                    <label className="label-element">{i18n.t('quotation.form-business.label-sn-3')}</label>
                                    <input className={errors.networkValues?"input-info error":"input-info success"}
                                           placeholder={"Cantidad"}
                                           type="number" min={"0"} name="followers" value={element.followers || ""} onChange={e => handleChange(index, e)} />
                                </fieldset>

                                <fieldset className={"remove-col text-center col-12 col-md-1"}>
                                    {
                                        index>=0 ?
                                            <button type="button"  className="button remove btn btn-outline-danger" onClick={() => removeFormFields(index)}>
                                                <i className="bi bi-trash-fill"/>
                                            </button>
                                            : null
                                    }
                                </fieldset>
                            </div>
                        </div>
                </div>
            ))}

        </div>
    )
}