import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import StatusTag from "../businessDetail/businessInfo/statusTag";
import i18n from '../../i18n.js'

export default function BusinessDataCard(props){

    const {business, language} =  (props) = props;
    const navigate = useNavigate();

    function navigateClic() {
        navigate('/negocio/' + business.id);
    }

    return(
        <div className={"data-card shadow"}>

            <StatusTag business={business} />


            <div className={"row"}>
                <div className={"col-12 col-md-6"}>
                    {business.title ?
                        <h4>
                            {business.title}
                        </h4>
                        :
                        <h4>{language === "es" ? business.business_type.name_es : business.business_type.name_pt} de {language === "es" ? business.business_category.name_es : business.business_category.name_pt}</h4>
                    }
                        <p className={"description"}>
                        {business.description}
                    </p>

                </div>

                <div className={"col-12 col-md-6"}>

                    <div className={"row"}>
                                        <div className={"col-6"}>
                                    <h6>
                                    {i18n.t('business.card.text1')}
                                    </h6>
                                    <p>
                                       {language==="es"?business.business_type.name_es:business.business_type.name_pt}
                                    </p>

                                    <h6>
                                    {i18n.t('business.card.text3')}
                                    </h6>
                                    <p>
                                       ${business.average_gross_profit_stat.toLocaleString('de-DE')}
                                    </p>

                                    <h6>
                                    {i18n.t('business.card.text5')}
                                    </h6>
                                    <p>
                                       {language==="es"?business.business_category.name_es:business.business_category.name_pt}
                                    </p>

                                </div>

                                    <div className={"col-6"}>

                                <h6>
                                {i18n.t('business.card.text7')}
                                </h6>
                                <p>
                                    {business.business_age_stat} años
                                </p>

                                <h6>
                                {i18n.t('business.card.text9')}
                                </h6>
                                <p>${business.algorithm_local_price.toLocaleString('de-DE')}</p>

                                <h6>
                                {i18n.t('business.card.text11')}
                                </h6>
                                <p>
                                    {language==="es"?business.user.country.name_es:business.user.country.name_pt}
                                </p>

                            </div>
                    </div>
            </div>

            </div>

            <div className={"detail-button"}>
                <button className="btn btn-outline-primary g-action-button rounded-pill"
                        onClick={navigateClic}
                >
                    {i18n.t('business.card.button')}
                </button>
            </div>
        </div>
    )
}