import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationES from "./locales/es/translation.json";
import translationPT from "./locales/pt/translation.json";

 const resources = {
   es: {
     translation: translationES
   },
   pt: {
     translation: translationPT
   }
 };


i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng:'es',
    debug: true,
    
    
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;