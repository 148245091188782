import i18n from '../../i18n';

export default function AboutUsBanner() {
    return(
        <div className="banner">
            <div className="section-title container">
                <h6>{i18n.t('about-us.banner.header-text')}</h6>
                <h1>
                    <span>{i18n.t('about-us.banner.title')}</span>
                </h1>

                <h2 className="col-6 col-md-4 aboutus_page_subtitle">
                {i18n.t('about-us.banner.text')}
                </h2>
            </div>
        </div>
    )

}