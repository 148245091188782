import React from "react";
import parse from 'html-react-parser';
import {ENV_URL} from "../../../app/constants";


export default function BlogBody(props){

    const {blogDetail} = props;
    const previewImage = ENV_URL + blogDetail.storage_path + blogDetail.preview_image;

    let bodyImage;
    if(blogDetail.body_image) {
        bodyImage = ENV_URL + blogDetail.storage_path + blogDetail.body_image;
    }

    return(
        <div className={"blog-body"}>
            <h2>
                {blogDetail.subtitle}
            </h2>
            <div className={"image"}>
                <img className={"shadow"} src={previewImage} alt="Imágen Encabezado de Nota"/>
            </div>
            <div className={"py-2"}>
                {parse(blogDetail.body)}
            </div>
            {
                blogDetail.body_image&& <img className={"shadow"} src={bodyImage} alt="Imágen Cuerpo de Nota" />
            }

        </div>

    )
}