import './businessList.css'
import BusinessListBanner from './businessList.banner'
import React, { useEffect, useRef, useState } from 'react'
import Search from '../search'
import BusinessFilters from './businessFilters'
import { useLocation, useParams } from 'react-router'
import loadingImage from '../../assets/icons/loading.gif'
import CountriesFetch from '../api/countries.fetch'
import BusinessCategoriesFetch from '../api/businessCategories.fetch'
import BusinessTypesFetch from '../api/businessTypes.fetch'
import BusinessProfitLimits from '../api/businessProfitLimits.fetch'
import Results from './BusinessList.results'
import i18n from '../../i18n.js'
import { ENV_URL } from '../../app/constants'

export default function BusinessList(props) {
    const { marketPlaceType, language } = (props = props)
    const getParams = useParams()
    const location = useLocation()

    const [filter, setFilter] = useState(false)
    const [action, setAction] = useState('MOSTRAR')

    const [businessList, setBusinessList] = useState(null)
    const [data, setData] = useState(null)
    const [countries, setCountries] = useState(null)
    const [minProfit, setMinProfit] = useState(0)
    const [maxProfit, setMaxProfit] = useState(1)

    const [loading, setLoading] = useState(true)
    const [infoFetch, setInfoFetch] = useState(false)
    const [businessCategories, setBusinessCategories] = useState(null)
    const [businessTypes, setBusinessTypes] = useState(null)

    const [categoryFilter, setCategoryFilter] = useState('*')
    const [typeFilter, setTypeFilter] = useState('*')
    const [profitFilter, setProfitFilter] = useState('*')
    const [currentPage, setCurrentPage] = useState(0)
    const [order, setOrder] = useState('new')
    const [anualProfit, setAnualProfit] = useState(0); //value for sql query
    const [profitFilterChanged, setProfitFilterChanged] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [search, setSearch] =  useState("");
    const [country, setCountry] = useState("");
    const [searchTextValue, setSearchTextValue] = useState("");
    const [typeFilterChange, setTypeFilterChange] = useState(false);
    const [countrySelected, setCountrySelected] = useState("");

    /**
     * Fetchs data when url params change
     */
    useEffect(() => {
        if (isSubmitted || marketPlaceType) {
            if (marketPlaceType && !typeFilterChange) {
                if (!typeFilterChange) {
                    setTypeFilter(marketPlaceType)
                    fetchFilteredData(marketPlaceType);
                } else {
                    fetchFilteredData(typeFilter);
                    setTypeFilterChange(false)
                }
                
            } else {
                fetchFilteredData(typeFilter);
            }

            setIsSubmitted(false);
        }
      
    }, [isSubmitted, marketPlaceType])

    useEffect(() => {
        let { searchText, countryId } = getParams

        if (searchText && searchText !== "*") {
            setSearch(searchText);
            setSearchTextValue(searchText);
        } else {
            setSearch("*");
            setSearchTextValue("");
        }

        if (countryId) {
            if (countryId !== "**") {
                setCountrySelected(countryId);
                setCountry(countryId);
            } else {
                setCountry("*");
            }
            
        } else {
            setCountry("*");
        }

        setIsSubmitted(true);
    
    }, [location])
    
    
    /**
     * Validates that all data is available
     */
    useEffect(() => {
        if (
            businessList &&
            businessCategories &&
            businessTypes &&
            countries
        ) {
            setInfoFetch(true)
            setData(businessList.data)
           
            if(!profitFilterChanged && (businessList.data).length>0){
                setAnualProfit(maxProfit)
                //setProfitFilter(maxProfit)
             }
           setLoading(false)
        }
    }, [
        businessList,
        businessCategories,
        businessTypes,
        countries,
        minProfit,
        maxProfit,
    ])

    useEffect(() => {
        setProfitFilterChanged(false)
        setProfitFilter("*")
    }, [categoryFilter, typeFilter,])

    useEffect(() => {
        if (handleMobile()) {
            setFilter(false)
        } else {
            setFilter(true)
        }
        //window.addEventListener("resize", handleMobile);
        setCurrentPage(1);
        setIsSubmitted(true);
    }, [])


    const fetchFilteredData = async (type) => {
        setLoading(true)
        searchScroll()
        setBusinessList(null)
        //let { searchText, countryId } = getParams
                
        let url
        url =
            `${ENV_URL}/api/V1/business/findBy/filter/` +
            search +
            '/' +
            country +
            '/' +
            categoryFilter +
            '/' +
            type +
            '/' +
            profitFilter +
            '/' +
            order +
            '?page=' +
            currentPage

        const api = await fetch(url)

        if (api.status === 200) {
            const dataApi = await api.json()
            setBusinessList(dataApi.businesses)
            setMinProfit(dataApi.minProfit)
            setMaxProfit(dataApi.maxProfit)
            return true
        } else {
            return false
        }
    }

    /**
     * Gets select value and sorts data array with businesses
     * @param event from select onChange
     */
    const orderBy = event => {
        let option = event.target.value

        switch (option) {
            case 'NEW':
                setOrder('new')
                break
            case 'OLD':
                setOrder('old')
                break
            case 'DOWN':
                setOrder('down')
                break
            case 'UP':
                setOrder('up')
                break
        }

        setIsSubmitted(true);
    }

    /**
     * Sets isMobile state according to screen width to hide or display filters
     */
    const handleMobile = () => {
        return window.innerWidth < 600
    }

    const resultRef = useRef()
    const mobileResultRef = useRef()
    const searchScroll = () => {
        if (handleMobile()) {
            mobileResultRef.current.scrollIntoView({ behavior: 'smooth' })
            window.scrollBy(0, -180)
        } else {
            resultRef.current.scrollIntoView({ behavior: 'smooth' })
            window.scrollBy(0, -200)
        }
    }

    /**
     * Toggle filters display visibility
     */
    const toggleFilter = () => {
        setFilter(!filter)
        if (filter) {
            setAction('MOSTRAR')
        } else {
            setAction('OCULTAR')
        }
    }

    return (
        <section className="businessList">
            <div className="businessList-section-content">
                <BusinessListBanner />

                <BusinessCategoriesFetch
                    setBusinessCategories={setBusinessCategories}
                />
                <BusinessTypesFetch setBusinessTypes={setBusinessTypes} />
                <CountriesFetch setCountries={setCountries} />
               

                <div className={'searchBar'} ref={resultRef}>
                    <Search language={language} setCurrentPage={setCurrentPage} search={search} setSearch={setSearch} country={country} setCountry={setCountry} setIsSubmitted={setIsSubmitted} searchTextValue={searchTextValue} setSearchTextValue={setSearchTextValue} countrySelected={countrySelected} setCountrySelected={setCountrySelected} fromHome={ false } />
                </div>
                <div className="results container">
                    <div className="top d-md-flex justify-content-between">
                        <p>{i18n.t('business.list.text')}</p>

                        <div className={'sort'}>
                            <select onChange={orderBy}>
                                <option value={'NEW'}>
                                    {i18n.t('business.list.value1')}
                                </option>
                                <option value={'OLD'}>
                                    {i18n.t('business.list.value2')}
                                </option>
                                <option value={'DOWN'}>
                                    {i18n.t('business.list.value3')}
                                </option>
                                <option value={'UP'}>
                                    {i18n.t('business.list.value4')}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <div className="toggle-button d-block d-md-none">
                                <button
                                    className="g-action-button-outline rounded-pill w-100"
                                    onClick={toggleFilter}
                                >
                                    {action} {i18n.t('business.list.button')}
                                </button>
                            </div>

                            {filter && (
                                <BusinessFilters
                                    infoFetch={infoFetch}
                                    businessCategories={businessCategories}
                                    businessTypes={businessTypes}
                                    countries={countries}
                                    maxProfit={maxProfit}
                                    minProfit={minProfit}
                                    marketPlaceType={marketPlaceType}
                                    setCategoryFilter={setCategoryFilter}
                                    setTypeFilter={setTypeFilter}
                                    setProfitFilter={setProfitFilter}
                                    language={language}
                                    setCurrentPage={setCurrentPage}
                                    setProfitFilterChanged={setProfitFilterChanged}
                                    anualProfit={anualProfit}
                                    setAnualProfit={setAnualProfit}
                                    profitFilterChanged={profitFilterChanged}
                                    setIsSubmitted={setIsSubmitted}
                                    setSearch={setSearch}
                                    setCountry={setCountry}
                                    typeFilter={typeFilter}
                                    setSearchTextValue={setSearchTextValue}
                                    setTypeFilterChange = {setTypeFilterChange}
                                    setCountrySelected={setCountrySelected}
                                />
                            )}
                        </div>

                        <div
                            ref={mobileResultRef}
                            className="col-12 col-md-7 col-lg-8"
                        >
                            {loading && (
                                <div className={'text-center'}>
                                    <img src={loadingImage} alt={'loading'} />
                                    <p>{i18n.t("contact-us.form.placeholders.loading")}</p>
                                </div>
                            )}

                            {!loading && infoFetch ? (
                                <Results
                                    businessList={businessList}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    data={data}
                                    language={language}
                                    setIsSubmitted={setIsSubmitted}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
