import React, { useState } from 'react'
import { isArrayEmpty } from '../../Utils'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'
import BusinessTableDesktop from './businessTableDesktop'
import BusinessAccordionMobile from './dashboard.businessAccordionMobile'
import i18n from '../../../i18n'
import { ENV_URL } from '../../../app/constants'

export default function BusinessesInfo(props) {
    const { businesses, setBusinesses } = (props = props)
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [deleting, setDeleting] = useState(false)

    function handleEdit(id, update_token) {
        navigate('/vende-tu-negocio-update/' + id + '/' + update_token)
    }

    function handlePayment(id, update_token) {
        navigate('/vende-tu-negocio-pagos/'+id+"/"+update_token)
    }

    function handleDelete(id, update_token) {
        setDeleting(true)
        axios
            .get(`${ENV_URL}/api/V1/business/delete/` + id + '/' + update_token)
            .then(response => {
                if (response.status === 200) {
                    setShow(true)
                    setBusinesses(current =>
                        current.filter(business => {
                            return business.id !== id
                        }),
                    )
                    setTimeout(() => setShow(false), 2500)
                    setDeleting(false)
                } else {
                    console.log('Error')
                    setDeleting(false)
                }
            })
            .catch(function (response) {
                if (response.response.status == 500) {
                    console.log('Error')
                    setDeleting(false)
                }
            })
    }

    function handleDetails(id) {
        navigate('/negocio/' + id)
    }

    return (
        <div className={'mt-3 mb-3'}>
            <h3>
                {i18n.t("dashboard.my-businesses")}
            </h3>

            {show && (
                <Alert
                    variant="warning"
                    onClose={() => setShow(false)}
                    dismissible
                >
                    <Alert.Heading>
                        {i18n.t("dashboard.business-deleted")}
                    </Alert.Heading>
                </Alert>
            )}

            {businesses.length > 0 ? (
                <>
                    <div className={'d-none d-md-block'}>
                        <BusinessTableDesktop
                            businesses={businesses}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handlePayment={handlePayment}
                            deleting={deleting}
                            handleDetails={handleDetails}
                        />
                    </div>

                    <div className={'d-block d-md-none'}>
                        <BusinessAccordionMobile
                            businesses={businesses}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handlePayment={handlePayment}
                            handleDetails={handleDetails}
                            deleting={deleting}
                        />
                    </div>
                </>
            ) : (
                <div>
                    <p className={'alert alert-warning text-center'}>
                        {i18n.t("dashboard.no-business")}
                    </p>
                    <Link
                        to="/vende-tu-negocio"
                        className="w-100 sell btn btn-outline-primary rounded-pill"
                    >
                        {i18n.t("dashboard.first")}
                    </Link>
                </div>
            )}
        </div>
    )
}
