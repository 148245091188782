import './suscribe.scss'
import i18n from '../i18n.js'
import {storeSubscription} from '../components/api/newsletterEndpoints'
import {useForm} from "react-hook-form";
import React, {useState, useEffect, useCallback} from "react";
import SusbscribeStatus from "./subscribe.status";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function Suscribe(props) {

    const [sendState, setSendState] = useState(null);
    const {language} = props;
    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const mailRequired = i18n.t('form-error-messages.mail');
    const mailPattern = i18n.t('form-error-messages.mail-pattern');

    const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = useCallback(async(data) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
    
        let recaptcha_token = await executeRecaptcha('suscribe');
        console.log("google recaptcha recived")
        setSendState(1);
        let body = {
            language: language,
            email: data.email,
            recaptcha_token: recaptcha_token
        }
        storeSubscription(body).then(response => {
            if(response.status===201){
                setSendState(2)
                reset()
            }
        }).catch(function (response) {
            setSendState(0)
        })
    }, [executeRecaptcha])

    return (
        <section className="suscribe">
            <div className="suscribe-overlay d-flex justify-content-center align-items-center">
                <div className="container row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 section-title">
                        <h6>{i18n.t('homepage.suscribe.header-text')}</h6>
                        <h3 className="suscribe-title">
                        {i18n.t('homepage.suscribe.title')}
                        </h3>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 justify-content-center align-items-center d-flex pt-4 pt-md-0">
                        <div className="container px-0">

                            <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row">
                                <div className="col">


                                        <input
                                            type="email"
                                            id="inputEmail"
                                            className={errors.mail?"form-control rounded-pill suscribe-input px-4 error":"form-control rounded-pill suscribe-input px-4 success"}
                                            placeholder={i18n.t('homepage.suscribe.placeholder')}
                                            {...register("email", {required: true, pattern:  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                             />
                                        <div className={"error-message text-danger"}>
                                            {errors.email?.type === 'required' && mailRequired}
                                            {errors.email?.type === 'pattern' && mailPattern}
                                        </div>


                                </div>
                            </div>
                            <div className="row">
                                <div className="col d-grid gap-2 d-md-flex justify-content-md-end">
                                    <SusbscribeStatus sendState={sendState}/>
                                    <button
                                        type="submit"
                                        className="btn btn-outline-primary g-action-button px-5 mt-2 mt-md-3"
                                    >
                                        {i18n.t('homepage.suscribe.button')}
                                    </button>
                                </div>
                            </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
