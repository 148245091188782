import React, { useEffect } from 'react'
import { ENV_URL } from '../../app/constants'
/**
 * @return {null}
 */
export default function BusinessDataFetch(props) {
    const {
        setBusinessData,
        setDanger,
        setLoading,
        businessId,
        businessToken,
    } = props

    const fetchData = async () => {
        let url = `${ENV_URL}/api/V1/business/show/${businessId}/${businessToken}`
        const api = await fetch(url)
        if (api.status === 200) {
            const dataApi = await api.json()
            setBusinessData(dataApi)
            return true
        } else {
            setDanger(true)
            setLoading(false)
            return false
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
}
