import React from "react";

export default function ActionButtons(props){

    const {business, handleEdit, handleDelete, handlePayment, deleting, handleDetails} = props

    return(
        <>
            {//business details
                business.is_complete?
                <button>
                    <i className="text-success bi bi-eye-fill"
                       onClick={() => handleDetails(business.id)}
                    />
                </button>
                :
                <button
                disabled={true}>
                    <i className="text-secondary bi bi-eye-fill"/>
                </button>
           }

            {//edit
                (business.is_complete === 1 && business.is_publication_payed===0 && business.is_authorized===0) ||
                (business.is_publication_payed===1) ||
                (business.is_complete === 1 && business.is_authorized===1)?
                    <button
                    disabled={true}>
                    <i className="text-secondary bi bi-pencil-square"/>
                    </button>
                    :
                    <button>
                        <i className="text-warning bi bi-pencil-square"
                           onClick={() => handleEdit(business.id, business.update_token)}
                        />
                    </button>
            }

            {//pay
                business.is_publication_payed===0 && business.is_authorized===1?
                    <button>
                        <i className="text-primary bi bi-currency-dollar"
                           onClick={() => handlePayment(business.id, business.update_token)}
                        />
                    </button>
                    :
                    <button
                    disabled={true}>
                        <i className="text-secondary bi bi-currency-dollar"/>
                    </button>

            }

            {//business delete
                (business.is_complete===0) ||
                business.is_publication_payed===0 && business.is_authorized===1?
                <button>
                    <i className="text-danger bi bi-trash3-fill"
                       onClick={() => handleDelete(business.id, business.update_token)}
                    />
                </button>
                    :
                    <button
                    disabled={true}>
                    <i className="text-secondary bi bi-trash3-fill"/>
                    </button>
            }

            </>
    )
}