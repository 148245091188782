import './latestNews.css'
import CarouselMobileRB from './latestNews.carouselMobileRB'
import {Link} from "react-router-dom";
import i18n from '../i18n';
import React, {useState} from "react";
import loadingImage from "../assets/icons/loading.gif";
import BlogsFeaturedFetch from "./api/blogs.featured.fetch";
import FeaturedCarrouselDesktop from "./blog/blog.featuredCarrouselDesktop";

export default function LatestNews(props) {
    const {language} = props;
    const [featuredBlogs, setFeaturedBlogs] = useState(null)
    return (
        <section className="latestNews-section py-5">
            <BlogsFeaturedFetch
                setFeaturedBlogs={setFeaturedBlogs}
                language={language}
            />
            <div className="latestNews-section-content">
                <div className="container mt-5">
                    <div className="section-title text-center">
                        <h6>{i18n.t('homepage.latest-news.header-text')}</h6>
                        <h2>{i18n.t('homepage.latest-news.title')}</h2>
                    </div>

                    <div className="carousel mb-5 mt-5">

                        {!featuredBlogs && (
                            <div className={'text-center pt-4 mt-4'}>
                                <img src={loadingImage} alt={'loading'} />
                                <p>{i18n.t("contact-us.form.placeholders.loading")}</p>
                            </div>
                        )}

                        {featuredBlogs && (
                            <>
                        <div className="d-none d-md-block">
                            <div className="blog d-none d-md-block">
                                <div className={"blog-section-content"}>
                                    <div className={"desktop-carrousel"}>
                                        <FeaturedCarrouselDesktop featuredBlogs={featuredBlogs} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="mobile-carousel d-md-none">
                            <CarouselMobileRB featuredBlogs={featuredBlogs}/>
                        </div>
                        </>
                        )}

                    </div>

                </div>
            </div>
        </section>
    )
}
