import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import cookie from 'react-cookies';
import {login} from '../api/userEndpoints'
import i18n from '../../i18n.js'
import {Helmet} from "react-helmet";


export default function Login(props){

    //document.title = props.title
    const { register, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState();
    const [loading, setLoading] = useState(false);
    const expires = new Date()
    expires.setDate(Date.now() + 2 * 60 * 60 * 1000)
    const token = cookie.load('laravel_token');

    useEffect(() => {
        if(token){
            navigate("/usuario/panel");
        }
    })


    const onSubmit = async (data) => {
        setLoading(true)
        setValidationError(false)
        const formDataRes = new FormData();

        formDataRes.append('email', data.mail);
        formDataRes.append('password', data.password);

        try{

            login(formDataRes).then(response => {
                if(response.status==200){
                   // cookie.save('auth', 'true', { path: '/' })
                    cookie.save('laravel_token', response.data.token,
                        {
                            path: '/',
                            expires,
                            maxAge: 10800,
                            //secure: true,
                            //httpOnly: true
                        }
                        )
                    navigate('/usuario/panel')
                }
            }).catch(function (response){
                if(response.response.status==401){
                    setValidationError(true)
                    setLoading(false)
                }
            })
        }catch (e) {

        }


    }

    const passwordRequired = i18n.t("form-error-messages.password");
    const passwordMax = i18n.t("form-error-messages.password-max");
    const passwordMin = i18n.t("form-error-messages.password-min");

    const mailRequired = i18n.t("form-error-messages.mail");
    const mailPattern = i18n.t("form-error-messages.mail-pattern");
    return(
        <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            <h1>{i18n.t('login.title')}</h1>

            <div className={"login-form col-12 col-lg-4"}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <fieldset className="col-12">
                        <label className="label-element">{i18n.t('login.label1')}</label>
                        <input className={errors.mail || validationError?"input-info error":"input-info success"}
                            type="mail"
                            placeholder={i18n.t("login.mail-placeholder")}
                            {...register("mail", {required: true, pattern:  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                        />
                        <div className={"error-message text-danger"}>
                            {errors.mail?.type === 'required' && mailRequired}
                            {errors.mail?.type === 'pattern' && mailPattern}
                        </div>

                    </fieldset>

                    <fieldset className="col-12">
                        <label className="label-element">{i18n.t('login.label2')}</label>
                        <input className={errors.password || validationError?"input-info error":"input-info success"}
                               type="password"
                               placeholder={i18n.t("login.pass-placeholder")}
                               {...register("password", { required: true, maxLength: 20, minLength: 2 })} />
                        <div className={"error-message text-danger"}>
                            {errors.password?.type === 'required' && passwordRequired}
                            {errors.password?.type === 'maxLength' && passwordMax}
                            {errors.password?.type === 'minLength' && passwordMin}
                        </div>

                    </fieldset>

                    {validationError&&<p className={"error-message text-danger"}>
                            {i18n.t("form-error-messages.credentials")}
                        </p>}



                    <div className="col-12 submit-botton">
                        <Link to={"/usuario/olvido-clave"}>
                        {i18n.t('login.link1')}
                        </Link>


                        <input className="btn btn-outline-primary g-action-button rounded-pill"
                               type="submit"
                               disabled={loading}
                               value={!loading?i18n.t("login.login-button"):i18n.t("login.login-button-loading")}/>


                        <p className={"pt-4"}>
                        {i18n.t('login.text')}
                            <Link to={"/usuario/registro"}>
                            {i18n.t('login.link2')}
                            </Link></p>
                    </div>



                </form>
            </div>

        </>
    )
}