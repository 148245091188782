import './header.css'
import logoEs from '../assets/logo-es.webp'
import logoPt from '../assets/logo-pt.png'
import {Link, Outlet, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import cookie from "react-cookies";
import {logout} from "./api/userEndpoints";
import i18n from '../i18n';


export default function Header(props) {

    const [expanded, setExpanded] = useState(false);
    const token = cookie.load('laravel_token');
    const user_id = cookie.load('user_id');
    const user_name = cookie.load('user_name');
    const navigate = useNavigate();
    const [logingout, setLogingout] = useState(false);

    const handleLogout = () =>{
        setLogingout(true)
        let userId = cookie.load('user_id');
        logout(token, userId).then((response) =>{
            setLogingout(true)
            if(response.status==200){
                cookie.remove('laravel_token', { path: '/' })
                cookie.remove('user_id', { path: '/' })
                cookie.remove('user_name', { path: '/' })
                navigate("/usuario/login");
            }
        })
    }

return(

<>
        <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-white border-bottom border-bottom-dark header-navbar">

            <div className="container">
                <div>
                <a className="navbar-brand" href="/">
                    <img src={props.language === "es" ? logoEs : logoPt} alt="logo" height={80} />
                </a>
                    {token && user_name? <p className={"text-center"}> {i18n.t('dashboard.hello')} {user_name}!</p >:null}
                 </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"
                          onClick={() => setExpanded(!expanded)}
                    />
                </button>
                <div
                    className={expanded?"collapse navbar-collapse header-navbar-nav show":"collapse navbar-collapse header-navbar-nav"}
                    id="navbarNav"
                >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link
                                className="nav-link active text-dark"
                                aria-current="page"
                                to="/negocios-disponibles"
                                onClick={() => setExpanded(false)}
                            >
                                {i18n.t('header.link1')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-dark" to="/contacto"
                                  onClick={() => setExpanded(false)}
                            >
                                {i18n.t('header.link2')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-dark" to="/prensa"
                                  onClick={() => setExpanded(false)}
                            >
                                {i18n.t('header.link3')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-dark" to="/blog"
                                  onClick={() => setExpanded(false)}
                            >
                                {i18n.t('header.link7')}
                            </Link>
                        </li>
                    </ul>
                    <div className="header-navbar-nav-actions">

                        {token ?
                            <Link to="/usuario/panel"
                                  onClick={() => setExpanded(false)}
                                  className="btn btn-outline-primary me-3 rounded-pill px-4"
                            >
                               {(i18n.t("dashboard.my-businesses")).toUpperCase()}

                            </Link>
                            :
                            <Link to="/vende-tu-negocio"
                                  onClick={() => setExpanded(false)}
                                  className="btn btn-outline-primary me-3 rounded-pill px-4"
                            >
                                {i18n.t('header.link4')}

                            </Link>
                        }

                        {token?

                             <button
                                  className="btn btn-outline-danger rounded-pill px-4"
                                    onClick={handleLogout}
                                  disabled={logingout}
                            >
                                {i18n.t("dashboard.exit")}

                            </button>
                                :
                            <Link to="/usuario/login"
                                  className="btn btn-outline-primary rounded-pill px-4"
                            >
                                {i18n.t('header.link5')}

                            </Link>

                        }


                    </div>

                </div>
            </div>

        </nav>


    <Outlet />
</>
    )
}
