import './howTo.scss'

export default function HowToCard(props) {
    return (
        <div className="card htb-card shadow">
            <div className="card-header htb-card-header">
                <img
                    className="card-img-top htb-card-img"
                    src={props.image}
                    alt="ícono card how to"
                />
                <div className="d-flex w-100"></div>
                <h3 className="htb-card-number">{props.number}</h3>
            </div>

            <div className="card-body">
                <p className="card-text htb-card-text">{props.text}</p>
            </div>
        </div>
    )
}
