import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import AlertModal from "../quotation/quotationForm/form.alertModal";
import FormStatus from "../quotation/quotationForm/form.status";
import cookie from "react-cookies";
import CountriesFetch from "../api/countries.fetch";
import {registerUser} from '../api/userEndpoints'
import i18n from '../../i18n'
import Input from 'react-phone-number-input/input';
import {Helmet} from "react-helmet";

export default function Register(props){
    //document.title = props.title
    const [countries, setCountries] = useState(null);
    const {language} = props;
    const {register, formState: { errors }, handleSubmit } = useForm();
    const [show, setShow] = useState(false);
    const [repeated, setRepeated] = useState("");
    const [sendState, setSendState] = useState(null);
    const token = cookie.load('laravel_token');
    const [country, setCountry] = useState("") //selected country for phone code

    const nameRequired = i18n.t('form-error-messages.name');
    const nameMax = i18n.t('form-error-messages.name-max');
    const nameMin = i18n.t('form-error-messages.name-min');

    const lastnameRequired = i18n.t('form-error-messages.lastname');
    const lastnameMax = i18n.t('form-error-messages.lastname-min');
    const lastnameMin = i18n.t('form-error-messages.lastname-max');

    const mailRequired = i18n.t('form-error-messages.mail');
    const mailPattern = i18n.t('form-error-messages.mail-pattern');
    const phoneRequired = i18n.t('form-error-messages.phone');
    const phoneMax = i18n.t('form-error-messages.phone-max');
    const phoneMin = i18n.t('form-error-messages.phone-min');
    const countryRequired = i18n.t('form-error-messages.country');

    const navigate = useNavigate();

    useEffect(() => {
        if(token){
            navigate("/usuario/panel");
        }
    })

    const setPhoneCode = (id) =>{
        countries.forEach(function(item){
                if(id==item.id) {
                    setCountry(item.iso_code)
                }
            }
        )}

    /**
     * Recives data from form after validation
     * @param data object
     */
    const onSubmit = async (data) => {

        setSendState(1);
        setRepeated(data.mail);

        const formDataRes = new FormData();

        formDataRes.append('name', data.name);
        formDataRes.append('last_name', data.lastname);
        formDataRes.append('email', data.mail);
        formDataRes.append('phone_number', data.phone);
        formDataRes.append('country_id', data.country);
        formDataRes.append('password', null);

        try{
            registerUser(formDataRes).then(function (response) {

                if(response.status==201){
                    navigate("/usuario/registro-exitoso");
                }
            }).catch(function (response) {
                //setSendState(0)
                if(response.request.status==422){
                    setSendState(422)
                    setShow(true);
                }

                if(response.status==500){
                    setSendState(0)
                }
            });

        }catch (e) {
            setSendState(0)
        }


    }

    const forCountries = !countries? [] : countries.map((c, index) =>
        (
            <option value={c.id} key={index}>{language==="es"?c.name_es:c.name_pt}</option>
        )
    );

    return(
        <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            <CountriesFetch setCountries={setCountries}/>
            <h1>
                {i18n.t("login.register-title")}
            </h1>

            <div className={"login-form col-12 col-lg-4"}>
            <form onSubmit={handleSubmit(onSubmit)}>


                <fieldset className="col-12">
                    <label className="label-element">{i18n.t("contact-us.form.label1")}</label>
                    <input className={errors.name?"input-info error":"input-info success"}
                        type="text"
                        placeholder={i18n.t("contact-us.form.placeholders.name")}
                        {...register("name", { required: true, maxLength: 20, minLength: 2 })} />
                    <div className={"error-message text-danger"}>
                        {errors.name?.type === 'required' && nameRequired}
                        {errors.name?.type === 'maxLength' && nameMax}
                        {errors.name?.type === 'minLength' && nameMin}
                    </div>

                </fieldset>
                <fieldset className="col-12">
                    <label className="label-element">{i18n.t("contact-us.form.label6")}</label>
                    <input className={errors.lastname?"input-info error":"input-info success"}
                        type="text"
                        placeholder={i18n.t("contact-us.form.placeholders.lastname")}
                        {...register("lastname", { required: true, maxLength: 20, minLength: 2 })} />
                    <div className={"error-message text-danger"}>
                        {errors.lastname?.type === 'required' && lastnameRequired}
                        {errors.lastname?.type === 'maxLength' && lastnameMax}
                        {errors.lastname?.type === 'minLength' && lastnameMin}
                    </div>

                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">
                        {i18n.t("contact-us.form.label4")}
                    </label>
                    <select className={errors.country?"input-info error":"input-info success"}
                            {...register("country", { required: true })}
                            disabled={!countries} onChange={(e) => setPhoneCode(e.target.value)}
                            >
                        {countries?
                            <option value="">{i18n.t("contact-us.form.placeholders.country")}</option>
                            :
                            <option value="">{i18n.t("contact-us.form.placeholders.loading")}</option>
                        }

                        {forCountries}
                    </select>
                    <div className={"error-message text-danger"}>
                        {errors.country?.type === 'required' && countryRequired}

                    </div>
                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">
                        {i18n.t("contact-us.form.label2")}
                    </label>
                    <input className={errors.mail?"input-info error":"input-info success"}
                           type="mail"
                           placeholder={i18n.t("contact-us.form.placeholders.mail")}
                           {...register("mail", {required: true, pattern:  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                    />
                    <div className={"error-message text-danger"}>
                        {errors.mail?.type === 'required' && mailRequired}
                        {errors.mail?.type === 'pattern' && mailPattern}
                    </div>

                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">
                        {i18n.t("contact-us.form.label3")}
                    </label>
                    <Input className={errors.phone?"input-info error":"input-info success"}
                           type="text"
                           placeholder={i18n.t("contact-us.form.placeholders.phone")}
                           {...register("phone", { required: true, maxLength: 20, minLength: 6 })} 
                           disabled = {country === "" }
                           withCountryCallingCode
                           international
                           country={country}
                    />

                    <div className={"error-message text-danger"}>
                        {errors.phone?.type === 'required' && phoneRequired}
                        {errors.phone?.type === 'maxLength' && phoneMax}
                        {errors.phone?.type === 'minLength' && phoneMin}
                    </div>
                </fieldset>
            

                <FormStatus sendState={sendState} type={"user"}/>

                <div className="col-12 submit-botton">
                    <input className="btn btn-outline-primary g-action-button rounded-pill"
                           type="submit"
                           value="ENVIAR"
                           disabled={sendState===0}
                           />
                </div>

            </form>
            </div>

            <AlertModal show={show}  setShow={setShow} repeated={repeated} type={"user"}/>

        </>

    )


}