import React from "react";
import i18n from '../../../i18n'

export default function BusinessState(props) {

    const {business} = props

    return(
        <>

        {//published
            business.is_publication_payed===1?
                <span className={"text-success"}>
                      {i18n.t("dashboard.published")}
                </span>
                :
                null
        }

    {//Payment pending
        business.is_publication_payed===0 && business.is_authorized===1?
        <span className={"text-primary"}>
            {i18n.t("dashboard.pay")}
        </span>
        :
        null
    }

    {  //In revision
        business.is_complete === 1 && business.is_publication_payed===0 && business.is_authorized===0?
        <span className={"text-warning"}>
            {i18n.t("dashboard.review")}
        </span>
        :
        null}

    { //incomplete
        business.is_complete === 0?
        <span className={"text-danger"}>
            {i18n.t("dashboard.incomplete")}
        </span>
        :
        null
    }
    </>
    )

}