import './blog.css'
import SearchIcon from '../../assets/icons/search.svg'
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import i18n from '../../i18n.js'

export default function BlogSearch(props) {

    const {language, loading, setFilters} = props
    const [search, setSearch] =  useState("");

    const handleSubmit = () =>{
        setFilters(
            prevState => ({
                filter: {
                    ...prevState.filter,
                   search: search
                }
            })
        )
    }





    return (
        <div className="search">

            <div className="container search-form row align-items-center mx-auto">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                    <div className="search-form-igroup">
                        <img
                            src={SearchIcon}
                            alt="search"
                            className="search-form-igroup-icon"
                        />
                        <input
                            type="text"
                            name="search"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            className="form-control search-form-igroup-input"
                            aria-label="Text input with dropdown button"
                            placeholder={i18n.t('blog.search-bar.placeholder')}
                            disabled={loading}
                        />
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <button
                        type="button"
                        className="btn btn-outline-primary rounded-pill search-button w-100"
                        onClick={handleSubmit}
                    >
                        {!loading?i18n.t('blog.search-bar.button'):i18n.t('blog.search-bar.button-loading')}
                    </button>
                </div>
            </div>
        </div>
    )
}
