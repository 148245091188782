import ok from "../../../assets/icons/ok.png";
import React from "react";

export default function ExtraFeatures(props) {

    const {business} = (props) = props;
    return(
        <div className={"extra-features"}>

            <div className={"row"}>

                {business.is_domain_stat==1?
                <div className={"col-12 col-md-5"}>
                    <span><img src={ok} alt={"ok"}/>Dominio</span>
                </div>
                    :
                    null
                }

               {business.is_website_stat==1?
                <div className={"col-12 col-md-5"}>
                    <span><img src={ok} alt={"ok"}/>Web</span>
                </div>
                   :
                   null
               }

               {}
                {business.is_registered_brand_stat=='1'?
                <div className={"col-12 col-md-7"}>
                    <span><img src={ok} alt={"ok"}/>Marca registrada</span>
                </div>
                    :
                    null
                }

                {business.is_app_stat=='1'?
                <div className={"col-12 col-md-7"}>
                    <span><img src={ok} alt={"ok"}/>Apps</span>
                </div>
                    :
                    null
                }


            </div>

        </div>
    )

}