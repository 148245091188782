import React from "react";
import i18n from '../../../i18n'

export default function Oportunities(props) {
    const {business} = (props) = props;
    return(
        <div className={"bulletpoints"}>
            <ul>
                <li><span className={"custom-bullet"}></span>
                    {i18n.t("business-detail.detail.opportunities")}
                </li>
                <p>{ business.opportunities }</p>
            </ul>

        </div>
    )
}