import React, {useEffect, useRef, useState} from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './App.scss'
import Header from './components/header'
import Welcome from './components/welcome'
import Search from './components/search'
import WhoWeAre from './components/whoWeAre'
import Spacer from './components/spacer'
import Sponsors from './components/sponsors'
import HowToBuy from './components/howToBuy'
import Suscribe from './components/suscribe'
import HowToSell from './components/howToSell'
import Testimonials from './components/testimonials/testimonials'
import LatestNews from './components/latestNews'
import GetInTouch from './components/getIntouch'
import Footer from './components/footer'
import Faqs from "./components/faqs/faqs";
import ContactUs from "./components/contactUs/contactUs";
import ScrollToTop from "./components/scrollToTopWrapper";
import NotFound from "./components/notFoundLink";
import {BrowserRouter as Router, Routes, Route, useParams, useHistory } from 'react-router-dom'
import Terms from "./components/terms/terms";
import AboutUs from "./components/aboutUs/aboutUs";
import LanguageSelector from "./components/langSelector";
import WhatsappContact from "./components/whatsappContact";
import BusinessList from "./components/businessAvailableList/businessList";
import BusinessDetail from "./components/businessDetail/businessDetail";
import User from "./components/user/user";
import Quotation from "./components/quotation/quotation";
import QuotationBusinessUpdate from "./components/quotation/quotationUpdate";
import i18n from "./i18n";
import Dashboard from "./components/user/dashboard/dashboard";
import Blog from "./components/blog/blog";
import BlogDetail from "./components/blog/blog.details/blog.detail";
import Payment from "./components/payment/payment";
import {useNavigate} from "react-router";
import TestimonialsPage from "./components/testimonials/testimonials.page";
import {Helmet} from "react-helmet";

function App() {

    const [language, setLanguage] = useState('es')
    const [currentDomain, setCurrentDomain] = useState(window.location.hostname)

    useEffect(() => {
        if (currentDomain.includes(".br")) {
          changeLanguage("pt"); // Brasil, establecer en portugués
        }

    }, [currentDomain]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };

    return (
        <>
            <Router>
                <LanguageSelector changeLanguage={changeLanguage} language={language}/>
                <ScrollToTop>



                <Routes>
                        <Route element={<Header language={language} />}>
                        <Route exact path="/" element={<Home title={i18n.t("section-titles.home")} description={i18n.t("section-titles.home_desc")} language={language} />} />
                        <Route path="/:newLang" element={<Home title={i18n.t("section-titles.home")} description={i18n.t("section-titles.home_desc")} />} />
                        <Route path="/negocios-disponibles" element={<BusinessListSection title={i18n.t("section-titles.businessListSection")} description={i18n.t("section-titles.businessListSection_desc")}  />} />
                        <Route path="/negocios-disponibles/:searchText/:countryId" element={<BusinessListSection  title={i18n.t("section-titles.businessListSection")} description={i18n.t("section-titles.businessListSection_desc")}  />} />
                        <Route path="/negocios-disponibles/:marketPlaceType" element={<BusinessListMarketplaceSection  title={i18n.t("section-titles.businessListSection")} description={i18n.t("section-titles.businessListSection_desc")}  />} />
                        <Route path="/contacto" element={<ContactUsSection  title={i18n.t("section-titles.contactUsSection")} description={i18n.t("section-titles.contactUsSection_desc")} />} />
                        <Route path="/testimonios" element={<TestimonialsSection title={i18n.t("section-titles.testimonialsSection")} description={i18n.t("section-titles.testimonialsSection_desc")} />} />
                        <Route path="/blog" element={<BlogSection title={i18n.t("section-titles.blogSection")} description={i18n.t("section-titles.blogSection_desc")} />} />
                        <Route path="/prensa" element={<NewsSection  title={i18n.t("section-titles.newsSection")} description={i18n.t("section-titles.newsSection_desc")}/>} />
                        <Route path="/:type/:blogId" element={<BlogDetailSection  />} />
                        <Route path="/vende-tu-negocio" element={<QuotationSection  title={i18n.t("section-titles.quotationSection")} description={i18n.t("section-titles.quotationSection_desc")} />} />
                        <Route path="/vende-tu-negocio-update/:businessId/:businessToken" element={<QuotationUpdateSection  title={i18n.t("section-titles.quotationSection")} description={i18n.t("section-titles.quotationUpdateSection_desc")} />} />
                        <Route path="/vende-tu-negocio-pagos/:id/:update_token" element={<PaymemntSection title={i18n.t("section-titles.paymentSection")} description={i18n.t("section-titles.paymentSection_desc")} />} />
                        <Route path="/precios" element={<PricesSection title={i18n.t("section-titles.pricesSection")} description={i18n.t("section-titles.pricesSection_desc")} />} />
                        <Route path="/usuario/panel" element={<DashboardSection title={i18n.t("section-titles.dashboardSection")} description={i18n.t("section-titles.dashboardSection_desc")}/>} />
                        <Route path="/preguntas-frecuentes" element={<FaqsSection title={i18n.t("section-titles.faqs")} description={i18n.t("section-titles.faqs_desc")} />} />
                        <Route path="/preguntas-frecuentes/:type" element={<FaqsTypeSection title_buyer={i18n.t("section-titles.faqs_buyer")} title_seller={i18n.t("section-titles.faqs_seller")} description_buyer={i18n.t("section-titles.faqs_buyer_desc")} description_seller={i18n.t("section-titles.faqs_seller_desc")} />} />
                        <Route path="/quienes-somos" element={<AboutUsSection title={i18n.t("section-titles.aboutUsSection")} description={i18n.t("section-titles.aboutUsSection_desc")}/>} />
                        <Route path="/como-vender" element={<HowToSellSection title={i18n.t("section-titles.howToSellSection")} description={i18n.t("section-titles.howToSellSection_desc")}/>} />
                        <Route path="/como-comprar" element={<HowToBuySection title={i18n.t("section-titles.howToBuySection")} description={i18n.t("section-titles.howToBuySection_desc")}/>} />
                        <Route path="/negocio/:businessId" element={<BusinessDetailSection/>} />
                        <Route path="*" element={<NotFoundSection title={i18n.t("section-titles.notFound")}/>} />
                        <Route path="/no-encontrado" element={<NotFoundSection title={i18n.t("section-titles.notFound")}/>} />
                    </Route>

                    <Route path="/usuario/*" element={<UserSection title={'Login'}/>} />

                </Routes>

                <Footer
                    language={language}
                />
                </ScrollToTop>
                <Terms language={language}/>
                {/*<WhatsappContact />*/}
            </Router>



        </>
    )


function Home(props) {
    const {newLang} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(newLang==='es' || newLang=='pt'){
            changeLanguage(newLang)
            navigate('/', { replace: true });
        }
    }, [newLang])

   //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <Welcome />
        <Search language={language} fromHome={ true }/>
        <Spacer multiplier={6} />
        <WhoWeAre language={props.language} />
        <Sponsors />
        <HowToBuy isHome={true} />
        <Suscribe language={language}/>
        <HowToSell isHome={true} />
        <Testimonials language={language}/>
        <LatestNews language={language}/>
        <GetInTouch language={language}/>
        </>
}

function PaymemntSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Payment language={language}/>
        </>
}

function PricesSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <Payment language={language} />
        </>
}

function TestimonialsSection(props){
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <TestimonialsPage language={language}/>
    </>
}

function DashboardSection(props){
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Dashboard language={language}/>
    </>
}

function FaqsSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <Faqs type="vendedor" language={language}/>
        <Suscribe />
        <GetInTouch language={language}/>
    </>
}

function BusinessDetailSection(props) {
    const {businessId} = useParams();
    return <>
       <BusinessDetail businessId={businessId} language={language}/>
    </>
}

function FaqsTypeSection(props) {
    //document.title = props.title
    const {type} = useParams();
    return <>
        <Helmet>
            <title>{type === "comprador" ? props.title_buyer : props.title_seller}</title>
            <meta name="description" content={type === "comprador" ? props.description_buyer : props.description_seller} />
        </Helmet>
        <Faqs type={type} language={language}/>
        <Suscribe />
        <GetInTouch language={language}/>
    </>
}

function ContactUsSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <ContactUs language={language}/>
    </>
}

function BusinessListSection(props){
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <BusinessList language={language}/>
    </>
}

function BusinessListMarketplaceSection(props){
    //document.title = props.title
    const {marketPlaceType} = useParams();
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <BusinessList marketPlaceType={marketPlaceType}  language={language}/>
    </>
}

function NewsSection(props){
    document.title = props.title
    return <>
        <h2>Sección noticias en desarrollo...</h2>
    </>
}

    function BlogSection(props){
        //document.title = props.title
        return <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            <Blog language={language} type={"blog"}/>
        </>
    }

    function NewsSection(props){
        //document.title = props.title
        return <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            <Blog language={language} type={"prensa"}/>
        </>
    }

    function BlogDetailSection(props){
        //document.title = props.title
        const {blogId} = useParams();
        return <>
            <BlogDetail blogId={blogId} language={language}/>
        </>
    }

function AboutUsSection(props){
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <AboutUs language={language}/>
    </>
}

function UserSection(props){
        //document.title = props.title
        return <>
            <User language={language}/>
        </>
    }

function HowToBuySection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <HowToBuy />c
    </>
}


function HowToSellSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <HowToSell />
    </>
}

function QuotationSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <Quotation language={language}/>
    </>
}

    function QuotationUpdateSection(props) {
        //document.title = props.title
        const {businessId, businessToken} = useParams();
        return <>
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <QuotationBusinessUpdate businessId={businessId} businessToken={businessToken} language={language}/>
        </>
    }


function NotFoundSection(props) {
    //document.title = props.title
    return <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="robots" content="noindex" />
        </Helmet>
        <NotFound />
    </>
}


}


export default App
