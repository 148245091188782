import React from "react";
import './payment.css'
import PaymentBanner from "./payment.banner";
import PaymentPrices from "./payment.prices";
import PaymentInstructions from "./payment.instructions";



export default function Payment({showPayBtn=true}) {
    return (
        <div className="payment">
            <div className="payment-section-content">

                < PaymentBanner/>

                <PaymentPrices showPayBtn={showPayBtn}/>

                <PaymentInstructions/>

            </div>
        </div>
    )

}