import React, {useEffect, useState} from "react";
import FormStatus from "../../quotation/quotationForm/form.status";
import AlertModal from "../../quotation/quotationForm/form.alertModal";
import {useForm} from "react-hook-form";
import {updateProfile} from "../../api/userEndpoints";
import cookie from 'react-cookies';
import i18n from "../../../i18n";
import Input from 'react-phone-number-input/input'


export default function UserProfile(props){

    const {userProfile, getUser, language, countries} = (props) = props;
    const [loading, setLoading] = useState(false);


    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [show, setShow] = useState(false);
    const [repeated, setRepeated] = useState("");
    const [sendState, setSendState] = useState(null);
    const user_id = cookie.load('user_id')
    const token = cookie.load('laravel_token')
    const [country, setCountry] = useState("") //selected country for phone code

    const nameRequired = i18n.t('form-error-messages.name');
    const nameMax = i18n.t('form-error-messages.name-max');
    const nameMin = i18n.t('form-error-messages.name-min');

    const lastnameRequired = i18n.t('form-error-messages.lastname');
    const lastnameMax = i18n.t('form-error-messages.lastname-min');
    const lastnameMin = i18n.t('form-error-messages.lastname-max');

    const mailRequired = i18n.t('form-error-messages.mail');
    const mailPattern = i18n.t('form-error-messages.mail-pattern');

    const stateRequired = i18n.t('form-error-messages.state');

    const phoneRequired = i18n.t('form-error-messages.phone');
    const phoneMax = i18n.t('form-error-messages.phone-max');
    const phoneMin = i18n.t('form-error-messages.phone-min');
    const countryRequired = i18n.t('form-error-messages.country');
    

    /**
     * sets business category elect value
     */
    useEffect(() => {
        if(userProfile){
            setValue('name', userProfile.name);
            setValue('lastname', userProfile.last_name);
            setValue('mail', userProfile.email);
            setValue('phone', userProfile.phone_number);
            setValue('country', userProfile.country_id);
            setValue('state', userProfile.state);
        }

    }, [])

     /**
     * Sets phone code in phone input
     * @param id
     */
      const setPhoneCode = (id) =>{
        countries.forEach(function(item){
            if(id==item.id) {
                 setCountry(item.iso_code)
             }
         }
     )}

      /**
     * Populate countries select
     * @type {Array}
     */
       const forCountries = !countries? [] : countries.map((c, index) =>
       (
           <option value={c.id} key={index}>{language==="es"?c.name_es:c.name_pt}</option>
       )
   );

        const onSubmit = async (data) => {
        setLoading(true)
            setSendState(1);
            try {
            const formDataRes = new FormData();

            formDataRes.append('id', user_id);
            formDataRes.append('name', data.name);
            formDataRes.append('last_name', data.lastname);
            formDataRes.append('email', data.mail);
            formDataRes.append('phone_number', data.phone);
            formDataRes.append('country_id', data.country);
            formDataRes.append('state', data.state);

            updateProfile(token, formDataRes).then(function (response) {
                if(response.status===200){
                        setSendState(2);
                        setLoading(false)
                        getUser()
                    }else{
                        setSendState(0)
                        setLoading(false)
                    }
                }).catch(function (response) {
                setLoading(false)
                    if(response.request.status===422){
                        setRepeated(data.mail)
                        setSendState(422)
                        setShow(true);
                     }else{
                        setSendState(0)
                    }

                });
        }catch (e) {
            setSendState(0)
                setLoading(false)
        }

    }

    return(
        <div className={"mt-3 mb-3"}>
            <h3>Mi perfil</h3>

            <div className={"login-form shadow p-3 mt-2"}>
                <form onSubmit={handleSubmit(onSubmit)}>

                <div className={"row"}>
                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-personal.label1')}</label>
                        <input className={errors.name?"input-info error":"input-info success"}
                               type="text"
                               placeholder={i18n.t('contact-us.form.placeholders.name')}
                               {...register("name", { required: true, maxLength: 20, minLength: 2 })} />
                        <div className={"error-message text-danger"}>
                            {errors.name?.type === 'required' && nameRequired}
                            {errors.name?.type === 'maxLength' && nameMax}
                            {errors.name?.type === 'minLength' && nameMin}
                        </div>

                    </fieldset>
                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-personal.label2')}</label>
                        <input className={errors.lastname?"input-info error":"input-info success"}
                               type="text"
                               placeholder={i18n.t('contact-us.form.placeholders.lastname')}
                               {...register("lastname", { required: true, maxLength: 20, minLength: 2 })} />
                        <div className={"error-message text-danger"}>
                            {errors.lastname?.type === 'required' && lastnameRequired}
                            {errors.lastname?.type === 'maxLength' && lastnameMax}
                            {errors.lastname?.type === 'minLength' && lastnameMin}
                        </div>

                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-personal.label5')}</label>
                        <input className={errors.mail?"input-info error":"input-info success"}
                               type="mail"
                               placeholder={i18n.t('contact-us.form.placeholders.mail')}
                               {...register("mail", {required: true, pattern:  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                        />
                        <div className={"error-message text-danger"}>
                            {errors.mail?.type === 'required' && mailRequired}
                            {errors.mail?.type === 'pattern' && mailPattern}
                        </div>

                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-personal.label6')}</label>
                        <Input className={errors.phone?"input-info error":"input-info success"}
                               type="text"
                               placeholder={i18n.t('contact-us.form.placeholders.phone')}
                               {...register("phone", { required: true, maxLength: 20, minLength: 6 })} 
                               disabled = {!country==""? false : true }
                               withCountryCallingCode
                               international
                               country={country}
                               />

                        <div className={"error-message text-danger"}>
                            {errors.phone?.type === 'required' && phoneRequired}
                            {errors.phone?.type === 'maxLength' && phoneMax}
                            {errors.phone?.type === 'minLength' && phoneMin}
                        </div>
                    </fieldset>


                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-personal.label3')}</label>
                        <select className={errors.country?"input-info error":"input-info success"}
                                {...register("country", { required: true })} 
                                onChange={(e) => setPhoneCode(e.target.value)} >

                                <option value="">{i18n.t('quotation.form-personal.label3')} </option>
                            {forCountries}
                        </select>
                        <div className={"error-message text-danger"}>
                            {errors.country?.type === 'required' && countryRequired}

                        </div>
                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-personal.label4')}</label>
                        <input className={errors.state?"input-info error":"input-info success"}
                               type="text"
                               placeholder={i18n.t('contact-us.form.placeholders.state')}
                               {...register("state", { required: true })}
                        />

                        <div className={"error-message text-danger"}>
                            {errors.state?.type === 'required' && stateRequired}
                        </div>
                    </fieldset>

                </div>

                    <div className={"col-12 text-center"}>

                        <FormStatus sendState={sendState} type={"dashboard"}/>

                            <input className="btn btn-outline-primary g-action-button rounded-pill mt-0"
                                   disabled={loading}
                                   type="submit"
                                   value="ACTUALIZAR"/>



                    </div>

                </form>
            </div>

            <AlertModal show={show}  setShow={setShow} repeated={repeated} type={"user"}/>


            </div>
    )
}