import './search.scss'
import React, {useState} from "react";
import i18n from '../i18n.js'

export default function SearchDropdown(props) {

    const {country, setCountry, countryHome, setCountryHome, countrySelected, setCountrySelected, language, countries, fromHome} = props;


    const forCountries = !countries? [] : countries.map((c, index) =>
        (
            <option value={c.id} key={index}>{language==="es"?c.name_es:c.name_pt}</option>
        )
    );

    const handleCountrySelection = (countryValue) => {
        if (fromHome) {
            setCountryHome(countryValue);
        } else {
            setCountrySelected(countryValue)
            setCountry(countryValue);
        }
    }

    return (
        <div className="search-dropdown">


            <select className="input-info"
                    name="country"
                    value={ fromHome ? countryHome : countrySelected }
                    disabled={!countries}
                    onChange={e =>handleCountrySelection(e.target.value)}>
                    <option value="">{i18n.t('search-bar.select-country')}</option>
                    <option value="*">Todos</option>
                {forCountries}
            </select>
        </div>
    )
}
