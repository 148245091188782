import React from "react";

export default function TermsTextEs() {
    return(
        <div className="terms-text-content">
            <div>
                <h4>Términos y condiciones</h4>
                <p>
                Sitiodetiendas.com es una marca registrada bajo la ley argentina y leyes de los
                distintos países donde funciona según normas aplicables.
                </p>
                <p>
                Sitiodetiendas.com es propiedad de persona física representada por Sitio de Tiendas Sociedad por Acciones con
                domicilio real en la calle Colonia 981 Apto 305, de la ciudad de Montevideo, República Oriental del Uruguay.
                </p>
                <p>
                Sitiodetiendas.com, es una página web que ofrece servicios de cotización y compra venta
                de Sitios (Negocios Digitales) mediante la forma contractual de Cesión de derechos.
                </p>
                <p>
                En este sentido las personas que ingresen a la misma con el fin de utilizar las prestaciones que ofrece, se los
                llamara USUARIOS quienes encontraran todos los servicios y contenidos relacionados con diferentes sitios de Negocios Digitales.
                </p>
                <p>
                Toda persona que utilice el Sitio, “USUARIO” acepta sin condicionamientos todos y cada uno de los presentes Términos
                y Condiciones de Uso, sus modificaciones y documentos complementarios y se obliga a cumplirlos sin objeciones.
                </p>
                <p>
                Todos los contenidos obrantes en el sitio web son de dominio de sitiodetiendas.com pudiendo el USUARIO tener acceso
                a los mismos de la manera que el sitio web lo habilite. El USUARIO asume la responsabilidad del uso del portal.
                </p>
                <p>
                El Sitio se reserva el derecho, a su sola discreción, de modificar, alterar o de actualizar, estos Términos de
                Uso en cualquier momento. Las modificaciones entrarán en vigencia de manera inmediata salvo que específicamente se indique otro momento. Hechas dichas modificaciones, al usar el sitio, usted acepta estar sujeto a las mismas, alteraciones o actualizaciones de los Términos y Condiciones de Uso, sin derecho a efectuar reclamo alguno con relación a ello.
                Si Ud. no está de acuerdo con alguno de los Términos de Uso, no utilice este Sitio.
                </p>
            </div>
            <div>
                <h4>Condiciones de acceso y utilización del Sitio:</h4>
                <ol>
                    <li>
                    La utilización de cualquier Servicio del Sitio atribuye la condición de Usuario del mismo.
                    </li>
                    <li>
                    El Sitio es de libre circulación, salvo quien desee cotizar, vender o comprar su Negocio Digital quien en tal caso deberá informar mediante la confección de los formularios previstos sus datos de contacto. Asimismo, el Usuario será responsable de aportar información veraz y licita.
                    </li>
                    <li>
                    Sitiodetiendas.com cumple con todas las normativas correspondientes a la protección de datos
                    </li>
                </ol>
            </div>

            <div>
                <h4>Uso del Sitio y sus Servicios.</h4>
                <p>
                    El Usuario reconoce y acepta que el uso de los contenidos y/o Servicios ofrecidos por el Sitio será bajo su exclusivo riesgo y/o responsabilidad. El Usuario se compromete a utilizar el Sitio y todo su contenido y Servicios de conformidad con la ley, la moral, el orden público, los presentes Términos de Uso, en su caso, le sean de aplicación. Asimismo, se compromete hacer un uso adecuado de los Servicios y/o contenidos del Sitio y a no emplearlos para realizar actividades ilícitas o constitutivas de delito, que atenten contra los derechos de terceros y/o que infrinjan la regulación sobre propiedad intelectual e industrial, o cualesquiera otras normas del ordenamiento jurídico aplicable.
                </p>
                <p>
                   Le queda expresamente prohibido realizar cualquier acto que pueda provocar daños en los sistemas físicos y lógicos del sitio web, de sus proveedores o  de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños mencionados.
                </p>
            </div>
            <div>
                <h4>Servicios en particular:</h4>
                <p>
                    Este sitio ofrece el servicio de cotizar, publicar y gestionar e intervenir en la compra venta mediante cesión de derechos de Negocios Digitales de quienes se encuentren interesados en hacerlo mediante el consentimiento expreso y según los términos y condiciones establecidos y aceptados por los mismos.
                </p>
                <p>
                    Al registrarse y/o navegar el Sitio, el USUARIO y/o el visitante brinda información personal, prestando su consentimiento libre, expreso e informado para que la misma sea recogida y almacenada directamente en nuestra base de datos, encontrándose protegida electrónicamente, utilizando los mecanismos de seguridad informática de protección de la información más completos y eficaces para mantenerla en total confidencialidad. Toda la información que se brinde será utilizada exclusivamente para la implementación del presente contrato. Asimismo, toda información será
                    protegida por los estándares de privacidad, en concordancia con el Reglamento General de Protección de Datos (GDPR) de la Unión Europea. Argentina: Ley de Protección de Datos Personales Nº 25.326, Brasil: Ley General de Protección de Datos (LGPD), Chile: Ley Nº 19.628 y la Ley Nº 19.628, México: Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP), Colombia: Ley 1581, Perú: Ley de Protección de Datos Personales Nº 29733, Uruguay: Ley Nº 18.331 sobre Protección de Datos Personales y Acción de "Habeas Data".
                </p>
                <p>
                    El USUARIO es responsable por el buen uso del Sitio, comprometiéndose expresamente a evitar cualquier tipo de acción que pueda dañar a sistemas, equipos o servicios accesibles directa o indirectamente a través de Internet, incluyendo la congestión intencional de enlaces o sistemas y de acuerdo a las presentes condiciones.
                </p>
                <p>
                    Se deja expresamente aclarado que el Sitio podrá ser utilizado única y exclusivamente por personas mayores de 18 años, debiendo abstenerse de utilizarlo las personas que no sean mayores de dicha edad.
                </p>
            </div>
            <div>
                <h4>Interés en un Negocio Digital</h4>
                <p>
                    El Usuario deberá seleccionar del listado disponible las opciones que son de su interés completando todos los datos que la pagina le solicite a los efectos de ser contactado para la prosecución del trámite.
                </p>
                <p>
                    El usuario podrá solicitar una cotización de su Negocio Digital el cual será establecido tomando factores de facturación y características de la marca, teniendo en cuenta cantidad de seguidores, participación en redes, notoriedad, logística, registros de marca/dominio, propiedad de apps, horas trabajo y antigüedad.
                </p>
                <p>
                    Sitiodetiendas.com dará toda la información disponible al interesado con el fin de generar herramientas que lo lleven a definir la obtención del Negocio Digital de su interés.
                </p>
                <p>
                    Queda prohibido el contacto directo entre los interesados, salvo el caso excepcional de negocios publicados bajo el modelo de anuncios pagos, siendo siempre e inexcusablemente Sitiodetiendas.com el intermediario para la concreción de la transacción. Es decir, los interesados tanto de la adquisición como de venta no podrán pasar por alto Sitio de tiendas para la realización de la operación.
                </p>
                <p>
                    En caso que el Vendedor encuentre por sus medios a un comprador, en el plazo de los primeros tres meses, el mismo deberá ser presentado a sitiodetiendas.com para la realización y formalización de la transacción, bajo las mismas condiciones anteriormente menciondas. En tal caso la comisión será del 50% de la comisión normal.-
                </p>
                <p>
                    Toda la actividad comercial se llevará a cabo a través de la intervención de Sitiodetiendas.com, salvos los casos mencionados como excepcionales.
                </p>
                <p>
                    Quien ponga a la venta un Negocio Digital deberá ser el verdadero propietario de dicho negocio, quien deberá otorgar a Sitiodetiendas.com la información para corroborar dicha situación. Asimismo, sitiodetiendas,com se compromete a no utilizar ningún dato aportado por el vendedor para ningún otro fin que no sea la realización del presente negocio.
                </p>
                <p>
                    Una vez recibida la oferta por parte del comprador, Sitiodetiendas.com compartirá con el vendedor para su aceptación formal.
                </p>
                <p>
                    Sitiodetiendas.com, auditara mediante herramientas digitales la veracidad de los datos aportados por el vendedor respecto del Negocio Digital a la venta.  Esto solo en lo que hace a información existente en las plataformas digitales, léase  tráfico, público e interacción con terceros y pagos, no pudiendo corroborar ningún otro dato ajeno a las mismas.
                </p>
                <p>
                    Sitiodetiendas.com no se hace responsable de los datos que no puedan ser corroborados por plataformas digitales aportados por el vendedor.
                </p>
                <p>
                    Quien vende (cede derechos) renuncia a todos los derechos que hacen al Negocio Digital transferido no pudiendo reclamar al adquirente ningún tipo de derecho desde el momento mismo de la concreción de la cesión y recepción del monto objeto de la operación.
                </p>
                <p>
                    En caso de que la operatoria no se lleve a cabo por cuestiones ajenas a Sitiodetiendas.com, la parte incumplidora será pasible de multa correspondiente al 14 % de la operación por su culpa frustrada.
                </p>
            </div>
            <div>
                <h4>Términos Generales</h4>
                <p>
                    Toda la información acerca de los Negocios Digitales publicados y/u ofrecidos por el Sitio es proporcionada por los Usuarios en su condición de titulares de Dominio sobre el mismo, siendo responsabilidad exclusiva del mismo dicha información.
                </p>
                <p>
                    El USUARIO es responsable por el buen uso de del servicio, comprometiéndose expresamente a evitar cualquier tipo de acción que pueda dañar a sistemas, equipos o servicios accesibles directa o indirectamente a través de Internet, incluyendo la congestión intencional de enlaces o sistemas y de acuerdo a las presentes condiciones.
                </p>
                <p>
                    El USUARIO asume plena responsabilidad frente a Sitiodetiendas.com y a terceros por daños y perjuicios que se produjeran como consecuencia del accionar propio, de sus dependientes o de terceros conectados a través del USUARIO y los que resulten de la inobservancia de las leyes o reglamentaciones de otros hechos ilícitos o del mal uso que se haga del servicio, debiendo el USUARIO indemnizar y mantener indemne a Sitio de Tiendas ante cualquier reclamo de pago que pudiera corresponder.
                </p>
                <p>
                    Sitiodetiendas.com tendrá el derecho de ampliar o mejorar los servicios prestados sin necesidad de comunicación previa, así como de alterar los medios o rutas utilizados para la prestación del servicio.
                </p>
                <p>
                    Sitiodetiendas.com no será responsable por los daños, alteración de información o interferencias al sistema o a las redes, producto del accionar de terceros.
                </p>
                <p>
                    Sitiodetiendas.com no será responsable por los daños y perjuicios causados, incluyendo caso fortuito y fuerza mayor, que se produjeran a los equipos, instalaciones o personal del USUARIO.
                </p>
            </div>
            <div>
                <h4>Declaraciones</h4>
                <p>
                    Los Usuarios expresamente comprenden, aceptan y están de acuerdo en que:
                </p>
                <ol>
                    <li>
                        El Sitio puede contener errores.
                    </li>
                    <li>
                        El Sitio no será responsable por ningún daño o perjuicio, directo o indirecto, incluyendo, sin ningún tipo de limitación, daños producidos por la pérdida y/o deterioro de información y/o por el uso del Sitio.
                    </li>
                    <li>
                        La utilización del Sitio y de Internet en general implica la asunción de riesgos de potenciales daños al software y al hardware del USUARIO. Por tal motivo, el equipo terminal desde el cual acceda al Sitio el USUARIO estaría en condiciones de resultar atacado y dañado por la acción de hackers quienes podrían incluso acceder a la información contenida en el equipo terminal del USUARIO, extraerla, sustraerla y/o dañarla. Los USUARIO renuncian a efectuar reclamos por estos motivos.
                    </li>
                    <li>
                        El intercambio de información a través de Internet tiene el riesgo de que tal información pueda ser captada por un tercero. El Sitio no se hace responsable de las consecuencias que pudiera acarrear al USUARIO tal hipótesis.
                    </li>
                    <li>
                        Sitiodetiendas.com no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.
                    </li>
                    <li>
                        Sitiodetiendas.com, se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia a aquellos que incumplan las presentes Condiciones Generales de Uso.
                    </li>
                </ol>
                <p>
                    El Sitio no guarda obligación alguna de conservar información que haya hecho disponible a los USUARIO, ni que le haya sido enviada por éstos últimos.
                </p>
                <p>
                    Las personas que utilicen el Sitio sitiodetiendas.com, por su sola utilización aceptarán de pleno derecho todas y cada una de las disposiciones previstas en los presentes Términos de Uso, sin reservas.
                </p>
                <p>
                    En caso de controversia y,  por tratarse de transacciones con participantes de distintos países, las mismas serán sometidas a las Reglas del Arbitraje Internacional.
                </p>
            </div>
          </div>
    )

}