import './langSelector.css'
import React, {useState} from "react";
import cellphoneIcon from "../assets/icons/cellphone.svg";
import i18n from '../i18n';

export default function LanguageSelector(props) {

    return(
        <section className="lang-selector">
            <div className="lang-selector-content">
                <p className="text">{i18n.t('lang-selector.slogan')}</p>

                <div className="flex">
                    <p className="phone">
                        <img
                            src={cellphoneIcon}
                            alt="start"
                            className="px-2"
                        />
                        +598 9722 8603
                    </p>

                    <div className={"selector"}>
                    <button type="button" onClick={() => props.changeLanguage('es')}>
                        <span className={
                            props.language === "es"?
                                "selected"
                            :
                            null
                        }>
                            {i18n.t("lang-selector.button-es")}
                        </span>
                    </button>

                        <span> | </span>

                    <button type="button" onClick={() => props.changeLanguage('pt')}>
                        <span className={
                            props.language === "pt"?
                                "selected"
                                :
                                null
                        }>
                            {i18n.t("lang-selector.button-pt")}
                        </span>
                    </button>
                    </div>
                </div>
            </div>
        </section>
)
}