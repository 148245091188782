import i18n from '../i18n.js'
import './welcome.scss'
/*
import backgroundImage from '../assets/images/welcome.webp'


const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}*/

export default function Welcome() {
    return (
        <section className="welcome-section">
            <div className="welcome-section-content">
                <div className="container">
                    <div className="welcome-section-content-flyer col-8 col-xs-8 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                        <h6>
                            {i18n.t('homepage.welcome.header-text')}
                        </h6>
                    </div>
                    <div className="welcome-section-content-title col-xs-12 col-sm-10 col-md-9 col-lg-8 col-xl-7">
                        <h1>
                            {i18n.t('homepage.welcome.title')}
                        </h1>    
                    </div>
                    <div className="welcome-section-content-subtitle col-8 col-xs-10 col-sm-10 col-md-7 col-lg-6 col-xl-4">
                        <h2>
                            {i18n.t('homepage.welcome.sub-title')}
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    )
}
