import React, {useEffect, useState} from "react";
import Questions from "./faqs.questionsAccordion";
import i18n from '../../i18n.js'

export default function ButtonSelector(props){

    const {buyer, setBuyer, seller, setSeller, type, language} = props;

       //set state segun param
    useEffect(() => {
        setBuyer(type === "comprador" );
        setSeller(type ==="vendedor" );
    },[])

    //funciones toggle
    const displayBuyer = () => {
        setBuyer(true);
        setSeller(false);
    };

    const displaySeller = () => {
        setBuyer(false);
        setSeller(true);
    };

    return(
<>
    <div className="buttons-select row">
        <div className="col 12 col-md-6 seller">
            <button
                type="button"
                className={seller ? "btn btn-outline-primary rounded-pill selected" : "btn btn-outline-primary rounded-pill"}
                onClick={displaySeller}
            >
                {i18n.t('faqs.button-selector.button1')}
            </button>
        </div>


        <div className="col-12 col-md-6 buyer">
            <button
                type="button"
                className={buyer ? "btn btn-outline-primary rounded-pill selected" : "btn btn-outline-primary rounded-pill"}
                onClick={displayBuyer}
            >
                {i18n.t('faqs.button-selector.button2')}
            </button>
        </div>
    </div>





</>
    )

}