import React, { useEffect, useRef, useState } from 'react'
import './businessDetail.css'
import DetailsContact from './businessDetail.contact'
import Suscribe from '../suscribe'
import GetInTouch from '../getIntouch'
import Breadcrums from './businessInfo/businessDetail.businessInfo.breadcrums'
import StatusTag from './businessInfo/statusTag'
import TopInfo from './businessInfo/topInfo'
import ButtonDivider from './businessInfo/buttonDivider'
import Oportunities from './businessInfo/oportunities'
import BottomInfo from './businessInfo/bottomInfo'
import BulletpointsInfo from './businessInfo/bulletpointsInfo'
import SocialNetworks from './businessInfo/socialNetworks'
import ExtraFeatures from './businessInfo/extraFeatures'
import loadingImage from '../../assets/icons/loading.gif'
import { useNavigate, useParams } from 'react-router-dom'
import i18n from '../../i18n.js'
import { ENV_URL } from '../../app/constants'
import {addVisitCount} from '../api/businessEndpoints'
import {Helmet} from 'react-helmet'

export default function BusinessDetail(props) {
    const { language } = props
    const { businessId } = useParams()
    const [business, setBusiness] = useState(null)
    const [isMobile, setIsMobile] = useState(true)
    const [loading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const navigate = useNavigate()
    const [pageTitle, setPageTitle] = useState(null)
    let visitAdded = 0

    useEffect (() => {
        if(business){
            const business_type = (language==="es") ? business.business_type.name_es : business.business_type.name_pt;
            const business_category = (language==="es")?business.business_category.name_es:business.business_category.name_pt;
            setPageTitle(business_type + " de " + business_category)
        } else {
            document.title = i18n.t("section-titles.home")
        }
    }, business)

    const fetchData = async () => {
        setLoading(true)
        let url = `${ENV_URL}/api/V1/business/findBy/id/` + businessId
        const api = await fetch(url)
        if (api.status === 200) {
            const dataApi = await api.json()
            setBusiness(dataApi)
            setLoading(false)
            return true
        } else {
            setLoading(false)
            setNotFound(true)
            return false
        }
    }

    const clicNavigate = () => {
        navigate('/negocios-disponibles')
    }

    /**
     * Sets isMobile state according to screen width
     */
    const handleMobile = () => {
        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    
    const incrementVisits = id =>{
        if(visitAdded<1){
            visitAdded = 1
            addVisitCount(id)
        } 
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleMobile)
        fetchData()
        incrementVisits(businessId)
    }, [])

    return (
        <>
        { business && pageTitle &&
        <Helmet>
            <title>{i18n.t("section-titles.businessDetailSection").replace("{}", pageTitle)}</title>
            <meta name="description" content={i18n.t("section-titles.businessDetailSection_desc").replace("{}", business.title)} />
        </Helmet>
        }
        <section className={'business-detail'}>
            <div className={'business-detail-section-content'}>
                {loading && (
                    <div className={'text-center'}>
                        <img src={loadingImage} alt={'loading'} />
                        <p>{i18n.t('blog.search-bar.button-loading')}</p>
                    </div>
                )}

                {!loading && business ? (
                    <div className={'loaded-data'}>
                        <div className={'business-info container'}>

                            <Breadcrums
                                business={business}
                                language={language}
                            />
                            <div className={'row'}>
                                <div className={'col-12 col-md-6'}>
                                    <StatusTag business={business} />
                                    {business.title?
                                        <h1>
                                            {business.title}
                                        </h1>
                                        :
                                        <h1>
                                            {language === 'es'
                                                ? business.business_type.name_es
                                                : business.business_type
                                                    .name_pt}{' '}
                                            de{' '}
                                            {language === 'es'
                                                ? business.business_category.name_es
                                                : business.business_category
                                                    .name_pt}
                                        </h1>
                                    }


                                    <h2 className={"business_page_subtitle"}>
                                        {business.description}
                                    </h2>

                                    <TopInfo
                                        business={business}
                                        language={language}
                                    />

                                    {isMobile ? (
                                        <>
                                            <ButtonDivider />
                                            <Oportunities business={business} />
                                            <BottomInfo
                                                business={business}
                                                loading={loading}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <div className={'col-12 col-md-6'}>
                                    <BulletpointsInfo business={business} />
                                </div>
                            </div>

                            {!isMobile && (
                                <ButtonDivider />
                            )}

                            <div className={'row'}>
                                <div className={'col-12 col-md-6'}>
                                    {!isMobile && (
                                        <>
                                            <Oportunities business={business} />
                                            <SocialNetworks
                                                business={business}
                                            />
                                            <ExtraFeatures
                                                business={business}
                                            />
                                        </>
                                    )}
                                    <div id="contactRefId"></div>
                                    {isMobile && (
                                        <>
                                            <ExtraFeatures
                                                business={business}
                                            />
                                            <SocialNetworks
                                                business={business}
                                            />
                                        </>
                                    )}
                                </div>

                                <div className={'col-12 col-md-6'}>
                                    {!isMobile && (
                                        <BottomInfo
                                            business={business}
                                            loading={loading}
                                        />
                                    )}
                                    
                                </div>
                            </div>

                        </div>

                        <div>
                            <DetailsContact businessId={businessId} language={language} />
                        </div>
                    </div>
                ) : null}

                {!loading && notFound ? (
                    <div className={'text-center'}>
                        <h3 className={'alert alert-warning text-center mt-4'}>
                        {i18n.t('business-detail.detail.not-found')}
                        </h3>

                        <input
                            className="btn btn-outline-primary g-action-button rounded-pill"
                            type="button"
                            onClick={clicNavigate}
                            value={i18n.t('business-detail.detail.not-found-button')}
                        />
                    </div>
                ) : null}

                <div className="back-effect"></div>

                <Suscribe language={language} />

                <GetInTouch />
            </div>
        </section>
        </>
    )
}
