import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import AlertModal from './form.alertModal'
import FormStatus from './form.status'
import Input from 'react-phone-number-input/input'
import cookie from 'react-cookies'
import i18n from '../../../i18n.js'
import { quotationUser } from '../../api/businessEndpoints'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function PersonalForm(props) {
    const {
        setSavedPersonalData,
        setBusinessId,
        countries,
        businessTypes,
        userProfile,
        language,
    } = props
    const [show, setShow] = useState(false) //displays alert modal for unique user
    const [repeated, setRepeated] = useState('') //unique property in DDBB (mail or business domain)
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm()
    const [sendState, setSendState] = useState(null)
    const [country, setCountry] = useState('') //selected country for phone code
    const [disableState, setDisableState] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha()

    /**
     * sets business category elect value
     */
    useEffect(() => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://leads.godixital.com/js/leads_form_trigger.js'
        //script.async = true;
        document.body.appendChild(script)

        if (userProfile) {
            setValue('name', userProfile.name)
            setValue('lastname', userProfile.last_name)
            setValue('mail', userProfile.email)
            setValue('phoneUser', userProfile.phone_number)
            setValue('country', userProfile.country_id)
            setValue('state', userProfile.state)

            if (userProfile.state) {
                setDisableState(true)
            }
        }

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    /**
     * Sets phone code in phone input
     * @param id
     */
    const setPhoneCode = id => {
        countries.forEach(function (item) {
            if (id == item.id) {
                setCountry(item.iso_code)
            }
        })
    }

    const nameRequired = i18n.t('form-error-messages.name')
    const nameMax = i18n.t('form-error-messages.name-max')
    const nameMin = i18n.t('form-error-messages.name-min')

    const mailRequired = i18n.t('form-error-messages.mail')
    const mailPattern = i18n.t('form-error-messages.mail-pattern')

    const phoneRequired = i18n.t('form-error-messages.phone')
    const phoneMax = i18n.t('form-error-messages.phone-max')
    const phoneMin = i18n.t('form-error-messages.phone-min')

    const countryRequired = i18n.t('form-error-messages.country')

    const lastnameRequired = i18n.t('form-error-messages.lastname')
    const lastnameMax = i18n.t('form-error-messages.lastname-max')
    const lastnameMin = i18n.t('form-error-messages.lastname-min')

    const stateRequired = i18n.t('form-error-messages.state')
    const businesTypeRequired = i18n.t('form-error-messages.business-type')

    /**
     * Recives data from form after validation
     * @param data object
     */
    const onSubmit = useCallback(
        async data => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available')
                return
            }

            let recaptcha_token = await executeRecaptcha(
                'business_personal_form',
            )
            console.log('google recaptcha recived')
            setSendState(1)
            setRepeated(data.mail)

            const formDataRes = new FormData()

            if (cookie.load('user_id')) {
                formDataRes.append('id', cookie.load('user_id'))
            }
            formDataRes.append('name', data.name)
            formDataRes.append('last_name', data.lastname)
            formDataRes.append('email', data.mail)
            formDataRes.append('phone_number', data.phone)
            formDataRes.append('country_id', data.country)
            formDataRes.append('state', data.state)
            formDataRes.append('business_type', data.businessType)
            formDataRes.append('password', null)
            formDataRes.append('recaptcha_token', recaptcha_token)

            try {
                quotationUser(formDataRes)
                    .then(function (response) {
                        if (response.status == 201) {
                            setSendState(2)
                            setBusinessId(response.data.businessId)
                            setSavedPersonalData(true)
                        }
                    })
                    .catch(function (response) {
                        //setSendState(0)
                        if (response.request.status == 422) {
                            setSendState(422)
                            setShow(true)
                        }

                        if (response.status == 500) {
                            setSendState(0)
                        }
                    })
            } catch (e) {
                setSendState(0)
            }
        },
        [executeRecaptcha],
    )

    /**
     * Populate countries select
     * @type {Array}
     */
    const forCountries = !countries
        ? []
        : countries.map((c, index) => (
              <option value={c.id} key={index}>
                  {language === 'es' ? c.name_es : c.name_pt}
              </option>
          ))

    /***
     * Populates business types select
     * @type {Array}
     */
    const forBusinessTypes = !businessTypes
        ? []
        : businessTypes.map((bt, index) => (
              <option value={bt.id} key={index}>
                  {language === 'es' ? bt.name_es : bt.name_pt}
              </option>
          ))

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h4 className={'pb-2'}>
                    {i18n.t('quotation.form-personal.title1')}{' '}
                </h4>

                <div className={'row'}>
                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">
                            {i18n.t('quotation.form-personal.label1')}
                        </label>
                        <input
                            className={
                                errors.name
                                    ? 'input-info error'
                                    : 'input-info success'
                            }
                            disabled={userProfile}
                            type="text"
                            placeholder={i18n.t(
                                'contact-us.form.placeholders.name',
                            )}
                            {...register('name', {
                                required: true,
                                maxLength: 20,
                                minLength: 2,
                            })}
                        />
                        <div className={'error-message text-danger'}>
                            {errors.name?.type === 'required' && nameRequired}
                            {errors.name?.type === 'maxLength' && nameMax}
                            {errors.name?.type === 'minLength' && nameMin}
                        </div>
                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">
                            {i18n.t('quotation.form-personal.label2')}
                        </label>
                        <input
                            className={
                                errors.lastname
                                    ? 'input-info error'
                                    : 'input-info success'
                            }
                            disabled={userProfile}
                            type="text"
                            placeholder={i18n.t(
                                'contact-us.form.placeholders.lastname',
                            )}
                            {...register('lastname', {
                                required: true,
                                maxLength: 20,
                                minLength: 2,
                            })}
                        />
                        <div className={'error-message text-danger'}>
                            {errors.lastname?.type === 'required' &&
                                lastnameRequired}
                            {errors.lastname?.type === 'maxLength' &&
                                lastnameMax}
                            {errors.lastname?.type === 'minLength' &&
                                lastnameMin}
                        </div>
                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">
                            {i18n.t('quotation.form-personal.label3')}
                        </label>
                        <select
                            className={
                                errors.country
                                    ? 'input-info error'
                                    : 'input-info success'
                            }
                            disabled={userProfile}
                            {...register('country', { required: true })}
                            onChange={e => setPhoneCode(e.target.value)}
                        >
                            <option value="">
                                {i18n.t('quotation.form-personal.label3')}{' '}
                            </option>
                            {forCountries}
                        </select>
                        <div className={'error-message text-danger'}>
                            {errors.country?.type === 'required' &&
                                countryRequired}
                        </div>
                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">
                            {i18n.t('quotation.form-personal.label4')}
                        </label>
                        <input
                            className={
                                errors.state
                                    ? 'input-info error'
                                    : 'input-info success'
                            }
                            disabled={disableState}
                            type="text"
                            placeholder={i18n.t(
                                'contact-us.form.placeholders.state',
                            )}
                            {...register('state', { required: true })}
                        />

                        <div className={'error-message text-danger'}>
                            {errors.state?.type === 'required' && stateRequired}
                        </div>
                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">
                            {i18n.t('quotation.form-personal.label5')}
                        </label>
                        <input
                            className={
                                errors.mail
                                    ? 'input-info error'
                                    : 'input-info success'
                            }
                            disabled={userProfile}
                            type="mail"
                            placeholder={i18n.t(
                                'contact-us.form.placeholders.mail',
                            )}
                            {...register('mail', {
                                required: true,
                                pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                        />
                        <div className={'error-message text-danger'}>
                            {errors.mail?.type === 'required' && mailRequired}
                            {errors.mail?.type === 'pattern' && mailPattern}
                        </div>
                    </fieldset>

                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">
                            {i18n.t('quotation.form-personal.label6')}
                        </label>
                        {userProfile ? (
                            <input
                                className={
                                    errors.phone
                                        ? 'input-info error'
                                        : 'input-info success'
                                }
                                name="phoneUser"
                                placeholder={i18n.t(
                                    'contact-us.form.placeholders.phone',
                                )}
                                {...register('phoneUser', {
                                    required: true,
                                    maxLength: 20,
                                    minLength: 6,
                                })}
                                disabled={true}
                            />
                        ) : (
                            <Input
                                className={
                                    errors.phone
                                        ? 'input-info error'
                                        : 'input-info success'
                                }
                                name="phone"
                                placeholder={i18n.t(
                                    'contact-us.form.placeholders.phone',
                                )}
                                {...register('phone', {
                                    required: true,
                                    maxLength: 20,
                                    minLength: 6,
                                })}
                                disabled={!country == '' ? false : true}
                                withCountryCallingCode
                                international
                                country={country}
                            />
                        )}

                        <div className={'error-message text-danger'}>
                            {errors
                                ? errors.phone?.type === 'required' &&
                                  phoneRequired
                                : ''}
                            {errors
                                ? errors.phone?.type === 'maxLength' && phoneMax
                                : ''}
                            {errors
                                ? errors.phone?.type === 'minLength' && phoneMin
                                : ''}
                        </div>
                    </fieldset>
                </div>

                <h4 className={'pt-4 pb-2'}>
                    {i18n.t('quotation.form-personal.title2')}{' '}
                </h4>

                <fieldset className="col-12">
                    <label className="label-element">
                        {i18n.t('quotation.form-personal.label7')}{' '}
                    </label>

                    <select
                        className={
                            errors.businessType
                                ? 'input-info error'
                                : 'input-info success'
                        }
                        {...register('businessType', { required: true })}
                    >
                        <option value="">
                            {i18n.t('quotation.form-personal.input')}{' '}
                        </option>
                        {forBusinessTypes}
                    </select>
                    <div className={'error-message text-danger'}>
                        {errors.businessType?.type === 'required' &&
                            businesTypeRequired}
                    </div>
                </fieldset>

                <FormStatus sendState={sendState} type={'user'} />

                <div className="col-12 submit-botton">
                    <p>{i18n.t('quotation.form-personal.text2')} </p>
                    <input
                        className="btn btn-outline-primary g-action-button rounded-pill"
                        disabled={sendState === 1}
                        type="submit"
                        value={i18n.t('quotation.form-personal.submit-button')}
                        id={'paso-1'}
                    />
                </div>
            </form>

            <AlertModal
                show={show}
                setShow={setShow}
                repeated={repeated}
                type={'user'}
            />
        </>
    )
}
