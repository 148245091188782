import React from "react";
import {ENV_URL} from "../../../app/constants";

export default function BlogDetailBanner(props){

    const {blogDetail} = props;


    return(
        <div className={"banner d-flex justify-content-center mb-4 pb-4 border"}>
            <div className={"text text-center"}>
                <h1>{blogDetail.title}</h1>
                <h6>{blogDetail.date} | {(blogDetail.type).toUpperCase()}</h6>
                {blogDetail.logo_image &&
                <div className={"logo pt-3"}>
                    <img className={"logo-image"} src={ENV_URL + blogDetail.storage_path + blogDetail.logo_image}/>
                </div>
                }
            </div>
        </div>
    )
}