import Carousel from 'react-bootstrap/Carousel';
import React from "react";
import i18n from "../../i18n";
import {useNavigate} from "react-router-dom";
import {ENV_URL} from '../../app/constants'

export default function FeaturedCarrouselDesktop(props) {
    const {featuredBlogs, type} = props;
    const navigate = useNavigate();

    function blogDetail(id, type) {
        navigate("/"+type+"/" + id)
    }

    let featured = featuredBlogs;
    if(type){
        featured =
            featuredBlogs.filter(blog => {
                return blog.type === type;
            });
    }



    let forBlogs = !featured.length>0?
        <h3 className={"alert alert-warning"}>{i18n.t('blog.list.message')}</h3>
        :
        featured.map((blog, index) =>
            (
                <Carousel.Item interval={3000} key={index}>
                    <div className={"item mx-4 my-4"}>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className={"carrousel-img"}>
                                    <img src={ENV_URL + blog.storage_path + blog.preview_image} alt={"Imágen Blog y Prensa"} />
                                </div>
                            </div>

                            <div className={"col-6 card"}>
                                <div className={"card-body"}>
                                    {blog.logo_image &&
                                    <div className={"pb-2"}>
                                        <img className={"logo-image"} src={ENV_URL + blog.storage_path + blog.logo_image} alt={"Logo Blog y Prensa"}/>
                                    </div>
                                    }
                                    <h5 className="card-title">
                                        {blog.title}
                                    </h5>
                                    <p className="card-date"> {blog.date}</p>
                                    <p className="card-text">
                                        {blog.subtitle}
                                    </p>
                                    <input className="btn btn-outline-primary g-action-button rounded-pill w-50"
                                        type="button"
                                        value={i18n.t('blog.featured.read')}
                                        onClick={() => blogDetail(blog.id, blog.type)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </Carousel.Item>
            )
        );

    return (

        <Carousel>
            {forBlogs}
         </Carousel>

    );
}
