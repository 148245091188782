import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import i18n from '../../../i18n.js'

export default function AlertModal(props){

    const navigate = useNavigate();
    const {show, setShow, repeated, type} = props;
    const handleClose = () => setShow(false);
    const handleLogin = () => {
        navigate('/usuario/login');
    }

    return(

        <>
             <Modal
                 show={show}
                 onHide={handleClose}
                 backdrop='static'
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
             >
            <Modal.Header closeButton>
                 <Modal.Title>
                     {type == 'user' && i18n.t('quotation.alert-modal.user-exists')}
                     {type == 'domain' && i18n.t('quotation.alert-modal.domain-exists')}
                     </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {type == 'user' && i18n.t('quotation.alert-modal.user-registered')}
                    {type == 'domain' && i18n.t('quotation.alert-modal.domain-registered')}
                    <span className={"fw-bold"}>{repeated}</span>.</p>
                <p>{i18n.t('quotation.alert-modal.text1')}  <span className={"fw-bold"}>{i18n.t('quotation.alert-modal.text2')} </span> {i18n.t('quotation.alert-modal.text3')} </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                {i18n.t('quotation.alert-modal.text4')} 
                </Button>
                <Button variant="primary" onClick={handleLogin}>
                {i18n.t('quotation.alert-modal.text5')} 
                </Button>
            </Modal.Footer>
        </Modal>
            </>
    )
}