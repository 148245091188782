import i18n from '../../i18n.js'
export default function FaqsBanner(){

    return(
        <div className="banner">
            <div className="section-title container">
                <h6>{i18n.t('faqs.banner.header-text')}</h6>
                <h1>
                    <span>{i18n.t('faqs.banner.title1')}</span>
                    <span> <br />{i18n.t('faqs.banner.title2')}</span>
                </h1>

                <h2 className="col-7 col-sm-6 col-md-4 faqs_page_subtitle">
                {i18n.t('faqs.banner.text')}
                </h2>
            </div>
        </div>
    )
}