import {Link} from "react-router-dom";
import React from "react";
import i18n from '../../i18n'

export  default function ForgetPasswordMessage(){

    return(
        <>
            <div className="success shadow mt-4 p-5 col-12 col-lg-7">
                <h2>{i18n.t("login.forget-title")}</h2>
                <p>
                    {i18n.t("login.forget-message-1")}
                <br/>
                    {i18n.t("login.forget-message-2")}
                </p>

                <div className="row justify-content-center align-items-center px-md-5 mx-md-5">
                    <div className="d-flex col-12 justify-content-center align-items-center mx-0">
                        <Link to="/usuario/login"
                              type="button"
                              className="fix-button-link btn w-100 btn-primary rounded-pill g-action-button"
                        >
                            LOGIN
                        </Link>
                    </div>
                    <div className="d-flex col-12 justify-content-center mx-0">
                        <Link to="/"
                              type="button"
                              className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                        >
                            {i18n.t("login.home")}
                        </Link>
                    </div>
                </div>

            </div>
            <div className={"gradient-bottom shadow mb-4 col-12 col-lg-7"}>
            </div>
        </>
    )
}