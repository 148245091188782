import './getInTouch.scss'
import cellphoneIcon from'../assets/icons/cellphone.svg'
import gpsMarker from'../assets/icons/gpsMarker.svg'
import mailIcon from'../assets/icons/mailIcon.svg'
import backgroundImage from '../assets/images/Desktop-Home-8.webp'
import {Link} from "react-router-dom";
import React from "react";
import i18n from '../i18n';

export default function GetInTouch(props) {
    const {language} = props;
    return (
        <section className="container git-c background-gradient w-100">
            <div className="row row-git-c1">
                <div className="d-flex col-xs-12 col-sm-12 col-md-6 col-lg-6 git-c1 justify-content-end">
                    <img
                        src={backgroundImage}
                        alt="Imágen Get in Touch"
                        className="git-c1-image"
                    />
                </div>
                <div className="d-flex col-xs-12 col-sm-12 col-md-6 col-lg-6 align-items-center">
                    <div className="align-items-center justify-content-center custom section-title">
                        <h6 className="start-content-title">{i18n.t('homepage.get-in-touch.header-text')}</h6>
                        <h2>{i18n.t('homepage.get-in-touch.title')}</h2>
                        <p className="start-content-text">
                        {i18n.t('homepage.get-in-touch.text')}
                        </p>
                        <p>
                            <img
                                src={cellphoneIcon}
                                alt="Ícono teléfono"
                                className="px-2"
                            />
                            +598 9722 8603
                        </p>
                        <p>
                            <img
                                src={mailIcon}
                                alt="Ícono correo electrónico"
                                className="px-2"
                            />
                            {language==="es"?"info@sitiodetiendas.com":"contato@seuproximonegocio.com.br"}
                        </p>
                        <p>
                            <img
                                src={gpsMarker}
                                alt="Ícono localización"
                                className="px-2"
                            />
                            LATAM, Argentina, Brasil, Chile, Colombia, México, Perú, Uruguay
                        </p>
                        <div className="row justify-content-center align-items-center">
                            <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 justify-content-center align-items-center mx-0 px-md-5">
                                <Link to="/contacto"
                                    type="button"
                                    className="fix-button-link btn w-100 btn-primary rounded-pill g-action-button"
                                >
                                    {i18n.t('homepage.get-in-touch.button1')}
                                </Link>
                            </div>
                            <div className="d-flex col-12 col-xs-12 col-sm-12 col-md-12 justify-content-center mx-0 px-md-5">
                                <Link to="/preguntas-frecuentes"
                                    type="button"
                                    className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                                >
                                    {i18n.t('homepage.get-in-touch.button2')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}