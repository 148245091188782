import axios from 'axios'
import { csrf } from './csrfValidation'
import { ENV_URL } from '../../app/constants'

/**
 * Endpoint for user register
 * @param formDataRes
 * @returns {AxiosPromise<any>}
 */
const registerUser = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/create`,
        data: formDataRes,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    })
}

/**
 * Endpoint for login
 * @param formDataRes
 * @returns {AxiosPromise<any>}
 */
const login = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/signin`,
        data: formDataRes,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    })
}

/**
 * Endpoint returns logged in user profile
 * @param token
 * @returns {AxiosPromise<any>}
 */
const getUserProfile = async token => {
    axios.defaults.withCredentials = true
    return axios({
        method: 'get',
        url: `${ENV_URL}/api/profile`,
        headers: {
            Authorization: 'Bearer ' + token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

/**
 * Logout endpoint, destroys session tokens
 * @param token
 * @param id
 * @returns {AxiosPromise<any>}
 */
const logout = async (token, id) => {
    axios.defaults.withCredentials = true
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/logout`,
        data: { id: id },
        headers: {
            Authorization: 'Bearer ' + token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

const getUserBusinesses = async user_id => {
    return axios({
        method: 'get',
        url: `${ENV_URL}/api/V1/business/findBy/userId/` + user_id,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

const passwordCreate = async (userId, token) => {
    return axios({
        method: 'get',
        url: `${ENV_URL}/api/V1/user/password/create/` + userId + '/' + token,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

const passwordUpdate = async (userId, token, formDataRes) => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/password/update/` + userId + '/' + token,
        data: formDataRes,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    })
}

const passwordUpdateRequest = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/password/request`,
        data: formDataRes,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    })
}

const updateProfile = async (token, formDataRes) => {
    axios.defaults.withCredentials = true
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/profile/update`,
        data: formDataRes,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
    })
}

/**
 * Endpoint for contacting business
 * @param formDataRes
 * @returns {AxiosPromise<any>}
 */
 const userContact = async formDataRes => {
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/user/contact`,
        data: formDataRes,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    })
}

export {
    login,
    getUserProfile,
    logout,
    getUserBusinesses,
    passwordCreate,
    passwordUpdate,
    passwordUpdateRequest,
    updateProfile,
    registerUser,
    userContact
}
