import back from "../../../assets/icons/back.svg";
import i18n from "../../../i18n";
import React from "react";
import {useNavigate} from "react-router";

export default function Breadcrums(props){
    const {blogDetail} = props
    let navigate = useNavigate();

    return(
        <div className={"breadcrumbs p-3"}>

            <div className={"desktop d-none d-md-block"}>
                <p>
                    <span className={"link"} onClick={() => navigate("/")}>{i18n.t("blog-detail.breadcrums.home")} </span>
                    <span className={"arrow"}> > </span>
                    <span  className={"link"} onClick={() => navigate("/"+blogDetail.type)}>{blogDetail.type === 'prensa' ? i18n.t("blog-detail.breadcrums.news") : i18n.t("blog-detail.breadcrums.blogs")}</span>
                    <span className={"arrow"}> > </span> {blogDetail.title}
                </p>
            </div>

            <div className={"back d-flex d-md-none"}>
                <button onClick={() => navigate(-1)}>
                        <span>
                            <object data={back} alt="back image svg"/>
                        </span>
                    VOLVER
                </button>
            </div>


        </div>
    )
}