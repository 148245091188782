import SponsorsBanner from '../assets/images/sponsors.png'
import './sponsors.scss'
import LogosCarouselDesktop from "./aboutUs/aboutUs.logosCarouselDesk";
import LogosCarouselMobile from "./aboutUs/aboutUs.logosCarouselMobile";
import React from "react";

export default function Sponsors(props) {
    return (
        <div className="aboutUs container">
            <div className="aboutUs-section-content">
            <div className="d-none d-md-block">
                <LogosCarouselDesktop fromHome={ true }/>
            </div>

            <div className="d-md-none">
                <LogosCarouselMobile fromHome={ true }/>
            </div>
            </div>
        </div>
    )
}
