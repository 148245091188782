import axios from "axios";
import {csrf} from "./csrfValidation";
import {ENV_URL} from "../../app/constants";

const storeSubscription = async body => {
    axios.defaults.withCredentials = true
    await csrf()
    return axios({
        method: 'post',
        url: `${ENV_URL}/api/V1/newsletters/subscribe`,
        data: body,
        headers: {
             Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

export {
    storeSubscription
}

