import React, { useEffect } from 'react'
import { ENV_URL } from '../../app/constants'

/**
 * @return {null}
 */
export default function BlogsFeaturedFetch(props) {
    const { setFeaturedBlogs, language } = props

    const fetchData = async () => {
        let url = `${ENV_URL}/api/V1/blog/featured/`+language
        const api = await fetch(url)
        if (api.status === 200) {
            const dataApi = await api.json()
            setFeaturedBlogs(dataApi)
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return null
}
