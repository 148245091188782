import Slider from "@mui/material/Slider";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import {useForm} from "react-hook-form";
import i18n from '../../i18n.js'

export default function BusinessFilters(props){

    const {profitFilterChanged, anualProfit, setAnualProfit, setProfitFilterChanged, infoFetch,  businessCategories, businessTypes, maxProfit, minProfit, marketPlaceType,setCategoryFilter,setTypeFilter, setProfitFilter, language, setCurrentPage, setIsSubmitted, setSearch, setCountry, typeFilter, setSearchTextValue, setTypeFilterChange, setCountrySelected} = (props) = props;
    
    const navigate = useNavigate();

    const {register ,setValue ,handleSubmit} = useForm();

    const [resetSlider, setResetSlider]= useState(false)

    let marks = [
        {value: minProfit ?? 0,
            label: minProfit ?? 0},
        {value: maxProfit ?? 0,
            label: maxProfit ?? 0}
    ];

    useEffect(() => {
        //setAnualProfit(maxProfit)
        if(marketPlaceType){
            setValue('businessType', marketPlaceType);
        }else{
            
            setValue('businessType', '*');
        }
        setValue('businessCategory', '*');
        setValue('profitSlider', maxProfit)
    }, [infoFetch, marketPlaceType]);

    const resetFilters = () => {
        setValue('businessType', '*');
        setValue('businessCategory', '*');
        setValue('profitSlider', maxProfit)
        setProfitFilter('*')
        setCategoryFilter('*')
        setTypeFilter('*')
        setSearch("*")
        setCountrySelected("");
        setCountry("*")
        setAnualProfit(maxProfit);
        setCurrentPage(1)
        setProfitFilterChanged(false)
        setSearchTextValue("")
        navigate("/negocios-disponibles");
    }

    /**
     * Populates business categories select
    */
    const forCategories = !businessCategories? [] : businessCategories.map((bc, index) =>
        (
            <option value={bc.id} key={index}>{language==="es"?bc.name_es:bc.name_pt}</option>
        )
    );

    /**
     * Populates business types select
        */
    const forTypes = !businessTypes? [] : businessTypes.map((bt, index) =>
        (
            <option value={bt.id} key={index}>{language==="es"?bt.name_es:bt.name_pt}</option>
        )
    );

    /**
     * Gets inputs values on change ans sets each const state
     * @param event from slider change
     */
    const getSliderValue = (event) =>{
        setProfitFilterChanged(true)
        setProfitFilter(event.target.value)
        let profit = event.target.value;
        setAnualProfit(profit);
     }

       /**
     * Gets inputs values and sends them to parent component BusinessList
     * @param event from submit button
     */
    const onSubmit = (data) => {
        /*if(profitFilterChanged){
            setProfitFilter(data.profitSlider)
        }*/

        if(resetSlider){
            setProfitFilterChanged(false)
            setProfitFilter('*')
            setResetSlider(false)
        }

          //setCategoryFilter(data.businessCategory)
           //setTypeFilter(data.businessType)
           setCurrentPage(1)
           setIsSubmitted(true);
    }

    const handleChangeType = (value) => {
        setTypeFilter(value);
        setTypeFilterChange(true);
        setResetSlider(true)
    }

    const handleChangeCategory = (value) => {
        setCategoryFilter(value);
        setResetSlider(true)
    }


    return(
           <div className="filters">
               <div className="filters-form shadow p-4">
                   <form onSubmit={handleSubmit(onSubmit)}>
                       <fieldset>
                           <p className="label-element">{i18n.t('business.filters.label1')}</p>
                           <select className="input-info"
                                   name="businessType"
                                   disabled = {!infoFetch }
                                   {...register("businessType")}
                                   onChange={(event)=>handleChangeType(event.target.value)}
                           >
                               <option value="*">Todos</option>
                             {
                                //TODO que traiga de DB las options en idioma correspondiente
                                forTypes
                             }
                         </select>
                       </fieldset>

                       <fieldset>
                           <p className="label-element">{i18n.t('business.filters.label2')}</p>
                           <select className="input-info"
                                   name="businessCategory"
                                   disabled = {!infoFetch}
                                   {...register("businessCategory")}
                                   onChange={(event)=>handleChangeCategory(event.target.value)}
                           >
                               <option value="*">Todos</option>
                               {
                                //TODO que traiga de DB las options en idioma correspondiente
                                forCategories
                               }
                               </select>
                       </fieldset>

                       <fieldset className={"range-fieldset"}>
                           <p className="label-element">{i18n.t('business.filters.label3')}</p>


                           <Slider
                               value={anualProfit}
                               {...register("profitSlider")}
                               name={"profitSlider"}
                               key={maxProfit+minProfit}
                               aria-label="Slider"
                               defaultValue={0}
                               step={1}
                               min={minProfit}
                               max={maxProfit}
                               valueLabelDisplay="on"
                               marks={marks}
                               onChange={getSliderValue}
                               disabled = {!infoFetch}
                           />

                       </fieldset>

                       <div className={"submit-filters"}>
                           <input className="btn btn-outline-primary g-action-button rounded-pill w-100"
                                  type="submit"
                                  value={i18n.t('blog.filters.apply')}
                                  disabled = {!infoFetch}
                           />
                       </div>
                   </form>

                   <div onClick={resetFilters} className={"text-center"}>
                       <button
                           className="fix-button-link btn w-100 btn-outline-primary rounded-pill px-4 g-action-button-outline"
                           disabled = {!infoFetch}
                       >{i18n.t('blog.filters.reset')}</button>
                   </div>
               </div>
            </div>
    )
}