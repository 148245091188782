import React from 'react'
import './quotation.css'
import CarouselMobile from '../testimonials/testimonials.carousel-m'
import CarouselDesktop from '../testimonials/testimonials.carousel-d'
import logoSSL from '../../assets/images/quotation/ssl_img_80x40.webp'
import logoPDP from '../../assets/images/quotation/pdp_img_80x40.webp'

export default function QuotationHeader(props) {
    const { title, message, language, savedPersonalData } = props

    return (
        <div className={'text-center'}>
            <h1>{title}</h1>
            {!savedPersonalData ? (
                <>
                    <h2 className="quotation_page_subtitle quotation_paragraphs">
                        {message.quotationParagraph01}
                    </h2>

                    <div className='quotation_paragraphs'>
                        <p>{message.quotationParagraph02}</p>
                        <p>{message.quotationParagraph03}</p>
                    </div>
                    <div className="grid gap-4 grid-cols-2">
                        <div className="div_img">
                            <img
                                src={logoSSL}
                                alt="logo SSL"
                                className="logo_img"
                            />
                        </div>
                        <div className="div_img">
                            <img
                                src={logoPDP}
                                alt="logo PDP"
                                className="logo_img"
                            />
                        </div>
                    </div>
                    <div className="quotation-section-cards mt-5">
                        <div className="section-title text-center testimonial-custom-title">
                            <h3>{message.quotationTestimonies}</h3>
                        </div>
                        <div className="carousel mb-5 mt-2">
                            <div className="d-none d-md-block">
                                <CarouselDesktop
                                    language={language}
                                    testimonial={'seller'}
                                />
                            </div>

                            <div className="d-md-none">
                                <CarouselMobile
                                    language={language}
                                    testimonial={'seller'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h2 className="quotation_page_subtitle quotation_paragraphs">
                        {message.pgBusinessForm01}
                    </h2>

                    <div className='quotation_paragraphs'>
                        <p>{message.pgBusinessForm02}</p>
                        <p>{message.pgBusinessForm03}</p>
                    </div>
                </>
            )}
        </div>
    )
}
