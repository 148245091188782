import { ENV_URL } from '../../app/constants'
import axios from 'axios'

const BlogByIdFetch = async (type, blogId) => {
       return axios({
        method: 'get',
        url: `${ENV_URL}/api/V1/blog/show/`+type+'/'+blogId,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

export { BlogByIdFetch }
