import AboutUsBanner from "./aboutUs.banner";
import './aboutUs.css'
import AboutUsMision from "./aboutUs.mision";
import AboutUsCarouselDesktop from "./aboutUs.newsCarouselD";
import AboutUsCarouselMobile from "./aboutUs.newsCarouselMB";
import Team from "./aboutUs.team";
import Suscribe from "../suscribe";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import LogosCarouselDesktop from "./aboutUs.logosCarouselDesk";
import LogosCarouselMobile from "./aboutUs.logosCarouselMobile";
import i18n from '../../i18n';
import BlogsFeaturedFetch from "../api/blogs.featured.fetch";
import FeaturedCarrouselDesktop from "../blog/blog.featuredCarrouselDesktop";
import loadingImage from '../../assets/icons/loading.gif'
import CarouselMobileRB from "../latestNews.carouselMobileRB";

export default function AboutUs(props){

    const {language} = props;
    const [featuredBlogs, setFeaturedBlogs] = useState(null)

    return(
        <section className="aboutUs">
            <BlogsFeaturedFetch
                setFeaturedBlogs={setFeaturedBlogs}
                language={language}
            />
            <div className="aboutUs-section-content">
                <AboutUsBanner/>
                <AboutUsMision/>


                    <div className="d-none d-md-block">
                       <LogosCarouselDesktop/>
                    </div>

                    <div className="d-md-none">
                       <LogosCarouselMobile/>
                    </div>


                <div className="news-carousel carousel pt-5 pb-5 mb-5 mt-5">
                    <div className="section-title text-center testimonial-custom-title">
                        <h6>{i18n.t('about-us.featured.header-text')}</h6>
                        <h2>{i18n.t('about-us.featured.title')}</h2>
                    </div>

                    {!featuredBlogs? (
                            <div className={'text-center pt-4 mt-4'}>
                                <img src={loadingImage} alt={'loading'} />
                                <p>{i18n.t('blog.search-bar.button-loading')}</p>
                            </div>
                        ):



                            <div className={"blog"}>
                                <div className={"blog-section-content"}>
                                    <div className="desktop-carrousel d-none d-md-block">
                                        <FeaturedCarrouselDesktop featuredBlogs={featuredBlogs} type={"prensa"}/>
                                    </div>



                    <div className="mobile-carrousel d-md-none">
                        <CarouselMobileRB featuredBlogs={featuredBlogs} type={"prensa"}/>
                    </div>
                        </div>
                            </div>
                    }




                </div>

                <Team />

                <Suscribe/>

            </div>
        </section>
    )
}