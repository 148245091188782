import i18n from "../../i18n";
import React from "react";

export default function Selector(props) {

    const {testimonial, setTestimonial} = props

    return(
        <div className="faqs">
            <div className="faqs-section-content">
        <div className="buttons-select row">
            <div className="col 12 col-md-6 seller">
                <button
                    type="button"
                    className={testimonial==="seller"? "btn btn-outline-primary rounded-pill selected" : "btn btn-outline-primary rounded-pill"}
                    onClick={()=>setTestimonial("seller")}
                >
                    {i18n.t('testimonials.selector.seller')}
                </button>
            </div>


            <div className="col-12 col-md-6 buyer">
                <button
                    type="button"
                    className={testimonial==="buyer"? "btn btn-outline-primary rounded-pill selected" : "btn btn-outline-primary rounded-pill"}
                    onClick={()=>setTestimonial("buyer")}
                >
                    {i18n.t('testimonials.selector.buyer')}
                </button>
            </div>
        </div>

            </div>
        </div>
    )

}