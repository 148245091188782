export default function Spacer(props) {
    let multiplier = 1
    if (typeof props.multiplier !== 'undefined') {
        multiplier = props.multiplier
    }

    return (
        <section
            className="container"
            style={{ height: multiplier + 'rem' }}
        ></section>
    )
}
