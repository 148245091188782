import React, { useEffect, useState } from 'react'
import PersonalForm from './quotationForm.personal'
import BusinessForm from './quotationForm.business'
import ScrollToTop from '../../scrollToTopWrapper'
import loadingImage from '../../../assets/icons/loading.gif'
import CountriesFetch from '../../api/countries.fetch'
import BusinessCategoriesFetch from '../../api/businessCategories.fetch'
import BusinessTypesFetch from '../../api/businessTypes.fetch'
import SocialNetworksFetch from '../../api/socialNetworks.fetch'
import cookie from 'react-cookies'
import { getUserProfile } from '../../api/userEndpoints'
import i18n from '../../../i18n.js'

export default function QuotationForm(props) {
    const {
        setQuotationSuccess,
        language,
        savedPersonalData,
        setSavedPersonalData,
    } = props //quotation complete
    const [infoFetch, setInfoFetch] = useState(false)
    const [businessId, setBusinessID] = useState(null)
    const [countries, setCountries] = useState(null)
    const [businessTypes, setBusinessTypes] = useState(null)
    const [businessCategories, setBusinessCategories] = useState(null)
    const [socialNetworks, setSocialNetworks] = useState(null)
    const [loading, setLoading] = useState(true)
    const [userProfile, setUserProfile] = useState(null)

    const token = cookie.load('laravel_token')

    const getUser = () => {
        getUserProfile(token).then(response => {
            setUserProfile(response.data)
        })
    }

    /**
     * Validates that all data is available
     */
    useEffect(() => {
        if (token) {
            getUser()
        }
    }, [])

    useEffect(() => {
        if (token) {
            if (
                countries &&
                businessTypes &&
                businessCategories &&
                socialNetworks &&
                userProfile
            ) {
                setInfoFetch(true)
                setLoading(false)
            }
        } else {
            if (
                countries &&
                businessTypes &&
                businessCategories &&
                socialNetworks
            ) {
                setInfoFetch(true)
                setLoading(false)
            }
        }
    }, [
        countries,
        businessTypes,
        businessCategories,
        socialNetworks,
        userProfile,
    ])

    return (
        <>
            <CountriesFetch setCountries={setCountries} />
            <BusinessCategoriesFetch
                setBusinessCategories={setBusinessCategories}
            />
            <BusinessTypesFetch setBusinessTypes={setBusinessTypes} />
            <SocialNetworksFetch setSocialNetworks={setSocialNetworks} />

            {loading && (
                <div className={'text-center'}>
                    <img src={loadingImage} alt={'loading'} />
                    <p>{i18n.t('quotation.form.loading')}</p>
                </div>
            )}

            {!infoFetch ? (
                ''
            ) : !savedPersonalData ? (
                <PersonalForm
                    language={language}
                    setSavedPersonalData={setSavedPersonalData}
                    setBusinessId={setBusinessID}
                    countries={countries}
                    businessTypes={businessTypes}
                    userProfile={userProfile}
                />
            ) : (
                <ScrollToTop>
                    <BusinessForm
                        language={language}
                        setQuotationSuccess={setQuotationSuccess}
                        businessId={businessId}
                        businessCategories={businessCategories}
                        socialNetworks={socialNetworks}
                    />
                </ScrollToTop>
            )}
        </>
    )
}
