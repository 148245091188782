import i18n from '../../i18n.js'
import React from 'react'

export default function PaymentBanner() {
    return (
        <div className="row pt-5 pb-5 justify-content-center section-title">
            <div className="d-flex col-12 justify-content-center text-center">
                <h6>{i18n.t('payment.subtitle')}</h6>
            </div>
            <div className="d-flex col-12 justify-content-center text-center px-4">
                <h1>{i18n.t('payment.title')}</h1>
            </div>
            <div className="d-flex col-8 justify-content-center mt-4">
                <div className="d-flex flex-column justify-content-center text-center">
                    <p>{i18n.t('payment.info.paragraph1')} </p>
                    <p>{i18n.t('payment.info.paragraph2')} </p>
                    <p>{i18n.t('payment.info.paragraph3')} </p>
                    <p>{i18n.t('payment.info.paragraph4')} </p>
                    <p>{i18n.t('payment.info.paragraph5')} </p>
                </div>
            </div>
        </div>
    )
}
