import './search.scss'
import SearchIcon from '../assets/icons/search.svg'
import SearchDropdown from './search.dropdown'
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import i18n from '../i18n.js'
import CountriesFetch from "./api/countries.fetch";

export default function Search(props) {

    const {language, setCurrentPage, search, setSearch, country, setCountry, setIsSubmitted, searchTextValue, setSearchTextValue, countrySelected, setCountrySelected, fromHome} = props
    const [countries, setCountries] = useState(null);
    const [countryHome, setCountryHome] = useState("");
    const [searchHome, setSearchHome] = useState("");
    const navigate = useNavigate();
    //const getParams = useParams();


    /**
     * Gets values from input and country select
     */
    const handleSubmit = () =>{

        if (fromHome) {
            if(!searchHome=="" && !countryHome=="" ){
                navigate("/negocios-disponibles/"  + searchHome + "/" + countryHome);
            }else if(searchHome=="" && !countryHome=="" ){
                navigate("/negocios-disponibles/*/"+countryHome);
            }else if(!searchHome=="" && countryHome=="" ){
                navigate("/negocios-disponibles/"+searchHome+"/**");
            }else{
                navigate("/negocios-disponibles");
            }
        } else {
            if (country == "") {
                setCountry("*");
            }
            setCurrentPage(1)
            setIsSubmitted(true)
        }
        
        

    }

    const handleTextSearch = (value) => {
        if (fromHome) {
            setSearchHome(value);
        } else {
            setSearchTextValue(value);
            if (value == "") {
                setSearch("*");
            } else {
                setSearch(value);
            }
        }
        
    }

    return (
        <div className="search">
            <CountriesFetch setCountries={setCountries}/>
            <div className="container search-form row align-items-center mx-auto">
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-7">
                    <div className="search-form-igroup">
                        <img
                            src={SearchIcon}
                            alt="search"
                            className="search-form-igroup-icon"
                        />
                        <input
                            type="text"
                            name="search"
                            value={fromHome ? searchHome : searchTextValue}
                            onChange={e => handleTextSearch(e.target.value)}
                            className="form-control search-form-igroup-input"
                            aria-label="Text input with dropdown button"
                            placeholder={i18n.t('search-bar.placeholder')}
                        />
                    </div>
                </div>
                <div className="search-form-dropdown col-xs-12 col-sm-12 col-md-4 col-lg-3">
                    <SearchDropdown country={country} setCountry={setCountry} countryHome={countryHome} setCountryHome={setCountryHome} countrySelected={countrySelected} setCountrySelected={setCountrySelected} language={language} countries={countries} fromHome = { fromHome } />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <button
                        disabled={!countries}
                        type="button"
                        className="btn btn-outline-primary rounded-pill search-button w-100"
                        onClick={handleSubmit}
                    >
                        {countries?i18n.t('search-bar.button'):i18n.t('search-bar.button-loading')}
                    </button>
                </div>
            </div>
        </div>
    )
}
